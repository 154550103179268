/**
 * **********************************************************
 * The main purpose to have one place
 * to do all the necessary transformation for the map
 * pins & search results.
 * **********************************************************
 */
export const parseListingsApiData = (responseData) => {
  const listings = responseData?.data?.listings || []
  const aggregates = responseData?.data?.aggregates || {}
  const count = responseData?.data?.count || 0
  try {
    const clusters = aggregates['map']?.clusters
    // .map((cluster) => {
    //   // get the full listings from listings if a cluster.count is 1 & cluster.listing is present
    //   if (cluster.count === 1 && cluster.listing) {
    //     // const listing = listings.find(
    //     //   (listing) => listing.mlsNumber === cluster.listing.mlsNumber
    //     // )
    //     const listing = cluster.listing
    //     if (!listing) return { ...cluster, count: cluster.count }
    //     else return { ...cluster, listing }
    //   } else return cluster
    // })

    return { data: listings, clusters: clusters, count }
  } catch (error) {
    console.error('parsing error: ', error)
  }
}
