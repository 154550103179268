import React, { useEffect, useContext, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Pagination from '@mui/material/Pagination'

// import FavoriteIcon from '@mui/icons-material/Favorite'
import SortIcon from '@mui/icons-material/Sort'
import { debounce } from '@mui/material/utils'
// import { useTheme } from '@mui/material/styles'
// import MapIcon from '@mui/icons-material/Map'

import Header from 'components/nav/Header'
import MapFilters from 'components/nav/secondary/MapFilters'
import Footer from 'components/nav/Footer'
import ListingCard from 'components/common/ListingCard'
import LoadingListingCard from 'components/results-section/LoadingListingCard'
import SearchSuggestions from 'components/common/SearchSuggestions'

import { store } from '../store/app/Store'
import UserContext from '../store/app/Context'
import { reverseGeocode } from 'utils/reverseGeocode'
import { isEmpty, startCase } from 'lodash'

export default function ListView() {
  // const theme = useTheme()
  // const navigate = useNavigate()
  const provider = useContext(UserContext)
  const globalState = useContext(store)
  const { dispatch } = globalState

  let {
    state = globalState?.state?.settings?.area,
    city = globalState?.state?.settings?.city,
  } = useParams()

  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingListings, setLoadingListings] = useState(true)
  const [page, setPage] = useState(1)
  const [resultRange, setResultRange] = useState([0, 0])
  const [addressHeader, setAddressHeader] = useState(
    state && city
      ? {
          city,
          province: state,
        }
      : null
  )

  const [popular, setPopular] = useState([])
  const [nearby, setNearby] = useState([])
  const [recent, setRecent] = useState([])

  const sortOptions = [
    {
      label: 'Recommended',
      value: 'random',
    },
    {
      label: 'Most Recent',
      value: 'createdOnDesc',
    },
    {
      label: 'Oldest',
      value: 'yearBuiltAsc',
    },
    {
      label: 'Most Expensive',
      value: 'listPriceDesc',
    },
    {
      label: 'Least Expensive',
      value: 'listPriceAsc',
    },
    {
      label: 'Most Bedrooms',
      value: 'bedsDesc',
    },
    {
      label: 'Least Bedrooms',
      value: 'bedsAsc',
    },
    {
      label: 'Most Bathrooms',
      value: 'bathsDesc',
    },
    {
      label: 'Least Bathrooms',
      value: 'bathsAsc',
    },
    {
      label: 'Most Square Feet',
      value: 'sqftDesc',
    },
  ]

  const [sortAnchor, setSortAnchor] = React.useState(null)
  const openSort = Boolean(sortAnchor)

  const handleClickSort = (event) => {
    setSortAnchor(event.currentTarget)
  }
  const handleCloseSort = () => {
    setSortAnchor(null)
  }
  const handleSort = (event) => {
    dispatch({
      type: 'searchParams',
      newVal: {
        ...globalState.state.searchParams,
        sortBy: event.target.getAttribute('data-value'),
      },
    })
    setSortAnchor(null)
  }

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        await request()
      }, 0),
    []
  )

  /** Reverse geocode the center of the map to get the area and city */
  useEffect(() => {
    setLoading(true)
    setLoadingListings(true)
    ;(async () => {
      let newSettings = globalState.state.settings

      const newArea = await reverseGeocode(globalState.state.settings.center) // reverse geocode the center of the map

      if (newArea) newSettings = { ...newSettings, ...newArea }

      // Update the map settings in the global state
      dispatch({
        type: 'settings',
        newVal: newSettings,
      })

      setLoading(false)
      setLoadingListings(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Update the header when the state or city changes */
  useEffect(() => {
    setLoading(true)
    setLoadingListings(true)

    setPage(1)

    let params = { ...globalState.state.searchParams },
      settings = { ...globalState.state.settings }

    if (!isEmpty(state)) {
      setAddressHeader({ province: state })
      params = { ...params, area: state }
      settings = { ...settings, area: state }
      if (!isEmpty(city)) {
        setAddressHeader({ city: city, province: state })
        params = { ...params, city: [city] }
        settings = { ...settings, city: city }
      }
    } else setAddressHeader(null)

    // if the state, city are the same as the searchParams, do nothing
    if (
      state === globalState?.state?.searchParams?.area &&
      city === globalState?.state?.searchParams?.city &&
      state &&
      city
    )
      return

    if (!addressHeader) {
      if (globalState.state.settings?.area) {
        setAddressHeader({ province: globalState.state.settings?.area })
        params = { ...params, area: globalState.state.settings?.area }
        settings = { ...settings, area: globalState.state.settings?.area }

        if (globalState.state.settings?.city) {
          setAddressHeader({
            city: globalState.state.settings?.city,
            province: globalState.state.settings?.area,
          })
          params = { ...params, city: [globalState.state.settings?.city] }
          settings = { ...settings, city: globalState.state.settings?.city }
        }
      }
    }

    dispatch({
      type: 'searchParams',
      newVal: params,
    })

    // update only if the area or city has changed
    if (
      state !== globalState?.state?.settings?.area ||
      city !== globalState?.state?.settings?.city
    ) {
      dispatch({
        type: 'settings',
        newVal: settings,
      })
    }

    setLoading(false)
    setLoadingListings(false)
    // eslint-disable-next-line
  }, [state, city, globalState.state.settings])

  useEffect(() => {
    setLoadingListings(true)
    setLoading(true)
    window.scrollTo(0, 0)

    try {
      fetch(async () => {
        let params = {
          ...globalState.state.searchParams,
          resultsPerPage: 8,
          pageNum: page,
        }

        if (page === 1) {
          // if first page, we want hasImages to be true
          // params['hasImages'] = true

          // if there is no city or area, we want to default to Toronto Ontario
          if (!params['city']) {
            params['city'] = ['Toronto']
          }
        }

        delete params['map']
        delete params['area']
        delete params['listings']
        delete params['aggregates']
        delete params['clusterPrecision']
        delete params['clusterLimit']

        const res = await provider.fetchMapPinsListings(params)

        if (res?.status >= 400) throw new Error(res?.data?.message)
        else {
          dispatch({
            type: 'searchResults',
            newVal: res,
          })

          setResultRange([page * 8 - 8, page * 8])
          setListings(res?.data)

          setLoadingListings(false)

          // get the mlsNumber from the first listing in the array and make a call to provider.getSuggestions
          const listingId = res?.data?.[0]?.mlsNumber

          if (!isEmpty(listingId)) {
            const suggestionsData = await provider.fetchSuggestions(
              res?.data?.[0]?.mlsNumber,
              res?.data?.[0]?.boardId
            )

            if (suggestionsData?.status >= 400)
              throw new Error(suggestionsData?.data?.message)
            else {
              setPopular(suggestionsData?.data?.suggestions?.popular)
              setNearby(suggestionsData?.data?.suggestions?.nearby)
              setRecent(suggestionsData?.data?.suggestions?.recent)
            }

            setLoading(false)
          }
        }
      })
    } catch (error) {
      console.log('error fetching listings & suggestions', error)
      setLoading(false)
      setLoadingListings(false)
    }
    // eslint-disable-next-line
  }, [globalState.state.searchParams, page])

  return (
    <>
      <Header />
      <MapFilters list={'true'} map={'false'} />
      <Container component="main" maxWidth="lg" sx={{ paddingTop: `1rem` }}>
        {loadingListings ? (
          <h1 style={{ marginTop: `16px` }}>
            <Skeleton animation="wave" variant="text" width={280} height={40} />
          </h1>
        ) : isEmpty(addressHeader) ? (
          <h1 style={{ marginTop: `16px` }}>No city or province selected.</h1>
        ) : (
          <h1 style={{ marginTop: `16px` }}>
            {startCase(addressHeader.city) &&
              startCase(addressHeader.city) + ', '}
            <span className="highlight">
              {startCase(addressHeader.province)}
            </span>
          </h1>
        )}
        {/* TODO: Map view button on list view when on mobile. */}
        {/* <Box display="flex" sx={{
          position: 'absolute',
          bottom: theme.spacing(4),
          pointerEvents: 'auto',
          flexDirection: 'column',
          right: theme.spacing(2),
          zIndex: 1000,
        }}>
        <Hidden mdUp>
          <Button
            sx={{
              maxWidth: theme.spacing(7),
              height: theme.spacing(6),
              minWidth: 0,
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: theme.palette.primary.white,
              },
              border: '1px solid ',
              borderColor: theme.palette.primary.main,
              marginRight: '50vh',
              transition: 'margin-right 0.2s ease',
            }}
            variant="contained"
            color="inherit"
            onClick={() => {
              navigate('/map')
            }}
          >
            <MapIcon sx={{
              color: theme.palette.primary.main,
              fontSize: theme.spacing(2.5),
            }} />
          </Button>
        </Hidden>
        </Box> */}
        <Box
          sx={{
            margin: '16px 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>
            {loadingListings ? (
              <Typography variant="body1" component="span">
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={150}
                  height={20}
                />
              </Typography>
            ) : (
              <span>
                {globalState?.state?.searchResults?.count > 0
                  ? `${resultRange[0] + 1} - ${resultRange[1]} of ${
                      globalState?.state?.searchResults?.count
                    } Homes`
                  : `No results found. Please try another search.`}
              </span>
            )}
          </p>
          <div>
            {/* <Hidden mdDown>
              <span className="buttonHolder" style={{ paddingRight: `16px` }}>
                <Button
                  component="button"
                  color="primary"
                  variant="contained"
                  endIcon={<FavoriteIcon />}
                  className="primaryBtn"
                >
                  Save Search
                </Button>
              </span>
            </Hidden> */}
            {/* Sort */}
            <span className="buttonHolder">
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<SortIcon />}
                className="primaryBtn"
                aria-controls={openSort ? 'sort-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? 'true' : undefined}
                onClick={handleClickSort}
              >
                Sort:{' '}
                <Hidden mdDown>
                  <span>
                    {
                      sortOptions.find(
                        (sort) =>
                          sort.value ===
                          globalState?.state?.searchParams?.sortBy
                      )?.label
                    }
                  </span>
                </Hidden>
              </Button>
              <Menu
                id="sort-menu"
                anchorEl={sortAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openSort}
                onClose={handleCloseSort}
                MenuListProps={{
                  'aria-labelledby': 'sort-button',
                }}
              >
                {sortOptions.map((sort, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleSort}
                    data-value={sort.value}
                    selected={
                      sort.value === globalState?.state?.searchParams?.sortBy
                    }
                  >
                    {sort?.label}
                  </MenuItem>
                ))}
              </Menu>
            </span>
          </div>
        </Box>
      </Container>
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2}>
          {loadingListings
            ? Array.from({ length: 8 }).map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <LoadingListingCard key={index} smallMode={true} />
                </Grid>
              ))
            : listings?.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <ListingCard data={item} key={index} color={'#054D85'} />
                </Grid>
              ))}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: `50px`,
              paddingBottom: `50px`,
              display: `flex`,
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={Math.floor(
                Number(globalState?.state?.searchResults?.count / 8)
              )}
              color="primary"
              page={page}
              onChange={(event, value) => {
                setPage(value)
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <SearchSuggestions
        popular={popular}
        recent={recent}
        nearby={nearby}
        loading={loading}
      />

      <Footer />
    </>
  )
}
