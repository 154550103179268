import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'

const LoadingListingCard = (props) => {
  // eslint-disable-next-line
  const { smallMode = false } = props

  return (
    <Card>
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          height: {
            sm: 235,
          },
        }}
      />
      <CardContent>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width="60%" sx={{ paddingTop: `.2rem` }} />
        <Skeleton animation="wave" width="40%" sx={{ paddingTop: `.2rem` }} />
        <Typography
          sx={{ fontSize: 10, paddingTop: `.5rem` }}
          color="text.secondary"
          gutterBottom
        >
          <Skeleton
            animation="wave"
            width="80%"
            sx={{ fontSize: 10, paddingTop: `.5rem` }}
          />
        </Typography>
      </CardContent>
    </Card>
  )
}

export default LoadingListingCard
