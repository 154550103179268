import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import PublicIcon from '@mui/icons-material/Public'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import Hidden from '@mui/material/Hidden'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import AssignmentIcon from '@mui/icons-material/Assignment'

import { store } from '../../store/app/Store'
import getUserCoordinates from '../../utils/getUserCoordinates'

const ActionButtons = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const globalState = useContext(store)
  const { state, dispatch } = globalState

  useEffect(() => {
    // so that the map zooms in and out when the zoom buttons are clicked
  }, [state.resultsSection])

  const styles = {
    root: {
      pointerEvents: 'auto',
      flexDirection: 'column',
      position: 'absolute',
      top: theme.spacing(16),
      right: theme.spacing(2),
      zIndex: 1000,
    },
    zoomButtonRoot: {
      position: 'absolute',
      bottom: theme.spacing(10),
      pointerEvents: 'auto',
      flexDirection: 'column',
      right: theme.spacing(2),
      zIndex: 1000,
    },
    icon: {
      color: theme.palette.common.white,
      fontSize: theme.spacing(2.5),
    },
    publicIcon: {
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2.5),
    },
    hideDesktop: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    zoomButton: {
      maxWidth: theme.spacing(7),
      height: theme.spacing(6),
      minWidth: 0,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
      },
      border: '1px solid ',
      borderColor: theme.palette.primary.main,
      // [theme.breakpoints.down('md')]: {
      //   maxWidth: theme.spacing(6),
      //   height: theme.spacing(4),
      // },
      marginRight: state.resultsSection.open ? '50vw' : theme.spacing(1),
      transition: 'margin-right 0.2s ease',
    },
    zoomIcon: {
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2.5),
    },
    clearButtonRoot: {
      position: 'absolute',
      bottom: theme.spacing(4),
      pointerEvents: 'auto',
      flexDirection: 'column',
      right: '50%',
      transform: 'translateX(50%)',
      zIndex: 1000,
    },
    clearButton: {
      // same as the zoom buttons, just centered in the middle of the map
      maxWidth: theme.spacing(7),
      height: theme.spacing(7),
      minWidth: {
        xs: theme.spacing(24),
        md: theme.spacing(28),
      },
      justifyContent: 'center',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
      },
      border: '1px solid ',
      borderColor: theme.palette.primary.main,
      // [theme.breakpoints.down('md')]: {
      //   maxWidth: theme.spacing(6),
      //   height: theme.spacing(4),
      // },
      marginRight: state.resultsSection.open ? '80vw' : null,
      transform: state.resultsSection.open ? 'translateX(50%)' : 'none',
      transition: 'margin-right 0.2s ease',
    },
  }

  return (
    <>
      <Box display="flex" sx={styles.root}>
        <Hidden mdDown>
          <Button
            sx={{
              maxWidth: theme.spacing(7),
              height: theme.spacing(6),
              minWidth: 0,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              [theme.breakpoints.down('md')]: {
                maxWidth: theme.spacing(6),
                height: theme.spacing(4),
              },
              marginRight: state.resultsSection.open
                ? '50vw'
                : theme.spacing(1),
              transition: 'margin-right 0.2s ease',
            }}
            variant="contained"
            color="inherit"
            onClick={() => {
              dispatch({
                type: 'resultsSection',
                newVal: {
                  ...state.resultsSection,
                  open: !state.resultsSection.open,
                },
              })
            }}
          >
            {state.resultsSection.open ? (
              <KeyboardDoubleArrowRightIcon sx={styles.icon} />
            ) : (
              <KeyboardDoubleArrowLeftIcon sx={styles.icon} />
            )}
          </Button>
        </Hidden>
        <Button
          sx={{
            maxWidth: theme.spacing(7),
            height: theme.spacing(6),
            minWidth: 0,
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.white,
            },
            border: '1px solid ',
            borderColor: theme.palette.primary.main,
            // [theme.breakpoints.down('md')]: {
            //   maxWidth: theme.spacing(6),
            //   height: theme.spacing(4),
            // },
            marginRight: state.resultsSection.open ? '50vw' : theme.spacing(1),
            transition: 'margin-right 0.2s ease',
          }}
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch({
              type: 'satelliteView',
              newVal: !state.satelliteView,
            })
          }}
        >
          <PublicIcon sx={styles.publicIcon} />
        </Button>
        <Button
          sx={styles.zoomButton}
          variant="contained"
          color="inherit"
          onClick={() => {
            ;(async () => {
              try {
                const userLocation = await getUserCoordinates()
                dispatch({
                  type: 'settings',
                  newVal: { ...state.settings, center: userLocation },
                })
                // sessionStorage.setItem('userLocation', true)
              } catch (errorTranslationLink) {
                // showToast(errorTranslationLink, 'default')
                console.error(
                  'error getting user location: ',
                  errorTranslationLink
                )
              }
            })()
          }}
        >
          <GpsFixedIcon sx={styles.publicIcon} />
        </Button>
      </Box>
      <Box display="flex" sx={styles.zoomButtonRoot}>
        <Hidden mdUp>
          <Button
            sx={styles.zoomButton}
            variant="contained"
            color="inherit"
            onClick={() => {
              navigate('/list')
            }}
          >
            <AssignmentIcon sx={styles.publicIcon} />
          </Button>
        </Hidden>
        <Button
          sx={styles.zoomButton}
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch({
              type: 'settings',
              newVal: {
                ...state.settings,
                zoom: state.settings.zoom + 1,
              },
            })
          }}
        >
          <AddIcon sx={styles.publicIcon} />
        </Button>
        <Button
          sx={styles.zoomButton}
          variant="contained"
          color="inherit"
          onClick={() => {
            dispatch({
              type: 'settings',
              newVal: {
                ...state.settings,
                zoom: state.settings.zoom - 1,
              },
            })
          }}
        >
          <RemoveIcon sx={styles.zoomIcon} />
        </Button>
      </Box>

      {state.polygon.path && (
        <Box display="flex" sx={styles.clearButtonRoot}>
          <Button
            sx={styles.clearButton}
            size="large"
            variant="contained"
            color="inherit"
            onClick={() => {
              dispatch({
                type: 'polygon',
                newVal: {
                  name: null,
                  type: null,
                  path: null,
                },
              })
            }}
            startIcon={<ClearIcon sx={styles.publicIcon} />}
          >
            {/* Clear icon with a label on the same line*/}
            <Box display="flex" flexDirection="row">
              <Typography
                sx={{
                  fontSize: {
                    xs: '0.8rem',
                    md: '1rem',
                  },
                  // bold the text
                  fontWeight: 700,
                  paddingLeft: {
                    xs: theme.spacing(0.5),
                    md: theme.spacing(1),
                  },
                  color: theme.palette.primary.main,
                }}
              >
                Clear Bounds
              </Typography>
            </Box>
          </Button>
        </Box>
      )}
    </>
  )
}

export default ActionButtons
