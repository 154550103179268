import React from 'react'
import { Link } from 'react-router-dom'

import { AppBar, Toolbar, Container, Button, Hidden, Box } from '@mui/material'

import { nav } from 'utils/pageConstants'

import MobileNav from 'components/pages/MobileNav'

import logo from 'assets/pages/colour-logo.png'

export default function Header() {
  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          padding: '0px',
          boxShadow: 'none',
          background: '#FFFFFF !important',
        }}
      >
        <Container disableGutters>
          <Toolbar
            sx={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
          >
            <Link to="/" style={{ height: `50px` }}>
              <img
                src={logo}
                alt="Homewise Real Estate"
                height="50"
                width="112"
              />
            </Link>
            <Box sx={{ display: 'flex' }}>
              <Hidden mdDown>
                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                  {nav.map((page) => (
                    <Link to={page.slug} key={page.id}>
                      <Button
                        sx={{
                          display: 'block',
                          marginTop: `0`,
                          padding: `8px 1.5rem 6px`,
                          textTransform: `none`,
                          fontWeight: `400`,
                        }}
                      >
                        {page.label}
                      </Button>
                    </Link>
                  ))}
                </Box>
                <Button
                  component="a"
                  target="_blank"
                  href={'https://my.thinkhomewise.com'}
                  color="primary"
                  variant="contained"
                  sx={{ marginTop: `0`, marginLeft: `2rem` }}
                >
                  {'Mortgage Options'}
                </Button>
              </Hidden>

              <Hidden mdUp>
                <MobileNav />
              </Hidden>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar sx={{ display: 'flex', flexDirection: `row` }} />
    </div>
  )
}
