import * as React from 'react'
import Container from '@mui/material/Container'
import BackButton from 'components/common/BackBtn'

export default function AuthWrapper({ children }) {
  return (
    <section
      style={{
        minHeight: '100vh',
        display: 'flex',
        background: `linear-gradient(180deg, #F3F9FD 52.08%, #FFFFFF 99.99%, rgba(219, 234, 247, 0) 100%, rgba(219, 234, 247, 0) 100%, #F5F7FA 100%)`,
      }}
    >
      <BackButton />
      <Container component="main" maxWidth="xs">
        {children}
      </Container>
    </section>
  )
}
