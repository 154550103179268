import React from 'react'
import { NumericFormat } from 'react-number-format'

export default React.forwardRef(function PriceNumberFormat(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      // isNumericString
      prefix="$"
    />
  )
})
