import React, { useContext, useState, useEffect, useMemo } from 'react'

import Pagination from '@mui/material/Pagination'
import { Grid, Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { debounce } from '@mui/material/utils'
import { withTranslation } from 'react-i18next'

// import ListingCard from './ListingCard'
import ListingCard from '../common/ListingCard'
import LoadingListingCard from './LoadingListingCard'

import { store } from '../../store/app/Store'
import UserContext from '../../store/app/Context'

const ListingsSection = (props) => {
  const theme = useTheme()

  const styles = {
    subtitle: {
      marginTop: `-1rem`,
    },
    listing: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }

  /** Calculate the width of the current section and set the width of the listing cards */
  const isSmall = window.innerWidth < 1200

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)

  const { fetchMapPinsListings } = useContext(UserContext)
  const globalState = useContext(store)
  const { state } = globalState

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        await request()
      }, 200),
    []
  )

  /** Initial load of listings, sets listings array to first 8 results */
  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 0)

    let params = {
      ...state.searchParams,
      resultsPerPage: 8,
      pageNum: page,
    }

    delete params['area']
    delete params['city']
    delete params['listings']
    delete params['aggregates']
    delete params['clusterPrecision']
    delete params['clusterLimit']

    // if there is a state.polygon.path available, then add the city/neighborhood to reqData
    if (state.polygon.path) {
      if (state.polygon.type === 'city') params.city = [state.polygon.name]
      if (state.polygon.type === 'neighborhood')
        params.neighborhood = [state.polygon.name]
    }

    fetch(async () => {
      const results = await fetchMapPinsListings(params, false)
      setListings(results?.data)
      setPageCount(results?.count)
      setLoading(false)
    })
  }, [page, state.searchParams, fetchMapPinsListings, fetch, state.polygon])

  return (
    <>
      <section style={styles.listing}>
        <Container>
          <Grid
            container
            spacing={{
              xs: 0,
              md: 1,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              justifyItems="center"
              alignItems="center"
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              {loading
                ? Array.from({ length: 8 }).map((item, index) => (
                    <Grid item sm={6} key={index}>
                      <LoadingListingCard key={index} smallMode={isSmall} />
                    </Grid>
                  ))
                : listings &&
                  listings.map((listing, index) => (
                    <Grid item sm={6} key={index}>
                      <ListingCard
                        data={listing}
                        key={index}
                        color={'#054D85'}
                      />
                    </Grid>
                  ))}
              <Pagination
                count={Math.ceil(pageCount / 8)}
                color="primary"
                page={page}
                onChange={(event, value) => {
                  setLoading(true)
                  setPage(value)
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}

export default withTranslation('common')(ListingsSection)
