import React from 'react'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'

import ListingCard from 'components/common/ListingCard'
import LoadingListingCard from 'components/results-section/LoadingListingCard'

import { isEmpty, startCase } from 'lodash'

export default function ListView(props) {
  const { loadingListings, addressHeader, listings } = props

  return (
    <>
      <Container component="div" sx={{ padding: `1.5rem 0rem 1rem` }}>
        {loadingListings ? (
          <h1 style={{ marginTop: `16px` }}>
            <Skeleton animation="wave" variant="text" width={280} height={40} />
          </h1>
        ) : isEmpty(addressHeader) ? (
          <h1 style={{ marginTop: `16px` }}>No city or province selected.</h1>
        ) : (
          <h1 style={{ marginTop: `16px` }}>
            {startCase(addressHeader.city) &&
              startCase(addressHeader.city) + ', '}
            <span className="highlight">
              {startCase(addressHeader.province)}
            </span>
          </h1>
        )}
      </Container>
      <Container component="div" style={{ position: `relative` }}>
        <Grid container spacing={3}>
          {loadingListings
            ? Array.from({ length: 4 }).map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <LoadingListingCard key={index} smallMode={true} />
                </Grid>
              ))
            : listings?.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <ListingCard data={item} key={index} color={'#054D85'} />
                </Grid>
              ))}
        </Grid>
      </Container>
    </>
  )
}
