import React, { useContext, useState } from 'react'
import { Link } from 'components/common/CustomLink'
import AuthContext from '../../store/auth/authContext'

// import Table from '@mui/material/Table'
// import TableBody from '@mui/material/TableBody'
// import TableHead from '@mui/material/TableHead'
// import TableCell from '@mui/material/TableCell'
// import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { formatCurrency, formatDetailPagePath } from 'utils/formats'

export default function ListingHistory(props) {
  const { user } = useContext(AuthContext)
  const { data, listing, error } = props
  const [seeMore, setSeeMore] = useState(false)
  const [sliceLimit, setSliceLimit] = useState(3)
  const valueMapping = {
    New: 'New',
    Pc: 'Price Change',
    Sld: 'Sold',
    Lsd: 'Leased',
    Lce: 'Leased conditionally with escape clause',
    Sce: 'Sold conditionally with escape clause',
    Lc: 'Lease conditionally',
    Sc: 'Sold conditionally',
    Exp: 'Expired',
    Sus: 'Suspended',
    Ter: 'Terminated',
    Dft: 'Deal fell through',
  }

  return (
    <TableContainer
      component={Paper}
      style={{
        padding: `.25rem`,
        width: `calc(100% - .75rem)`,
        margin: `16px 0 32px 0`,
        textAlign: `center`,
        border: `1px solid rgba(20, 123, 201, 0.5)`,
        boxShadow: `0px 1px 0px #EBEBEB, -12px 24px 21px rgba(0, 0, 0, 0.03), 12px 24px 21px rgba(0, 0, 0, 0.03)`,
        borderRadius: `6px`,
        display: `flex`,
      }}
    >
      {!user?.activeSession ? (
        <p
          style={{
            textAlign: `center`,
            width: `100%`,
            padding: `1rem 1rem 1.5rem`,
          }}
        >
          <small>
            Local rules require you to be signed in to view this home’s
            history.&nbsp;
            <Link
              to={`/auth/login?redirect=${formatDetailPagePath(
                listing?.address,
                listing?.mlsNumber,
                listing?.boardId
              )}`}
              className="link"
            >
              Sign In
            </Link>
            &nbsp;or&nbsp;
            <Link
              to={`/auth/new-account?redirect=${formatDetailPagePath(
                listing?.address,
                listing?.mlsNumber,
                listing?.boardId
              )}`}
              className="link"
            >
              Join
            </Link>
            &nbsp;for free with no obligation.
          </small>
        </p>
      ) : error ? (
        <p
          style={{
            textAlign: `center`,
            width: `100%`,
            padding: `1rem 1rem 1.5rem`,
          }}
        >
          <small>There was an error loading previous listing data…</small>
        </p>
      ) : data ? (
        <Container spacing={0} disableGutters sx={{ padding: 0 }}>
          {data.slice(0, sliceLimit).map((item, index) => (
            <Grid
              item
              xs={12}
              style={
                index % 2 === 0
                  ? { background: `#f3f9fd`, padding: `10px` }
                  : { padding: `10px` }
              }
            >
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <p>
                  <strong>
                    {item.lastStatus === 'Sld'
                      ? formatCurrency(item.soldPrice)
                      : formatCurrency(item.listPrice)}
                  </strong>
                </p>
                <p style={{ color: `#147bc9`, fontWeight: `700` }}>
                  {valueMapping[item.lastStatus]}
                </p>
                <Link
                  to={formatDetailPagePath(
                    listing?.address,
                    item?.mlsNumber,
                    item?.boardId
                  )}
                >
                  <Button variant="contained" sx={{ marginTop: `0` }}>
                    View
                  </Button>
                </Link>
              </Box>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <p>
                  <small>
                    List: {new Date(item.listDate).toISOString().split('T')[0]}{' '}
                    | End:{' '}
                    {
                      new Date(item.timestamps.expiryDate)
                        .toISOString()
                        .split('T')[0]
                    }
                  </small>
                </p>
                <p>
                  <strong>{item.mlsNumber}</strong>
                </p>
              </Box>
            </Grid>
          ))}
          {data?.length > 3 && (
            <Grid container>
              <Grid
                item
                xs={12}
                justifyItems="center"
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                }}
              >
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    setSeeMore(!seeMore)
                    setSliceLimit(seeMore ? 3 : data.length)
                  }}
                >
                  {data.length > 3 && !seeMore ? 'See More' : 'See Less'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      ) : (
        <p
          style={{
            textAlign: `center`,
            width: `100%`,
            padding: `1rem 1rem 1.5rem`,
          }}
        >
          <small>Loading previous listing data…</small>
        </p>
      )}
    </TableContainer>
  )
}
