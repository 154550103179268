import React from 'react'
import { withTranslation } from 'react-i18next'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'

// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import lenders from '../../assets/lender-lockup-v3.png'
import hwLogo from '../../assets/homewise-logo.png'

const SidebarMortgage = (props) => {
  return (
    <Paper
      style={{
        padding: `1rem 1.5rem 1rem`,
        margin: `1rem 0 1rem`,
        textAlign: `center`,
        border: `1px solid rgba(20, 123, 201, 0.5)`,
        boxShadow: `0px 1px 0px #EBEBEB, -12px 24px 21px rgba(0, 0, 0, 0.03), 12px 24px 21px rgba(0, 0, 0, 0.03)`,
        borderRadius: `6px`,
      }}
    >
      <Box className="interest-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img src={hwLogo} alt="Homewise" width="125" />
            <Hidden mdUp>
              <h3 style={{ margin: `.8rem auto` }}>Get Pre-Approved Today!</h3>
              <p
                style={{
                  fontSize: `.8rem`,
                  lineHeight: `1.2rem`,
                }}
              >
                We find your best mortgage rate and features from 30+ banks and
                lenders and provide a personal Advisor.
              </p>
              <Button
                component="a"
                href={`https://my.thinkhomewise.com/m/real_estate/${props?.mlsNumber}`}
                color="primary"
                variant="contained"
                target="_blank"
                sx={{ width: `100%` }}
                id="mortgageLink_mobile_sideBar"
              >
                {'Get Your Mortgage'}
              </Button>
              <img
                src={lenders}
                width="300"
                alt="Homewise Lenders"
                style={{
                  height: `auto`,
                  display: `block`,
                  margin: `1rem auto 0`,
                }}
              />
            </Hidden>
            <Hidden smDown>
              <p
                style={{
                  fontSize: `.8rem`,
                  lineHeight: `1.2rem`,
                }}
              >
                We'll find your best <strong>pre-approval and approval</strong>{' '}
                mortgage rate and features from 30+ lenders
              </p>
              <img
                src={lenders}
                width="300"
                alt="Homewise Lenders"
                style={{
                  height: `auto`,
                  display: `block`,
                  margin: `1rem auto 0`,
                }}
              />
              <Button
                component="a"
                href={`https://my.thinkhomewise.com/m/real_estate/${props?.mlsNumber}`}
                color="primary"
                variant="contained"
                target="_blank"
                sx={{ width: `100%` }}
                id="mortgageLink_desktop_sideBar"
              >
                {'Get Your Mortgage'}
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default withTranslation('landing')(SidebarMortgage)
