import React from 'react'
import { Link } from 'components/common/CustomLink'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import { formatDetailPagePath } from '../../utils/formats'
import { isEmpty } from 'lodash'

const SearchSuggestions = (props) => {
  const { nearby = [], popular = [], recent = [], loading = true } = props

  return (
    <>
      {!(isEmpty(nearby) || isEmpty(popular) || isEmpty(recent)) && (
        <div style={{ background: `#fff`, padding: `2rem 0` }}>
          <Container component="main" maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <h3 className="suggestion-header">Popular Searches</h3>
                <ul className="suggestion-list">
                  {/* skeletons based on loading */}
                  {loading
                    ? Array.from({ length: 10 }).map((item, index) => (
                        <li key={index}>
                          <Skeleton variant="text" />
                        </li>
                      ))
                    : nearby.map((item, index) => (
                        <li key={index}>
                          <Link to={`/list/${item.state}/${item.name}`}>
                            {item?.name + ' Homes'}
                          </Link>
                        </li>
                      ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <h3 className="suggestion-header">Nearby Cities</h3>
                <ul className="suggestion-list">
                  {loading
                    ? Array.from({ length: 10 }).map((item, index) => (
                        <li key={index}>
                          <Skeleton variant="text" />
                        </li>
                      ))
                    : nearby.map((item, index) => (
                        <li key={index}>
                          <Link to={`/list/${item.state}/${item.name}`}>
                            {item?.name}
                          </Link>
                        </li>
                      ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <h3 className="suggestion-header">Popular Cities</h3>
                <ul className="suggestion-list">
                  {loading
                    ? Array.from({ length: 10 }).map((item, index) => (
                        <li key={index}>
                          <Skeleton variant="text" />
                        </li>
                      ))
                    : popular.map((item, index) => (
                        <li key={index}>
                          <Link to={`/list/${item.state}/${item.name}`}>
                            {item?.name}
                          </Link>
                        </li>
                      ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <h3 className="suggestion-header">Recent Listings</h3>
                <ul className="suggestion-list">
                  {loading
                    ? Array.from({ length: 10 }).map((item, index) => (
                        <li key={index}>
                          <Skeleton variant="text" />
                        </li>
                      ))
                    : recent.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={formatDetailPagePath(
                              item?.address,
                              item?.mlsNumber,
                              item?.boardId
                            )}
                          >
                            {item?.name}
                          </Link>
                        </li>
                      ))}
                </ul>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  )
}

export default SearchSuggestions
