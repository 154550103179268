import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Slider from '@mui/material/Slider'

import { formatCurrency } from '../../../../utils/formats'
import { store } from '../../../../store/app/Store'
import { humanReadableCurrency } from 'components/map/const'

const marksLease = [
  {
    value: 0,
    label: humanReadableCurrency(0),
  },
  {
    value: 1e3,
    label: humanReadableCurrency(1e3),
  },
  {
    value: 3e3,
    label: humanReadableCurrency(3e3),
  },
  {
    value: 5e3,
    label: humanReadableCurrency(5e3),
  },
  {
    value: 1e4,
    label: 'Max',
  },
]

/** A map of sales prices in an array for use in the slider along with their indexes */
const salePrices = [
  0, 50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000,
  500000, 550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000,
  950000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000, 1750000,
  2000000, 2250000, 2500000, 2750000, 3000000, 3250000, 3500000, 3750000,
  4000000, 4250000, 4500000, 4750000, 5000000, 5250000, 5500000, 5750000,
  6000000,
]

const marksSale = [
  {
    value: salePrices.indexOf(5e4) + 1,
    label: humanReadableCurrency(5e4),
  },
  {
    value: salePrices.indexOf(5e5) + 1,
    label: humanReadableCurrency(5e5),
  },
  {
    value: salePrices.indexOf(1e6) + 1,
    label: humanReadableCurrency(1e6),
  },
  {
    value: salePrices.indexOf(2e6) + 1,
    label: humanReadableCurrency(2e6),
  },
  {
    value: salePrices.indexOf(4e6) + 1,
    label: humanReadableCurrency(4e6),
  },
  {
    value: salePrices.indexOf(6e6) + 1,
    label: 'Max',
  },
]

export default function PriceMobile() {
  const globalState = useContext(store)
  const { state, dispatch } = globalState

  const [value, setValue] = useState(
    state.searchParams.type[0] === 'lease'
      ? [state.searchParams.minPrice || 0, state.searchParams.maxPrice || 1e4]
      : [
          salePrices.indexOf(state.searchParams.minPrice) + 1 || 0,
          salePrices.indexOf(state.searchParams.maxPrice) + 1 ||
            salePrices.length,
        ]
  )

  // On mount, if the store does not have a maxPrice set, based on the type, set it to the max value
  useEffect(() => {
    if (state.searchParams.type[0] === 'lease') {
      if (!state.searchParams.maxPrice) {
        const range = [
          state.searchParams.minPrice > 11e3
            ? 1e3
            : state.searchParams.minPrice || 0,
          1e4,
        ]
        setValue(range)
      }
    } else {
      if (!state.searchParams.maxPrice) {
        const range = [
          salePrices.indexOf(state.searchParams.minPrice) + 1 || 0,
          salePrices.length,
        ]
        setValue(range)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeCommitted = (event, newValue) => {
    let updatedSearchParams = {
      ...state.searchParams,
    }

    if (state.searchParams.type[0] === 'lease') {
      // if the max value is 10k, then only set the min value
      if (newValue[1] === 1e4) {
        updatedSearchParams.minPrice = newValue[0]
        delete updatedSearchParams.maxPrice
      } else {
        updatedSearchParams.minPrice = newValue[0]
        updatedSearchParams.maxPrice = newValue[1]
      }
    } else {
      // if the max value is 6m, then only set the min value
      if (newValue[1] >= 43) {
        updatedSearchParams.minPrice = salePrices[newValue[0] - 1]
        delete updatedSearchParams.maxPrice
      } else {
        updatedSearchParams.minPrice = salePrices[newValue[0] - 1]
        updatedSearchParams.maxPrice = salePrices[newValue[1] - 1]
      }
    }

    dispatch({
      type: 'searchParams',
      newVal: updatedSearchParams,
    })
  }

  /**
   * Custom value label format for the slider.
   * @param {number} value
   */
  const valueLabelFormat = (value) => {
    const maxPriceThreshold =
      state.searchParams.type[0] === 'lease' ? 1e4 : salePrices.length

    if (value === maxPriceThreshold) return 'Max'

    if (state.searchParams.type[0] === 'lease') return formatCurrency(value)

    return formatCurrency(salePrices[value - 1])
  }

  return (
    <Box
      component="div"
      sx={{
        width: { xs: 350, md: 600 },
        margin: { xs: '25px 10px 0px 4px', md: '25px 10px 0px 10px' },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {state.searchParams.type[0] === 'lease' ? (
            <Slider
              getAriaLabel={() => 'Price Range'}
              min={0}
              max={1e4}
              value={value}
              step={1e2}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              marks={marksLease}
            />
          ) : (
            <Slider
              getAriaLabel={() => 'Price Range'}
              min={1}
              max={salePrices.length}
              value={value}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              marks={marksSale}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
