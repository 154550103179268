import axios from 'axios'
import Config from '../config'

const baseUrl = Config.api.REALTY

/**
 * Search listings.
 * @param {Object} data
 * @param {Object} params
 * @returns {Promise}
 */ // eslint-disable-next-line
const searchListings = async (data, params) => {
  const { userId, ...outgoingData } = data

  // Remove empty values
  Object.keys(outgoingData).forEach((key) => {
    if (
      outgoingData[key] === null ||
      outgoingData[key] === undefined ||
      outgoingData[key] === '' ||
      outgoingData[key] === 0 ||
      Number.isNaN(outgoingData[key]) ||
      Number(outgoingData[key]) === 0
    ) {
      delete outgoingData[key]
    }
  })

  return await axios({
    method: 'POST',
    baseURL: baseUrl,
    url: '/listings',
    data: JSON.stringify(outgoingData),
    params,
  })
}

/**
 * Search listings via proxy.
 * @param {Object} data
 * @param {Object} params
 * @param {Object} options - Axios request options.
 * @returns {Promise}
 */
const searchListingsProxy = async (data, params, options) => {
  const { ...outgoingData } = data

  // Remove empty values
  Object.keys(outgoingData).forEach((key) => {
    if (typeof outgoingData[key] == 'boolean') return

    if (
      outgoingData[key] === null ||
      outgoingData[key] === undefined ||
      outgoingData[key] === '' ||
      outgoingData[key] === 0 ||
      Number.isNaN(outgoingData[key]) ||
      Number(outgoingData[key]) === 0
    ) {
      delete outgoingData[key]
    }
  })

  return await axios({
    method: 'GET',
    baseURL: baseUrl,
    url: '/listings',
    params: outgoingData,
    ...options,
  })
}

/**
 * Get the count of listings.
 * @param {Object} data
 * @param {Object} params
 * @returns {Promise}
 */
const getSearchListingsCount = async (data, params) => {
  const { userId, ...outgoingData } = data
  return await axios({
    method: 'POST',
    baseURL: baseUrl,
    url: '/listings?type=count',
    data: JSON.stringify(outgoingData),
    params,
  })
}

/**
 * Get a listing.
 * @param {String} listingId
 * @param {Object} params
 * @param {String} boardId
 * @returns {Promise}
 */
const getListing = async (listingId, boardId, params = null) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/listings/${listingId}`,
    params: params,
    headers: {
      'board-id': boardId,
    },
  })
}

/**
 * Get a listing via proxy.
 * @param {String} listingId
 * @param {Object} params
 * @param {String} boardId
 * @returns {Promise}
 */
const getListingViaProxy = async (listingId, boardId, params = null) => {
  return await axios({
    method: 'POST',
    url: `${baseUrl}/listings/${listingId}`,
    params: {
      boardId: boardId,
      ...params,
    },
  })
}

/**
 * Get suggestions.
 * @param {String} MLS Id
 * @param {String} boardId
 * @returns {Promise}
 */
const getSuggestions = async (listingId, boardId) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/listings/${listingId}/suggestions`,
    headers: {
      'board-id': boardId,
    },
  })
}

/**
 * Get similar listings.
 * @param {String} MLS Id
 * @returns {Promise}
 */
const getSimilarListings = async (listingId) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/listings/${listingId}/similar`,
  })
}

/**
 * Get a static map image url for a listing.
 * @param {Object} options
 * @returns {String}
 */
const getListingImageUrl = (options) => {
  const markers = {
    marker1: `${Config.google.staticMarkersUrl}/marker1.png`,
  }
  const mapUrl = 'https://maps.googleapis.com/maps/api/staticmap'
  const center = options.coordinates || 'Toronto,ON,Canada'
  const zoom = options.zoom || 10
  const size = `${options.width || 320}x${options.height || 240}`
  const key = Config.google.MAPS

  return `${mapUrl}?center=${center}&zoom=${zoom}&size=${size}&key=${key}&markers=icon:${
    markers[options.listingType]
  }%7C${center}`
}

/**
 * Autocomplete a search query.
 * @param {String} query
 * @returns {Promise}
 */
const autocomplete = async (params) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/autocomplete`,
    params,
  })
}

/**
 * Locations search endpoint.
 * @param {String} query
 * @returns {Promise}
 */
const getLocations = async (params) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/locations`,
    params,
  })
}

/**
 * Get a listing's history.
 * @param {String} token
 * @param {Object} params
 * @returns {Promise}
 */
const getListingHistory = async (token, params) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/listings/history`,
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const listingsApi = {
  // search: searchListings,
  search: searchListingsProxy,
  getListing,
  getListingViaProxy,
  getSimilarListings,
  getListingImageUrl,
  getSearchListingsCount,
  getSuggestions,
  autocomplete,
  getLocations,
  getListingHistory,
}

export default listingsApi
