import i18next from '../i18n'

export const colors = ['#F88078', '#007BFF', '#CCEFC4']

export const nav = [
  {
    id: 'nav1',
    label: 'Buying',
    slug: 'https://homewiserealestate.ca/buying',
  },
  {
    id: 'nav2',
    label: 'Selling',
    slug: 'https://homewiserealestate.ca/selling',
  },
  {
    id: 'nav3',
    label: 'About',
    slug: 'https://homewiserealestate.ca/about',
  },
  {
    id: 'nav4',
    label: 'Contact',
    slug: 'https://homewiserealestate.ca/contact',
  },
  // {
  //   id: 'nav5',
  //   label: 'Map Search',
  //   slug: '/map',
  // },
]

export const directions = {
  Horizontal_Text_Photo: 'row',
  Horizontal_Photo_Text: 'row-reverse',
  Vertical_Text_Photo: 'column',
  Vertical_Photo_Text: 'column-reverse',
}

export const imageFades = {
  Horizontal_Text_Photo: 'fade-right',
  Horizontal_Photo_Text: 'fade-left',
  Vertical_Text_Photo: 'fade',
  Vertical_Photo_Text: 'fade',
}

export const copyFades = {
  Horizontal_Text_Photo: 'fade-left',
  Horizontal_Photo_Text: 'fade-right',
  Vertical_Text_Photo: 'fade',
  Vertical_Photo_Text: 'fade',
}

export const provinces = [
  {
    value: '01',
    short: i18next.t('common:provinces.01.short'),
    label: i18next.t('common:provinces.01.name'),
  },
  {
    value: '02',
    short: i18next.t('common:provinces.02.short'),
    label: i18next.t('common:provinces.02.name'),
  },
  {
    value: '03',
    short: i18next.t('common:provinces.03.short'),
    label: i18next.t('common:provinces.03.name'),
  },
  {
    value: '04',
    short: i18next.t('common:provinces.04.short'),
    label: i18next.t('common:provinces.04.name'),
  },
  {
    value: '05',
    short: i18next.t('common:provinces.05.short'),
    label: i18next.t('common:provinces.05.name'),
  },
  {
    value: '06',
    short: i18next.t('common:provinces.06.short'),
    label: i18next.t('common:provinces.06.name'),
  },
  {
    value: '07',
    short: i18next.t('common:provinces.07.short'),
    label: i18next.t('common:provinces.07.name'),
  },
  {
    value: '08',
    short: i18next.t('common:provinces.08.short'),
    label: i18next.t('common:provinces.08.name'),
  },
  {
    value: '09',
    short: i18next.t('common:provinces.09.short'),
    label: i18next.t('common:provinces.09.name'),
  },
  {
    value: '10',
    short: i18next.t('common:provinces.10.short'),
    label: i18next.t('common:provinces.10.name'),
  },
  {
    value: '11',
    short: i18next.t('common:provinces.11.short'),
    label: i18next.t('common:provinces.11.name'),
  },
  {
    value: '12',
    short: i18next.t('common:provinces.12.short'),
    label: i18next.t('common:provinces.12.name'),
  },
  {
    value: '13',
    short: i18next.t('common:provinces.13.short'),
    label: i18next.t('common:provinces.13.name'),
  },
]

/**
 * The following data-set was sourced from the data available from here:
 * https://api.repliers.io/listings/property-types
 * Currently Supported boards: CREA, TRREB.
 *
 * NOTE: This data-set may need to be updated when more boards are added
 * to the repiers API.
 */
export const homeTypeOptions = [
  {
    label: 'All Property Types',
    value: {
      class: ['residential', 'condo'],
      propertyType: [],
      style: null,
    },
  },
  {
    label: 'Detached',
    value: {
      class: 'residential',
      propertyType: [
        // Unique First Value; label + class values
        'detached-r', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // TRREB Property Types
        'Cottage',
        'Detached',
        'Link',
        'Rural Resid',
        'Upper Level',

        // CREA Property Types
        'Single Family',
      ],
      style: [
        // CREA Styles
        'House',
        'House, Detached',
        'Detached',
        'House, Link',
        'House, Stacked',
        'House, Front And Back',
        'Mobile Home',
        'Mobile Home, Detached',
        'Manufactured Home',
        'Manufactured Home/Mobile',
        'Manufactured Home, Detached',
        'Manufactured Home/Mobile, Detached',
        'Two Apartment House, Detached',
        'Modular',
        'Modular, Detached',
        'Other, Detached',
        'Multi-Family, Detached',
        'Recreational',
        'Recreational, Detached',
        'Floathome',
        'Garden Home',

        // TRREB Styles
        '1 1/2 Storey',
        '2 1/2 Storey',
        '2-Storey',
        '3-Storey',
        'Backsplit 3',
        'Backsplit 4',
        'Backsplit 5',
        'Bungaloft',
        'Bungalow',
        'Bungalow-Raised',
        'Loft',
        'Other',
        'Sidesplit 3',
        'Sidesplit 4',
        'Sidesplit 5',
      ],
    },
  },
  {
    label: 'Semi-Detached',
    value: {
      class: ['residential', 'condo'],
      propertyType: [
        // Unique First Value; label + class values
        'semi-detached-rc', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // TRREB Property Types
        'Att/Row/Twnhouse',
        'Semi-Detached',

        // CREA Property Types
        'Single Family',
      ],
      style: [
        // CREA Styles
        'Semi-Detached',
        'House, Semi-Detached',
        'Duplex, Semi-Detached',
        'Two Apartment House, Semi-Detached',
        'Multi-Family, Semi-Detached',
        'Multi-Family, Side By Side',
        'Attached',
        'House, Attached',
        'House, Side By Side',
        'Multi-Family',
        'Multi-Family, Attached',

        // TRREB Styles
        '1 1/2 Storey',
        '2 1/2 Storey',
        '2-Storey',
        '3-Storey',
        'Apartment',
        'Bachelor/Studio',
        'Backsplit 3',
        'Backsplit 4',
        'Backsplit 5',
        'Bungaloft',
        'Bungalow',
        'Bungalow-Raised',
        'Loft',
        'Sidesplit 3',
        'Sidesplit 4',
      ],
    },
  },
  {
    // NOTE: Always keep this at index 2, it's used in handleClickType.
    label: 'Condo Apt',
    value: {
      class: ['condo', 'residential'],
      propertyType: [
        // Unique First Value; label + class values
        'condo-apt-cr', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // TRREB Property Types
        'Co-Op Apt',
        'Co-Ownership Apt',
        'Comm Element Condo',
        'Condo Apt',

        // CREA Property Types
        'Single Family',
      ],
      style: [
        // CREA Styles
        'Apartment',
        'Apartment, Attached',
        'Apartment, Detached',
        'Apartment, Semi-Detached',
        'Two Apartment House',
        'Two Apartment House, Attached',

        // TRREB Styles
        'Apartment',
        'Bachelor/Studio',
        'Bungaloft',
        '2-Storey',
        '3-Storey',
        'Bungalow',
        'Loft',
        'Multi-Level',
        'Other',
        'Stacked Townhse',
      ],
    },
  },
  {
    label: 'Condo Townhouse',
    value: {
      class: ['condo', 'residential'],
      propertyType: [
        // Unique First Value; label + class values
        'condo-townhouse-cr', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // TRREB Property Types
        'Condo Townhouse',
        'Det Condo',
        'Leasehold Condo',
        'Semi-Det Condo',
        'Upper Level',

        // CREA Property Types
        'Single Family',
      ],
      style: [
        // CREA Styles
        'Row / Townhouse',
        'Row / Townhouse, Attached',
        'Row / Townhouse, Semi-Detached',
        'Row / Townhouse, Detached',

        // TRREB Styles
        '2-Storey',
        '3-Storey',
        'Bungaloft',
        'Bungalow',
        'Loft',
        'Multi-Level',
        'Other',
        'Stacked Townhse',
      ],
    },
  },
  {
    label: 'Multiplex',
    value: {
      class: ['residential', 'condo'],
      propertyType: [
        // Unique First Value; label + class values
        'multiplex-rc', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // TRREB Property Types
        'Duplex',
        'Fourplex',
        'Multiplex',
        'Triplex',

        // CREA Property Types
        'Multi-family',
      ],
      style: [
        // CREA Styles
        'Duplex',
        'Triplex',
        'Fourplex',
        'Duplex, Attached',
        'Triplex, Attached',
        'Fourplex, Attached',
        'Duplex, Detached',
        'Triplex, Detached',
        'Fourplex, Detached',
        'Duplex, Up And Down',

        // TRREB Styles
        '1 1/2 Storey',
        '2 1/2 Storey',
        '2-Storey',
        '3-Storey',
        'Apartment',
        'Backsplit 3',
        'Backsplit 4',
        'Bungaloft',
        'Bungalow',
        'Bungalow-Raised',
        'Loft',
        'Other',
        'Sidesplit 4',
      ],
    },
  },
  {
    label: 'Vacant Land',
    value: {
      class: ['commercial', 'condo', 'residential'],
      propertyType: [
        // Unique First Value; label + class values
        'vacant-land-ccr', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // CREA Property Types
        'Vacant Land',

        // TRREB Property Types
        'Vacant Land Condo',
        'Land',
      ],
      style: null,
    },
  },
  {
    label: 'Other',
    value: {
      class: ['residential', 'condo'],
      propertyType: [
        // Unique First Value; label + class values
        'other-rc', // This is a custom value not needed by the API, but used to determine if the user has selected this particular option.

        // CREA Property Types
        'Parking',
        'Single Family',

        // TRREB Property Types
        'Farm',
        'Other',
        'Parking Space',
        'Time Share',
      ],
      style: [
        // CREA Styles
        'Offices',
        'Unknown',
        'No Building',
        'Commercial Mix',
        'Other, Attached',
        'Recreational, Unknown',
        'Attached',
        'Unknown',
        'Apartment',
        'Detached',
        'Floathome',
        'Other, Detached',
        'Semi-detached',
        'Special Purpose',

        // TRREB Styles
        '2-Storey',
        'Apartment',
        'Bachelor/Studio',
        'Bungalow',
        'Loft',
        'Other',
        'Stacked Townhse',
        'Apartment',
        'Multi-Level',
        'Other',
      ],
    },
  },
]

export const activeStatuses = [
  'New', // New
  'Pc', // Price Change
]

export const soldStatuses = [
  'Sld', // Sold
  'Sce', // Sold Conditional with escape clause
  'Sc', // Sold Conditional
]

export const leasedStatuses = [
  'Lsd', // Leased
  'Lc', // Leased Conditional
]

export const deListedStatuses = [
  'Exp', // Expired
  'Sus', // Suspended
  'Ter', // Terminated
  'Dft', // Deal Fell Through
]

export const historyBoardIds = ['2']
