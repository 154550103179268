import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import { useNavigate } from 'utils/hooks/useCustomNavigate'
import { Loader } from '@googlemaps/js-api-loader'
import * as c from '../../map/const'

import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import TourIcon from '@mui/icons-material/Tour'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { styled, lighten } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import { startCase, lowerCase } from 'lodash'

import { formatDetailPagePath, formatListingName } from 'utils/formats'
import UserContext from 'store/app/Context'
import { store } from 'store/app/Store'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 0,
  padding: '10px 8px 10px 4px',
  color: theme.palette.primary.secondary,
  backgroundColor: lighten(theme.palette.background.dark, 0.7),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const autocompleteService = { current: null }

const Search = (props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const resultsLimit = isMobile ? 3 : 6

  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const { autocompleteSearch, fetchLocations } = useContext(UserContext)

  const mapStatus = useRef(state.mapStatus.loaded)
  const [options, setOptions] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [inputValue, setInputValue] = useState(state.propertyInfoAddress)
  const [value, setValue] = useState(state.propertyInfoAddress)

  useEffect(() => {
    // use the google maps loader to load the google maps api
    ;(async () => {
      const loader = new Loader(c.loaderOptions)

      try {
        await loader.load()
        mapStatus.current = true
        dispatch({ type: 'mapStatus', newVal: { loaded: true } })
      } catch (err) {
        console.log('error loading map for search: ', err)
      }
    })()
    // eslint-disable-next-line
  }, [])

  const searchOptions = {
    componentRestrictions: { country: 'CA' },
    // allow addresses and cities
    types: ['geocode'],
    // for location biasing, see:
    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#location
    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#locationbias
    locationbias: 'ipbias',
  }

  const fetch = useMemo(
    () =>
      debounce(async (request, callback) => {
        await autocompleteService.current.getPlacePredictions(
          { ...request, ...searchOptions },
          callback
        )
      }, 200),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    let active = true
    setLoading(true)

    try {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService()
      }

      if (!autocompleteService.current) {
        setLoading(false)
        return undefined
      }

      if (inputValue === '') {
        setOptions(value ? [value] : [])
        setValue('')
        dispatch({ type: 'propertyInfoAddress', newVal: '' })
        setLoading(false)
        return undefined
      }

      fetch({ input: inputValue }, async (results) => {
        if (active) {
          let newOptions = []

          if (value) newOptions = [value]

          if (results) {
            // loop through the results and add a type: 'place' to each result
            results = results?.slice(0, 3)?.map((result) => {
              result.type = 'locations'
              // remove the country from the description
              result.structured_formatting.secondary_text =
                result?.structured_formatting?.secondary_text
                  ?.split(',')
                  .slice(0, -1)
                  .join(',')

              result?.structured_formatting?.secondary_text?.replace(
                ', Canada',
                ''
              )
              return result
            })

            // if a result.description is the country name 'Canada', remove that result
            results = results.filter(
              (result) => result?.description !== 'Canada'
            )

            if (inputValue.length >= 2) {
              // split the input into components
              const query = inputValue?.split(',').map((item) => item.trim())

              // const listings = await autocompleteSearch(query[0]), locations = await fetchLocations(query[0]);

              // make concurent requests to the api for listings and locations
              const [listings, locations] = await Promise.all([
                autocompleteSearch(query[0]),
                fetchLocations(query[0]),
              ])

              if (locations?.search) {
                let areas = []

                if (locations?.search?.neighborhoods?.length > 0) {
                  const neighborhoods = locations?.search?.neighborhoods
                    .slice(0, 3)
                    .map((neighborhood) => {
                      // if a result[].structured_formatting.main_text is the same as startCase(lowerCase(`${neighborhood?.name}`)), remove that result
                      if (
                        results?.some(
                          (result) =>
                            result?.structured_formatting?.main_text ===
                            startCase(lowerCase(`${neighborhood?.name}`))
                        )
                      ) {
                        results = results.filter(
                          (result) =>
                            result?.structured_formatting?.main_text !==
                            startCase(lowerCase(`${neighborhood?.name}`))
                        )
                      }

                      return {
                        type: 'locations',
                        description: startCase(
                          lowerCase(`${neighborhood?.name}`)
                        ),
                        structured_formatting: {
                          main_text: startCase(
                            lowerCase(`${neighborhood?.name}`)
                          ),
                          secondary_text:
                            neighborhood?.city && neighborhood?.state
                              ? `${startCase(
                                  lowerCase(neighborhood?.city)
                                )}, ${startCase(
                                  lowerCase(neighborhood?.state)
                                )}`
                              : 'Neighborhood',
                          // generate the matched_substrings array from formatListingName(result.address)
                          main_text_matched_substrings: [],
                        },
                        place_id: neighborhood?.name,
                        state: neighborhood?.state,
                        city: neighborhood?.city,
                        reference: neighborhood?.name,
                        matched_substrings: [],
                        address: {
                          location: neighborhood?.location,
                          type: 'neighborhood',
                        },
                      }
                    })

                  areas = [...areas, ...neighborhoods]
                }

                results = [...results, ...areas]
              }

              if (listings?.listings) {
                // loop through the results and add a type: 'listings' to each result & reformat the object to match the google places object
                const transformedResults = listings?.listings
                  ?.slice(0, resultsLimit)
                  ?.map((result) => {
                    return {
                      type: 'listings',
                      description: formatListingName(result.address),
                      structured_formatting: {
                        main_text: formatListingName(result.address),
                        secondary_text: `${startCase(
                          lowerCase(`${result?.address?.city}`)
                        )}, ${startCase(
                          lowerCase(`${result?.address?.state}`)
                        )} | MLS# ${result?.mlsNumber} | For ${startCase(
                          result?.type
                        )}`,
                        // generate the matched_substrings array from formatListingName(result.address)
                        main_text_matched_substrings: [],
                      },
                      place_id: result?.mlsNumber,
                      reference: result?.mlsNumber,
                      matched_substrings: [],
                      address: result?.address,
                      mlsNumber: result?.mlsNumber,
                      boardId: result?.boardId,
                    }
                  })

                // combine the google places results with the listings results
                results = [...results, ...transformedResults]
              }
            }

            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
          setLoading(false)
        }
      })

      return () => {
        active = false
      }
    } catch (e) {
      console.log('error in search initialization: ', e)
      setLoading(false)
      return () => {
        active = false
      }
    }
    // eslint-disable-next-line
  }, [value, inputValue, fetch, autocompleteSearch, fetchLocations, dispatch])

  const handleChange = (event, newValue) => {
    if (!newValue) return

    let defaultZoom = 14

    if (newValue?.type === 'listings') {
      dispatch({
        type: 'propertyInfoAddress',
        newVal: newValue?.description,
      })
      navigate(
        formatDetailPagePath(
          newValue?.address,
          newValue?.mlsNumber,
          newValue?.boardId
        )
      )
    } else {
      if (newValue?.address?.type !== undefined) {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)

        dispatch({
          type: 'polygon',
          newVal: {
            name: newValue?.description,
            type: newValue?.address?.type,
            center: newValue?.address?.location,
          },
        })

        dispatch({
          type: 'propertyInfoAddress',
          newVal: newValue?.description,
        })

        // if the path has 'list' then persist the state and city in the url
        if (window?.location?.pathname?.includes('list'))
          navigate(`/list/${newValue?.state}/${newValue?.city}`)
        else navigate('/map')
      } else {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)

        dispatch({
          type: 'propertyInfoAddress',
          newVal: newValue?.description,
        })

        // geocode the place_id
        if (newValue?.place_id) {
          const geocoder = new window.google.maps.Geocoder()

          geocoder.geocode(
            { placeId: newValue?.place_id },
            (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  const address_components = results[0].address_components
                  const addressItems = address_components.reduce(
                    (seed, { short_name, types }) => {
                      types.forEach((t) => {
                        seed[t] = short_name
                      })
                      return seed
                    },
                    {}
                  )

                  const provinceLongName = address_components.find((item) =>
                    item.types.includes('administrative_area_level_1')
                  ).long_name

                  // get coordinates
                  const lat = results[0]?.geometry?.location?.lat()
                  const lng = results[0]?.geometry?.location?.lng()

                  dispatch({
                    type: 'settings',
                    newVal: {
                      ...state.settings,
                      center: { lat, lng },
                      zoom: defaultZoom,
                    },
                  })

                  dispatch({
                    type: 'searchParams',
                    newVal: {
                      ...globalState.state.searchParams,
                      // streetName: addressOnly.trim(), // disabled for now, we don't want to search by street name
                      area: provinceLongName,
                      city: [addressItems.locality],
                    },
                  })

                  // if the address has street_number, route, and locality, then send the coordinates to state.streetAddressPin
                  if (
                    addressItems?.street_number &&
                    addressItems?.route &&
                    addressItems?.locality
                  ) {
                    dispatch({
                      type: 'streetAddressPin',
                      newVal: {
                        visible: true,
                        location: {
                          lat,
                          lng,
                        },
                      },
                    })

                    defaultZoom = 17
                  }

                  navigate(`/map/${lat};${lng}?z=${defaultZoom}`)
                }
              }
            }
          )
        }
      }
    }
  }

  return (
    <Autocomplete
      id="search-autocomplete"
      sx={{
        height: 'auto',
        backgroundColor: 'white',
        borderRadius: '4px',
        maxWidth: '600px',
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.description
      }
      // filterOptions={(x) => x}
      // prevent the warning for `""` value
      isOptionEqualToValue={(option, value) =>
        option?.place_id === value?.place_id
      }
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      open={open}
      onOpen={() => {
        isMobile ? navigate('/search') : setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      noOptionsText="No Results"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('search.label')}
          fullWidth
          size="small"
          InputProps={{
            ...params.InputProps,
            'aria-label': t('search.label'),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params?.InputProps?.endAdornment}
              </React.Fragment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      options={options.sort((a, b) => -a?.type?.localeCompare(b?.type))}
      groupBy={(option) => option.type}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>
            <Grid container alignItems="center" spacing={0}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                {params?.group === 'listings' ? (
                  <TourIcon sx={{ paddingLeft: '2px' }} />
                ) : (
                  <LocationOnIcon sx={{ paddingLeft: '2px' }} />
                )}
              </Grid>
              <Grid
                item
                sx={{
                  width: 'calc(100% - 44px)',
                  wordWrap: 'break-word',
                  marginLeft: '-10px',
                }}
              >
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  <Typography variant="h6" color="text.secondary">
                    {startCase(params.group)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || []

        const parts =
          option?.type !== 'listings'
            ? parse(
                option?.structured_formatting?.main_text,
                matches?.map((match) => [
                  match?.offset,
                  match?.offset + match?.length,
                ])
              )
            : [
                {
                  text: option?.structured_formatting?.main_text,
                  highlight: false,
                },
              ]

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default Search
