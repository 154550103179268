import React from 'react'
import { Grid } from '@mui/material'
import Slider from 'react-slick'
import IconButton from '@mui/material/IconButton'

import StarRatings from 'react-star-ratings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const SamplePrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `2`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        left: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="previous"
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `-.25rem`,
        }}
      />
    </IconButton>
  )
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `10`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        right: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="next"
    >
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `.25rem`,
        }}
      />
    </IconButton>
  )
}

export const ReviewCarousel = ({ reviews }) => {
  let reviewsSlidingSettings = {
    dots: false,
    dotsClass: 'hwDots',
    centerMode: false,
    infinite: true,
    fade: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <Slider {...reviewsSlidingSettings} className="reviewSlider">
      {reviews.map((item) => (
        <Grid
          key={item.id}
          item
          style={{
            display: `flex`,
          }}
        >
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              padding: `0 4rem`,
            }}
          >
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-start`,
              }}
            >
              <p
                style={{
                  color: `#054d85`,
                  marginBottom: `0px`,
                  fontWeight: `500`,
                }}
              >
                {item.name}
              </p>
            </div>
            <div
              style={{
                color: `#4c5257`,
                lineHeight: `1.2rem`,
                textAlign: `left`,
              }}
              dangerouslySetInnerHTML={{ __html: item.body }}
            />
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-end`,
                flex: `1`,
                justifyContent: `flex-end`,
              }}
            >
              <StarRatings
                rating={parseFloat(item.rating.split('_').pop())}
                starRatedColor="#e5722b"
                starEmptyColor="#F5B58D"
                numberOfStars={parseFloat(item.rating.split('_').pop())}
                starDimension="20px"
                starSpacing="2px"
                name="Google Reviews"
              />
              <p
                style={{
                  color: `#147bc9`,
                  margin: `0px`,
                }}
              >
                <em>{item.platform}</em>
              </p>
            </div>
          </div>
        </Grid>
      ))}
    </Slider>
  )
}
