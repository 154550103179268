import React, { useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import config from 'config'

import { Button, Container, Grid, Paper, Hidden } from '@mui/material'

import Layout from 'layouts/StaticLayout'
import InterestForm from 'components/pages/InterestForm'
import ImageCarousel from 'components/pages/ImageCarousel'
import ImgWithFallback from 'components/pages/ImgWithFallback'
import { ReviewCarousel } from 'components/pages/ReviewCarousel'
import { RealtorCarousel } from 'components/pages/RealtorCarousel'

import HeroBg from 'assets/pages/backgrounds/hwr-masthead-1.png'
import cuteCouple from 'assets/pages/cute-couple.png'
import cuteCouple_webp from 'assets/pages/cute-couple.webp'
import keys from 'assets/pages/the-keys.png'
import keys_webp from 'assets/pages/the-keys.webp'
import shareImg from 'assets/pages/social_share_low.png'

import AOS from 'aos'
import 'aos/dist/aos.css'

import {
  directions,
  imageFades,
  copyFades,
  aboutSections,
  reviews,
  realtors,
} from 'utils/pageConstants'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const About = (props) => {
  const { t } = props

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, []) //eslint-disable-line

  return (
    <Layout>
      <div className={'root'}>
        <Helmet>
          <title>{t(`meta.title`)}</title>
          <meta name="description" content={t(`meta.description`)} />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={t(`meta.title`)} />
          <meta itemprop="description" content={t(`meta.description`)} />
          <meta itemprop="image" content={config.baseUrl + shareImg} />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://homewiserealestate.ca" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t(`meta.title`)} />
          <meta property="og:description" content={t(`meta.description`)} />
          <meta property="og:image" content={config.baseUrl + shareImg} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="homewiserealestate.ca" />
          <meta
            property="twitter:url"
            content="https://homewiserealestate.ca"
          />
          <meta name="twitter:title" content={t(`meta.title`)} />
          <meta name="twitter:description" content={t(`meta.description`)} />
          <meta name="twitter:image" content={config.baseUrl + shareImg} />
        </Helmet>
        {/* HERO WITH SVG ARROW */}
        <div style={{ position: `relative` }}>
          <section
            className={'section hero'}
            style={{
              backgroundImage: `url(${HeroBg})`,
              backgroundPosition: '0 0',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Container>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6} className={'heroNoCard'}>
                  <Paper className={'heroCard'}>
                    <h1
                      className={'boostSize'}
                      dangerouslySetInnerHTML={{
                        __html: t('hero.title', {
                          start: "<span class='highlight'>",
                          end: '</span>',
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    />
                    <p>{t('hero.copy')}</p>
                    <Button
                      component="a"
                      href="#getStarted"
                      color="primary"
                      variant="contained"
                      className="hw_btn_primary_main"
                    >
                      {t('hero.cta')}
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </section>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{
              color: '#ffffff',
              fontSize: `2rem`,
              position: `absolute`,
              bottom: `0`,
              left: `50%`,
              marginLeft: `-.65rem`,
            }}
          />
          <svg
            className={'angleSm'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              fill="#f5f7fa"
              d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
            ></path>
          </svg>
        </div>

        <Container maxWidth="lg" style={{ padding: `2rem 0` }}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={10} sm={8}>
              <h2 style={{ textAlign: `center` }}>
                <span className="sm-font" style={{ opacity: 0.6 }}>
                  Why Choose
                </span>
                <br />
                <span className="highlight">Homewise Real Estate</span>
              </h2>
              <div
                className={'html'}
                dangerouslySetInnerHTML={{
                  __html: `<p>At Homewise Real Estate, we marry incredible Realtors with industry leading technology to help you find your perfect home faster and easier than ever before. Looking to sell? Our experienced team will help you sell your home quickly and at a price you’ll love. Homewise Real Estate also partners with our in house mortgage team to help buyers have an end to end process that will save you time and money.</p>`,
                }}
              />
            </Grid>
          </Grid>
        </Container>

        {/* Realtor Carousel */}
        <section
          style={{
            background: `#ffffff`,
            boxShadow: `0 0.5rem 1rem rgb(0 0 0 / 15%)`,
          }}
        >
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} data-aos="fade-up" data-aos-offset={300}>
                <h2 style={{ textAlign: `center` }}>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    AN AMAZING TEAM OF
                  </span>
                  <br />
                  <span className="highlight">Industry Experts</span>
                </h2>
              </Grid>
              <Grid item xs={12}>
                <RealtorCarousel realtors={realtors} directions={directions} />
              </Grid>
            </Grid>
          </Container>
        </section>

        {aboutSections.map((item) => (
          <span key={item.id}>
            <Hidden mdDown>
              <Container
                maxWidth="lg"
                key={item.id}
                style={{ padding: `2rem 0` }}
              >
                <Grid
                  container
                  spacing={6}
                  alignItems="center"
                  justifyContent="center"
                  style={{ overflow: `hidden` }}
                  direction={directions[item.style]}
                >
                  <Grid item xs={10} sm={5} data-aos={imageFades[item.style]}>
                    <h2
                      style={{
                        marginTop: `0rem`,
                        marginBottom: `0rem`,
                        fontWeight: `900`,
                      }}
                    >
                      {item.title}
                    </h2>
                    <p className="subtitle">{item.subtitle}</p>
                    <div
                      className={'html'}
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />

                    {Object.values(item).includes('buttons') &&
                      item.buttons.map((item) => (
                        <Button
                          variant="contained"
                          color={item.primary ? 'primary' : 'secondary'}
                          className={
                            item.primary ? 'hw_btn_primary' : 'hw_btn_secondary'
                          }
                          href={item.link}
                          key={item.id}
                        >
                          {item.label}
                        </Button>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    style={{ textAlign: `right` }}
                    data-aos={copyFades[item.style]}
                  >
                    {item.photos.length > 1 ? (
                      <ImageCarousel images={item.photos} />
                    ) : (
                      item.photos.map((item, index) => (
                        <ImgWithFallback
                          key={index}
                          src={item.src_webp}
                          fallback={item.src}
                          width="300"
                          alt={item.alt}
                          className={'fullWidth rounded'}
                        />
                      ))
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
            <Hidden mdUp>
              <Container
                maxWidth="lg"
                key={item.id}
                style={{ padding: `2rem 0` }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ overflow: `hidden` }}
                  direction={directions[item.style]}
                >
                  <Grid item xs={10} sm={5} data-aos={copyFades[item.style]}>
                    {item.photos.length > 1 ? (
                      <ImageCarousel images={item.photos} />
                    ) : (
                      item.photos.map((item, index) => (
                        <ImgWithFallback
                          key={index}
                          src={item.src_webp}
                          fallback={item.src}
                          width="300"
                          alt={item.alt}
                          className={'fullWidth rounded'}
                        />
                      ))
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    data-aos={imageFades[item.style]}
                    style={{ textAlign: `center` }}
                  >
                    <h2
                      style={{
                        marginTop: `1rem`,
                        marginBottom: `0rem`,
                      }}
                    >
                      {item.title}
                    </h2>
                    <div
                      className={'html'}
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />

                    {Object.values(item).includes('buttons') &&
                      item.buttons.map((item) => (
                        <Button
                          variant="contained"
                          color={item.primary ? 'primary' : 'secondary'}
                          className={
                            item.primary ? 'hw_btn_primary' : 'hw_btn_secondary'
                          }
                          href={item.link}
                          key={item.id}
                        >
                          {item.label}
                        </Button>
                      ))}
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
          </span>
        ))}

        <section className={'section getStarted'}>
          {/* Reviews Carousel */}
          <Container maxWidth="lg" style={{ paddingBottom: `2rem` }}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} data-aos="fade-up" data-aos-offset={300}>
                <h2 style={{ textAlign: `center` }}>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    HOME BUYERS LIKE YOU
                  </span>
                  <br />
                  <span className="highlight">Love Homewise</span>
                </h2>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                data-aos="fade-in"
                data-aos-offset={300}
              >
                <ReviewCarousel reviews={reviews} />
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                data-aos="fade-in"
                data-aos-offset={300}
              >
                <ImgWithFallback
                  src={cuteCouple_webp}
                  fallback={cuteCouple}
                  width="300"
                  alt="Happy Homewise Homeowners"
                  className={'fullWidth rounded'}
                />
              </Grid>
            </Grid>
          </Container>

          {/* Lead Capture */}
          <Container id="getStarted">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={10}>
                <InterestForm />
              </Grid>
            </Grid>
          </Container>
        </section>

        {/* Final Mortgage callout */}
        <section className={'section finalCta'}>
          <Container>
            <Grid container justifyContent="center" alignItems="center">
              <Hidden mdDown>
                <Grid item xs={12} sm={2}>
                  <ImgWithFallback
                    src={keys_webp}
                    fallback={keys}
                    width="200"
                    alt={t('preapp.alt')}
                    style={{ marginBottom: `-10px` }}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={6} md={3}>
                <h2>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    {t('preapp.title1')}
                  </span>
                  <br />
                  <span className="highlight">{t('preapp.title2')}</span>
                </h2>
                <p>{t('preapp.copy')}</p>
                <Button
                  component="a"
                  href="https://my.thinkhomewise.com"
                  color="secondary"
                  variant="contained"
                  className="hw_btn_secondary"
                  sx={{ marginLeft: `0 !important` }}
                >
                  {t('preapp.cta')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default withTranslation('about')(About)
