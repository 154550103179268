import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardCheck,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

import { nav } from 'utils/constants'

export default function MobileNav() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {nav.map((page, index) => (
          <ListItem key={page.id} disablePadding>
            <ListItemButton component="a" href={page.slug}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  style={{
                    color: '#147bc9',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <ListItem disablePadding>
        <ListItemButton component="a" href={'./affordability'}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faAngleRight}
              style={{
                color: '#147bc9',
              }}
            />
          </ListItemIcon>
          <ListItemText primary={'Affordability'} />
        </ListItemButton>
      </ListItem> */}
      <Divider />
      <List>
        {['Mortgages'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component="a" href="https://my.thinkhomewise.com">
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  style={{
                    color: '#147bc9',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            sx={{ marginTop: `0`, paddingLeft: `10px`, paddingRight: `10px` }}
            aria-label="Open Menu"
          >
            <MenuIcon />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
