import React from 'react'

import IconButton from '@mui/material/IconButton'

import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const SamplePrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `2`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        left: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="previous"
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `-.25rem`,
        }}
      />
    </IconButton>
  )
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `10`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        right: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="next"
    >
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `.25rem`,
        }}
      />
    </IconButton>
  )
}

const ImageCarousel = ({ images }) => {
  console.log(images)
  const settings = {
    dots: true,
    dotsClass: 'hwDots',
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          fade: false,
          arrows: true,
        },
      },
    ],
  }
  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <div key={index}>
          <img src={item.src} key={index} alt="" width="300" />
        </div>
      ))}
    </Slider>
  )
}

export default ImageCarousel
