import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import SearchMobile from 'components/nav/Search/SearchMobile'

const Search = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (!isMobile) navigate('/')
  }, [isMobile, navigate])

  return <SearchMobile />
}

export default Search
