/** Uses new store. */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import config from 'config'

import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Helmet from 'react-helmet'

import LeafletMap from './Leaflet'
import ResultsDrawer from './ResultsDrawer'
import ActionButtons from './ActionButtons'

import { store } from '../../store/app/Store'
import i18next from '../../i18n'
import Header from '../nav/Header'
import MapFilters from '../nav/secondary/MapFilters'
import { Hidden } from '@mui/material'

import shareImg from 'assets/social_share_low.png'

const map = [
  {
    title: i18next.t('common:footer.map.items.1.title'),
    url: i18next.t('common:footer.map.items.1.url'),
  },
  {
    title: i18next.t('common:footer.map.items.2.title'),
    url: i18next.t('common:footer.map.items.2.url'),
  },
  {
    title: i18next.t('common:footer.map.items.3.title'),
    url: i18next.t('common:footer.map.items.3.url'),
  },
  {
    title: i18next.t('common:footer.map.items.4.title'),
    url: i18next.t('common:footer.map.items.4.url'),
  },
]

const Layout = ({ children }) => {
  const globalState = useContext(store)
  const { state } = globalState
  const { t } = useTranslation(['common'])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Helmet>
        <title>{t(`meta.title`)}</title>
        <meta name="description" content={t(`meta.description`)} />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={t(`meta.title`)} />
        <meta itemprop="description" content={t(`meta.description`)} />
        <meta itemprop="image" content={config.baseUrl + shareImg} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://homewiserealestate.ca" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t(`meta.title`)} />
        <meta property="og:description" content={t(`meta.description`)} />
        <meta property="og:image" content={config.baseUrl + shareImg} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="homewiserealestate.ca" />
        <meta property="twitter:url" content="https://homewiserealestate.ca" />
        <meta name="twitter:title" content={t(`meta.title`)} />
        <meta name="twitter:description" content={t(`meta.description`)} />
        <meta name="twitter:image" content={config.baseUrl + shareImg} />
      </Helmet>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={2}>
          <Header />
        </Grid>
        <Grid item xs={2}>
          <MapFilters list={'false'} map={'true'} />
        </Grid>
        <Grid
          container
          direction="row"
          style={
            isMobile
              ? {
                  height: `calc(100dvh - 113px)`,
                  margin: `0`,
                }
              : { height: `calc(100dvh - 163px)`, margin: `0` }
          }
        >
          <Grid item xs={state.resultsSection?.open ? 6 : 12}>
            <section
              className="mapSection"
              style={
                isMobile
                  ? {
                      height: `calc(100dvh - 113px)`,
                      margin: `0`,
                    }
                  : { height: `calc(100dvh - 163px)`, margin: `0` }
              }
            >
              {/* <MapComponent
                mapSettings={mapSettings}
                mapEvents={mapEvents}
                style={
                  state.resultsSection.open
                    ? { width: '50%' }
                    : { width: '100%' }
                }
              >
                <MapPins />
              </MapComponent> */}
              {/* <div className="mapSection__content">{children}</div> */}
              <ActionButtons />
              <LeafletMap
                style={
                  state.resultsSection.open
                    ? { width: '50%' }
                    : { width: '100%' }
                }
              />
            </section>
          </Grid>
          <Grid
            item
            xs={state.resultsSection?.open ? 6 : 0}
            style={
              isMobile
                ? {
                    height: `calc(100dvh - 113px)`,
                    margin: `0`,
                    zIndex: `1000`,
                  }
                : {
                    height: `calc(100dvh - 163px)`,
                    margin: `0`,
                    zIndex: `1000`,
                  }
            }
          >
            <ResultsDrawer open={state.resultsSection.open} />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid container direction="row">
            <Grid item xs={12}>
              <footer className="footer">
                <p>
                  © {new Date().getFullYear()} Homewise Real Estate, Brokerage.
                  All&nbsp;rights&nbsp;reserved.
                </p>
                <ul>
                  {map.map((option, key) => (
                    <li key={key} style={{ marginBottom: `0px` }}>
                      <a
                        href={option.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {option.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </footer>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </>
  )
}

export default Layout
