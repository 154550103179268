import { useEffect, useState } from "react";
import axios from "axios";

export const useSchema = (schemaUrl) => {
  const [schema, setSchema] = useState(null);
  useEffect(() => {
    const abortController = new AbortController();
    if (schemaUrl) {
      try {
        // convert the above call into an axios call
        async function getSchema() {
          const res = await axios.get(schemaUrl);
          setSchema(res.data);
        }
        getSchema();
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [schemaUrl]);
  return schema;
};
