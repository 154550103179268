import Ajv from 'ajv'
import addFormats from 'ajv-formats'

export const validateSchema = (t, schema, formData, ns, type) => {
  const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strict: false,
  })
  addFormats(ajv)

  const errorsForHumans = (item) => {
    const path = item.instancePath.split('/')
    const target = path[path.length - 1]
    // const keyword = item.keyword

    if (ns === 'userProfile') {
      // return validateUserProfile(item, target, path, keyword)
      // validateUserProfile doesnt exist
      return [target, t(`${ns}:${target}.${item.keyword}`)]
    }
    if (!ns) {
      return [target, t(`${target}.${item.keyword}`)]
    }
    return [target, t(`${ns}:${target}.${item.keyword}`)]
  }

  const validate = ajv.compile(schema)
  const valid = validate(formData)

  if (!valid) {
    const errorsList = validate.errors.map((item) => {
      return errorsForHumans(item)
    })
    //errorsList array of key-pair arrays [[],[],[]], convert it into the object
    return Object.fromEntries(errorsList)
  }
}

export const parseHeaderLinks = (links) => {
  return links.split(',').map((link) => {
    const splittedLink = link.split(';')
    const url = splittedLink[0].replace(/<(.*)>/, '$1').trim()
    const rel = splittedLink[1].replace(/rel="(.*)"/, '$1').trim()
    return { rel, url }
  })
}
