import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import Config from 'config'
import parse from 'parse-link-header'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'
import { formatListingName } from 'utils/formats'

import CustomPhoneNumber from 'utils/phoneNumber'
import Input from 'react-phone-number-input/input'

const Sidebar = (props) => {
  const { t, listing, mlsId, boardId, headshot } = props

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [thankyou, setThankyou] = useState(false)
  const [schemaUrl, setSchemaUrl] = useState('')

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')

  const schema = useSchema(schemaUrl)

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.REALTY + `/meta?group=homewise`)
      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      // console.log(urlsObject)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'user') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !Object.keys(schemaUrl).length

    if (isSchemaEmpty) {
      // console.log('getSchema()')
      getSchema()
      isSchemaEmpty = false
    }
  }, [schemaUrl])

  const is_email = (email) => {
    let minRegex = new RegExp(
      /^([^<>()[\],;:\s@"]+)@([^<>()[\],;:\s@"]+\.)+[a-zA-Z]{2,4}$/
    )
    return minRegex.test(email)
  }

  const pushLead = async (event) => {
    event.preventDefault()

    setLoading(true)
    setErrors({})
    // console.log('pushLead()')

    // Temporary is_email check
    const seemsEmaily = is_email(email)

    if (seemsEmaily) {
      let data = {
        user: {
          name: {
            first: firstname.trim(),
            last: lastname.trim(),
          },
          email: email,
          phone: phoneNumber.substring(2),
        },
        property: {
          city: listing.address.city,
          address: formatListingName(listing.address),
          price: parseFloat(listing.listPrice),
        },
        code: mlsId,
        boardId: boardId,
      }

      if (message) data.message = message

      // console.log(schema, data)
      let isSchemaEmpty = !Object.keys(schemaUrl).length

      const err = isSchemaEmpty
        ? true
        : validateSchema(t, schema, data, 'errors')
      // const err = false;

      if (!err) {
        try {
          const headers = {
            'Content-Type': 'application/json',
          }

          const body = JSON.stringify(data)
          const res = await axios.post(Config.api.CONTACT, body, { headers })

          if (res.status === 200) {
            setLoading(false)
            setThankyou(true)
          } else {
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      } else {
        setLoading(false)
        setErrors({
          ...err,
        })
        console.table(err)
      }
    } else {
      setLoading(false)
      setErrors({
        email: t('errors:email.format'),
      })
    }
  }

  return (
    <>
      <Paper
        style={{
          padding: `1rem 1.5rem 2rem`,
          // margin: `3rem 0 1.5rem`,
          textAlign: `center`,
          border: `1px solid rgba(20, 123, 201, 0.5)`,
          boxShadow: `0px 1px 0px #EBEBEB, -12px 24px 21px rgba(0, 0, 0, 0.03), 12px 24px 21px rgba(0, 0, 0, 0.03)`,
          borderRadius: `6px`,
        }}
      >
        <Box sx={{ display: `flex`, paddingBottom: `1rem` }}>
          {headshot && (
            <img src={headshot} width="65" height="65" alt="Homewise Realtor" />
          )}
          <span style={{ width: `100%` }}>
            <h3 style={{ marginTop: `.5rem`, marginBottom: `0` }}>
              Contact Homewise Realtor
            </h3>
            <p style={{ marginTop: `0`, marginBottom: `1rem` }}>
              <small>Tour this home and others</small>
            </p>
          </span>
        </Box>

        <Box
          className="interest-form"
          component="form"
          noValidate
          onSubmit={pushLead}
        >
          <Grid container spacing={2}>
            {thankyou ? (
              <Grid item xs={12} style={{ textAlign: `center` }}>
                <h1>👍</h1>
                <p>
                  <strong>{t('capture.success.msg')}</strong>
                </p>
                <p>{t('capture.success.copy')}</p>
              </Grid>
            ) : (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    id="leadCaptureFirstName"
                    size="small"
                    InputProps={{
                      autoComplete: 'chrome-off',
                    }}
                    value={firstname}
                    onChange={(e) => {
                      setFirstname(e.target.value)
                      if (errors.first) {
                        delete errors.first
                      }
                    }}
                    fullWidth
                    required
                    error={errors.first ? true : false}
                    helperText={errors.first ? errors.first : ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    id="leadCaptureLastName"
                    size="small"
                    InputProps={{
                      autoComplete: 'chrome-off',
                    }}
                    value={lastname}
                    onChange={(e) => {
                      setLastname(e.target.value)
                      if (errors.last) {
                        delete errors.last
                      }
                    }}
                    fullWidth
                    required
                    error={errors.last ? true : false}
                    helperText={errors.last ? errors.last : ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    id="leadCaptureEmail"
                    InputProps={{
                      autoComplete: 'chrome-off',
                    }}
                    size="small"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      if (errors.email) {
                        delete errors.email
                      }
                    }}
                    fullWidth
                    required
                    error={errors.email ? true : false}
                    helperText={errors.email ? errors.email : ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    // international={false}
                    defaultCountry="CA"
                    inputComponent={CustomPhoneNumber}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="message"
                    label="Extra Info"
                    name="message"
                    autoComplete="message"
                    size="small"
                    sx={{
                      backgroundColor: 'white',
                    }}
                    inputProps={{
                      style: { fontSize: `12px`, lineHeight: `15px` },
                    }}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={2}
                    placeholder={`I'd like to buy/sell a home like this & want to speak to a Homewise realtor.`}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value)
                      if (errors.message) {
                        delete errors.message
                      }
                    }}
                    error={errors.message ? true : false}
                    helperText={errors.message ? errors.message : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: `100%`,
                      display: `block`,
                      margin: `.5rem auto 0`,
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} style={{ color: `#fff` }} />
                    ) : (
                      'Contact Agent'
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

export default withTranslation('landing')(Sidebar)
