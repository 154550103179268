import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { store } from '../../../../store/app/Store'

import { soldStatuses, leasedStatuses, activeStatuses } from 'utils/constants'
const soldStatusesArray = [...soldStatuses, ...leasedStatuses]

// const soldOptions = [
//   'All',
//   '1d',
//   '3d',
//   '7d',
//   '30d',
//   '90d',
//   '180d',
//   '1yr',
//   '2yrs',
//   '3yrs',
//   '4yrs',
//   '5yrs',
//   '6yrs',
//   '7yrs',
// ]
const soldLabels = [
  'All',
  '1 day',
  '3 days',
  '7 days',
  '30 days',
  '90 days',
  '180 days',
  '1 Year Ago',
  '2 Years Ago',
  '3 Years Ago',
  '4 Years Ago',
  '5 Years Ago',
  '6 Years Ago',
  '7 Years Ago',
]
const soldValues = [
  0, 1, 3, 7, 30, 90, 180, 360, 720, 1080, 1440, 1800, 2160, 2520,
]

// const options = ['All', '1d', '3d', '7d', '14d', '30d', '90d']
const values = [0, 1, 3, 7, 14, 30, 90]
const labels = [
  'All',
  '1 day',
  '3 days',
  '7 days',
  '14 days',
  '30 days',
  '90 days',
]

export default function DaysActive() {
  const globalState = React.useContext(store)
  const { state, dispatch } = globalState

  const [open, setOpen] = React.useState(false)
  const [soldOpen, setSoldOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const anchorRefSold = React.useRef(null)

  const [active, setActive] = React.useState(
    Array.isArray(state?.searchParams?.status)
      ? state?.searchParams?.status?.includes?.('A')
      : false
  )

  const [soldActive, setSoldActive] = React.useState(
    Array.isArray(state?.searchParams?.status)
      ? state?.searchParams?.status?.includes?.('U')
      : false
  )

  const numberOfDaysSold = () => {
    if (state.searchParams.minSoldDate === 0) return 0

    if (!state.searchParams.minSoldDate) return 0

    const date = new Date(state.searchParams.minSoldDate)
    const today = new Date()
    const timeDiff = Math.abs(today.getTime() - date.getTime())
    const numOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

    // for any number of days return the closest value in the values array
    const final = values.reduce((prev, curr) =>
      Math.abs(curr - numOfDays) < Math.abs(prev - numOfDays) ? curr : prev
    )

    // if final is out of bound, return ''
    if (final > values[values.length - 1]) return ''

    return final
  }

  const [daysSold, setDaysSold] = React.useState(numberOfDaysSold())
  const [selectedIndexSold, setSelectedIndexSold] = React.useState(
    soldValues.indexOf(daysSold)
  )

  const numberOfDays = () => {
    if (state.searchParams.minListDate === 0) return 0

    if (!state.searchParams.minListDate) return 0

    const date = new Date(state.searchParams.minListDate)
    const today = new Date()
    const timeDiff = Math.abs(today.getTime() - date.getTime())
    const numOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

    if (numOfDays <= 1) return 0
    else if (numOfDays <= 2) return 1
    else if (numOfDays <= 4) return 3
    else if (numOfDays <= 8) return 7
    else if (numOfDays <= 15) return 14
    else if (numOfDays <= 31) return 30
    else if (numOfDays <= 91) return 90
    else return ''
  }

  const [daysOnHomewise, setDaysOnHomewise] = React.useState(numberOfDays())
  const [selectedIndex, setSelectedIndex] = React.useState(
    values.indexOf(daysOnHomewise)
  )

  const handleChangeDaysOnHomewise = (value) => {
    setDaysOnHomewise(values[value])

    // calculate the date from today minus the number of days
    const date = new Date()
    date.setDate(date.getDate() - values[value])

    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        // conver to YYYY-MM-DD
        minListDate:
          Number(values[value]) <= 0 ? 0 : date.toISOString().split('T')[0],
      },
    })
  }

  const handleChangeDaysSold = (value) => {
    setDaysSold(soldValues[value])

    // calculate the date from today minus the number of days
    const date = new Date()
    date.setDate(date.getDate() - soldValues[value])

    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        // conver to YYYY-MM-DD
        minSoldDate:
          Number(soldValues[value]) <= 0 ? 0 : date.toISOString().split('T')[0],
      },
    })
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    handleChangeDaysOnHomewise(index)
    setOpen(false)
  }

  const handleMenuItemClickSold = (event, index) => {
    setSelectedIndexSold(index)
    handleChangeDaysSold(index)
    setSoldOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleToggleSold = () => {
    setSoldOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  const handleCloseSold = (event) => {
    if (anchorRefSold.current && anchorRefSold.current.contains(event.target)) {
      return
    }

    setSoldOpen(false)
  }

  const handleClick = () => {
    let params = {
      ...state.searchParams,
    }

    if (active) {
      params.status = params.status.filter((status) => status !== 'A')

      if (params.status.length === 0) params.status = null

      delete params.minListDate

      if (Array.isArray(params?.lastStatus))
        params.lastStatus = params?.lastStatus.filter(
          (status) => !activeStatuses.includes(status)
        )
      else delete params.lastStatus
    }

    if (!active) {
      if (Array.isArray(params.status)) params.status.push('A')
      else params.status = ['A']
      params.minListDate = null

      if (Array.isArray(params?.lastStatus))
        params?.lastStatus.push(...activeStatuses)
      else params.lastStatus = activeStatuses
    }

    dispatch({
      type: 'searchParams',
      newVal: params,
    })
    setActive(!active)
  }

  const handleClickSold = () => {
    let params = {
      ...state.searchParams,
    }

    if (soldActive) {
      params.status = params.status.filter((status) => status !== 'U')

      if (params.status.length === 0) params.status = null

      delete params.minSoldDate

      if (Array.isArray(params?.lastStatus))
        params.lastStatus = params?.lastStatus.filter(
          (status) => !soldStatusesArray.includes(status)
        )
      else delete params.lastStatus
    }

    if (!soldActive) {
      if (Array.isArray(params.status)) params.status.push('U')
      else params.status = ['U']
      params.minSoldDate = null

      if (Array.isArray(params?.lastStatus))
        params?.lastStatus.push(...soldStatusesArray)
      else params.lastStatus = soldStatusesArray
    }

    dispatch({
      type: 'searchParams',
      newVal: params,
    })
    setSoldActive(!soldActive)
  }

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        aria-label="Days Active"
        className="daysActiveGroup"
      >
        <Button
          ref={anchorRef}
          onClick={handleClick}
          variant={active ? `contained` : `outlined`}
          sx={{
            fontWeight: `700`,
            background: active ? `#054d85` : `#fff`,
            color: active ? `#fff` : `#686868`,
            textTransform: `none`,
            fontSize: `10px`,
            // padding: `0 `,
            minWidth: `30px !important`,
          }}
        >
          Active
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select days active filter"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            color: `#686868`,
            textTransform: `none`,
            fontSize: `10px`,
            padding: `8px .5rem`,
          }}
        >
          {/* {options[selectedIndex]} */}
          <KeyboardArrowDownIcon fontSize="small" />
        </Button>
        <Button
          ref={anchorRefSold}
          onClick={handleClickSold}
          variant={soldActive ? `contained` : `outlined`}
          sx={{
            fontWeight: `700`,
            background: soldActive ? `#AA482F` : `#fff`,
            color: soldActive ? `#fff` : `#686868`,
            textTransform: `none`,
            fontSize: `10px`,
            // padding: `0 `,
            minWidth: `30px !important`,
          }}
        >
          Sold
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select days active filter"
          aria-haspopup="menu"
          onClick={handleToggleSold}
          sx={{
            color: `#686868`,
            textTransform: `none`,
            fontSize: `10px`,
            padding: `8px .5rem`,
          }}
        >
          {/* {soldOptions[selectedIndexSold]}{' '} */}
          <KeyboardArrowDownIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            sx={{ marginTop: `13px` }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {labels.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={soldOpen}
        anchorEl={anchorRefSold.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            sx={{ marginTop: `13px` }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseSold}>
                <MenuList id="split-button-menu2" autoFocusItem>
                  {soldLabels.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === selectedIndexSold}
                      onClick={(event) => handleMenuItemClickSold(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
