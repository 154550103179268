import React from 'react'
import ListingCard from './ListingCard'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useState } from 'react'

const Comparables = ({ data, boardId }) => {
  const [visibleCards, setVisibleCards] = useState(2)

  const handleLoadMore = () => {
    setVisibleCards(visibleCards + 2)
  }

  return (
    <>
      <Grid container spacing={2}>
        {data.slice(0, visibleCards).map((item, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <ListingCard data={item} boardId={boardId} key={index} />
          </Grid>
        ))}
      </Grid>
      {visibleCards < data.length && (
        <Grid container spacing={2}>
          <Grid item xs={12} alignItems={'center'} justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                margin: `1rem auto`,
                padding: `.25rem 1rem`,
                fontSize: `.65rem`,
              }}
              size="small"
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Comparables
