import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { withTranslation, useTranslation } from 'react-i18next'

import axios from 'axios'
import Config from 'config'
import parse from 'parse-link-header'

import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'

import AuthContext from '../../store/auth/authContext'

import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import logo from '../../assets/HWRE-logo-colour.png'

import Wrapper from './Wrapper'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'You are creating a Homewise account and agree to our '}
      <a
        href="https://thinkhomewise.com/page/terms/"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        Terms of Use
      </a>
      {' and '}
      <a
        href="https://thinkhomewise.com/page/privacy/"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        Privacy Policy
      </a>
      {'.'}
    </Typography>
  )
}

const NewAccount = (props) => {
  const context = useContext(AuthContext)
  const { signUp } = context
  const [schemaUrl, setSchemaUrl] = useState('')
  const schema = useSchema(schemaUrl)

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(context?.loading)
  const [searchParams] = useSearchParams()
  const redirect = searchParams?.get('redirect')
  const navigate = useNavigate()
  const [t] = useTranslation()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.CUSTOMER + `/meta?group=homewise`)
      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      // console.log(urlsObject)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'authRegister') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !schemaUrl.length

    if (isSchemaEmpty) {
      getSchema()
      isSchemaEmpty = false
    }
  }, [schemaUrl])

  const is_email = (email) => {
    let minRegex = new RegExp(
      /^([^<>()[\],;:\s@"]+)@([^<>()[\],;:\s@"]+\.)+[a-zA-Z]{2,4}$/
    )
    return minRegex.test(email)
  }

  const validate = () => {
    let isError = false
    // empty errors object
    Object.keys(errors).forEach(function (k) {
      delete errors[k]
    })

    if (password.length < 8) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: t('common:errors.userDetailsPasswordErrorLength'),
      }))
    }

    if (password.length < 1) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: t('common:errors.userDetailsPasswordError'),
      }))
    }

    if (password !== passwordConfirm) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: t('common:errors.userDetailsPasswordErrorMatch'),
      }))
    }

    return isError
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm)
  }

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault()
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setLoading(true)
    setErrors({})

    // Temporary is_email check
    const seemsEmaily = is_email(email)

    if (seemsEmaily) {
      let data = {
        name: {
          first: firstname.trim(),
          last: lastname.trim(),
        },
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
      }
      // console.log(data, schema)

      const err = validateSchema(t, schema, data, 'errors')
      const passwordErr = validate()
      if (!passwordErr) {
        if (!err) {
          try {
            signUp(
              email,
              firstname.trim(),
              lastname.trim(),
              password,
              setLoading,
              setErrors,
              errors,
              () => {
                // console.log('success')
                if (redirect && redirect !== '/real-estate') {
                  // console.log('redirect', redirect)
                  navigate(redirect?.replace('/real-estate', ''), {
                    replace: true,
                  })
                } else {
                  // console.log('redirect', '/')
                  navigate('/')
                }
              }
            )
          } catch (error) {
            setLoading(false)
            // console.log('signUp try error', error)
          }
        } else {
          setLoading(false)
          setErrors({
            ...err,
          })
          console.table(err)
        }
      } else {
        setLoading(false)
      }
    } else {
      // console.log('email is not valid')
      setLoading(false)
      setErrors({
        email: t('errors:email.format'),
      })
    }
  }

  return (
    <Wrapper>
      <SnackbarProvider
        maxSnack={3}
        style={{ pointerEvents: 'all' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width="220" alt="Homewise Real Estate" />
          <h1 style={{ margin: `2rem auto` }}>
            Welcome to <span className="highlight">Homewise</span>
          </h1>
          <p style={{ textAlign: `center` }}>
            Get hand-picked homes, insights into previous sales, and the perfect
            match for you.
          </p>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setFirstname(e.target.value)
                    if (errors.first) {
                      delete errors.first
                    }
                  }}
                  error={errors.first ? true : false}
                  helperText={errors.first ? errors.first : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setLastname(e.target.value)
                    if (errors.last) {
                      delete errors.last
                    }
                  }}
                  error={errors.last ? true : false}
                  helperText={errors.last ? errors.last : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    if (errors.email) {
                      delete errors.email
                    }
                  }}
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    if (errors.password) {
                      delete errors.password
                    }
                  }}
                  error={errors.password ? true : false}
                  helperText={errors.password ? errors.password : ''}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordConfirm"
                  label="Password Confirmation"
                  id="passwordConfirm"
                  type={showPasswordConfirm ? 'text' : 'password'}
                  autoComplete="new-password-confirm"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value)
                    if (errors.passwordConfirm) {
                      delete errors.passwordConfirm
                    }
                  }}
                  error={errors.passwordConfirm ? true : false}
                  helperText={
                    errors.passwordConfirm ? errors.passwordConfirm : ''
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                        >
                          {showPasswordConfirm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ display: `block`, margin: `2rem auto` }}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: `#ffffff`,
                  }}
                  size={20}
                  thickness={4}
                />
              ) : (
                'Create Account'
              )}
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Already have an account?{' '}
                  <Link
                    to={`/auth/login${window?.location?.search}`}
                    variant="body2"
                    className="link"
                  >
                    Login now!
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SnackbarProvider>
      <Copyright sx={{ mt: 5 }} />
    </Wrapper>
  )
}

export default withTranslation('landing')(NewAccount)
