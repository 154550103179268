/** NOTE: IF ANY OF THE FOLLOWING CONSTANTS ARE CHANGED, PLEASE UPDATE THEM AT: public\sw.js TOO. 🙏*/
const DB_NAME = 'realty-locations'
const DB_VERSION = 1
const DB_STORE_NAMES = ['cities', 'neighborhoods']
/** END OF NOTE */

/** Un-register the service worker & then re-register it. */
function remountServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister()
      }
    })

    // Re-register the service worker & activate it.
    navigator.serviceWorker
      .register('/real-estate/sw.js', { scope: 'real-estate/' })
      .then((registration) => {
        console.log('Service worker re-registered.')
      })
  }
}

/**
 * A simpler IDB wrapper to open a db connection,
 * then fetch locations data from the object store.
 * @param {String} query - The query to search for.
 * @returns {Object} - The locations data.
 */
export async function getLocationsFromIDB(query) {
  try {
    // Transform the query to lowercase.
    query = query.toLowerCase()

    const request = indexedDB.open(DB_NAME, DB_VERSION)

    const dbPromise = new Promise((res, rej) => {
      request.onerror = function (event) {
        rej('request.onerror event info: ', event)
      }

      request.onsuccess = async function (event) {
        const db = event.target.result

        // Asynchronously get the data from the object store that match the query, max 5 results, then return the data as response.search.cities & response.search.neighborhoods.
        const response = await new Promise((resolve, reject) => {
          const transaction = db.transaction(DB_STORE_NAMES, 'readonly')

          const citiesStore = transaction.objectStore('cities')
          const neighborhoodsStore = transaction.objectStore('neighborhoods')

          const citiesIndex = citiesStore.index('name')
          const neighborhoodsIndex = neighborhoodsStore.index('name')

          const citiesRequest = citiesIndex.getAll(
            IDBKeyRange.bound(query, query + '\uffff'),
            5
          )
          const neighborhoodsRequest = neighborhoodsIndex.getAll(
            IDBKeyRange.bound(query, query + '\uffff'),
            5
          )

          let results = { search: {} }

          citiesRequest.onsuccess = function (event) {
            results.search.cities = event.target.result
          }

          neighborhoodsRequest.onsuccess = function (event) {
            results.search.neighborhoods = event.target.result
            resolve(results)
          }
        })

        const data = await response

        res(data)

        db.close()
      }

      request.onupgradeneeded = function (event) {
        remountServiceWorker()
        rej('IndexedDB is being upgraded.')
      }
    })

    // await the dbPromise, for max 3 seconds, then throw an error.
    const locations = await Promise.race([
      dbPromise,
      new Promise((res, rej) => {
        setTimeout(() => {
          rej('getLocationsFromIDB() timed out.')
        }, 1000)
      }),
    ])

    if (typeof locations === 'object') return locations
    else throw new Error('getLocationsFromIDB() returned an invalid response.')
  } catch (e) {
    console.error('Error getting locations from IDB: ', e)
    // remountServiceWorker()
    return null
  }
}
