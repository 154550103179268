import { useEffect, useRef } from 'react'

/**
 * A simple hook to execute a callback at a given interval.
 * @param {Function} callback
 * @param {Number} delay
 * @returns {void}
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [callback, delay])
}
