import React, { useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import config from 'config'

import { Button, Container, Grid, Paper, Hidden } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PlaceIcon from '@mui/icons-material/Place'
import StarIcon from '@mui/icons-material/Star'

import Layout from 'layouts/StaticLayout'
import ImgWithFallback from 'components/pages/ImgWithFallback'
import LeadCapture from 'components/pages/LeadCapture'
import { ReviewCarousel } from 'components/pages/ReviewCarousel'

import cuteCouple from 'assets/pages/cute-couple.png'
import cuteCouple_webp from 'assets/pages/cute-couple.webp'
import keys from 'assets/pages/the-keys.png'
import keys_webp from 'assets/pages/the-keys.webp'
import google from 'assets/pages/google.png'
import shareImg from 'assets/pages/social_share_low.png'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { reviews } from 'utils/pageConstants'

const Contact = (props) => {
  const { t } = props

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, []) //eslint-disable-line

  return (
    <Layout>
      <div className={'root'}>
        <Helmet>
          <title>{t(`meta.title`)}</title>
          <meta name="description" content={t(`meta.description`)} />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={t(`meta.title`)} />
          <meta itemprop="description" content={t(`meta.description`)} />
          <meta itemprop="image" content={config.baseUrl + shareImg} />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://homewiserealestate.ca" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t(`meta.title`)} />
          <meta property="og:description" content={t(`meta.description`)} />
          <meta property="og:image" content={config.baseUrl + shareImg} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="homewiserealestate.ca" />
          <meta
            property="twitter:url"
            content="https://homewiserealestate.ca"
          />
          <meta name="twitter:title" content={t(`meta.title`)} />
          <meta name="twitter:description" content={t(`meta.description`)} />
          <meta name="twitter:image" content={config.baseUrl + shareImg} />
        </Helmet>
        <section className={'section getStarted'}>
          {/* Lead Capture */}
          <Container id="getStarted" style={{ paddingBottom: `10rem` }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Paper className={'formCard'}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction={{ xs: `column-reverse`, md: `row` }}
                  >
                    <Grid item xs={12} sm={7}>
                      <LeadCapture
                        title={'Contact us today!'}
                        subtitle={
                          'Enter your info to connect with a Homewise Realtor'
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      sx={{ textAlign: `left`, padding: `2rem 0` }}
                    >
                      <h1 className={'boostSize'}>Homewise Real Estate</h1>
                      <Button
                        startIcon={<EmailIcon sx={{ color: `#054D85` }} />}
                        href={t('common:footer.contact.items.1.url')}
                        sx={{
                          padding: `2px 5px !important`,
                          margin: `0px !important`,
                          color: `#007BFF !important`,
                          textTransform: `none !important`,
                          fontWeight: `400 !important`,
                        }}
                      >
                        {t('common:footer.contact.items.1.title')}
                      </Button>
                      <br />
                      <Button
                        startIcon={<LocalPhoneIcon sx={{ color: `#054D85` }} />}
                        href={t('common:footer.contact.items.2.url')}
                        sx={{
                          padding: `2px 5px !important`,
                          margin: `0px !important`,
                          color: `#007BFF !important`,
                          textTransform: `none !important`,
                          fontWeight: `400 !important`,
                        }}
                      >
                        {t('common:footer.contact.items.2.title')}
                      </Button>
                      <br />
                      <Button
                        startIcon={<PlaceIcon sx={{ color: `#054D85` }} />}
                        href={t('common:footer.contact.items.3.url')}
                        sx={{
                          padding: `2px 5px !important`,
                          margin: `0px !important`,
                          color: `#007BFF !important`,
                          textTransform: `none !important`,
                          fontWeight: `400 !important`,
                        }}
                      >
                        {t('common:footer.contact.items.3.title')}
                      </Button>
                      <h2 className={'rating'}>
                        4.9 &nbsp;&nbsp;
                        <span style={{ color: `#FF9800` }}>
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </span>
                        &nbsp;&nbsp;
                        <img src={google} width="90" alt="google reviews" />
                      </h2>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>

          {/* Reviews Carousel */}
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} data-aos="fade-up" data-aos-offset={300}>
                <h2 style={{ textAlign: `center` }}>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    HOME BUYERS LIKE YOU
                  </span>
                  <br />
                  <span className="highlight">Love Homewise</span>
                </h2>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                data-aos="fade-in"
                data-aos-offset={300}
              >
                <ReviewCarousel reviews={reviews} />
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                data-aos="fade-in"
                data-aos-offset={300}
              >
                <ImgWithFallback
                  src={cuteCouple_webp}
                  fallback={cuteCouple}
                  width="300"
                  alt="Happy Homewise Homeowners"
                  className={'fullWidth rounded'}
                />
              </Grid>
            </Grid>
          </Container>
        </section>

        {/* Final Mortgage callout */}
        <section className={'section finalCta'}>
          <Container>
            <Grid container justifyContent="center" alignItems="center">
              <Hidden mdDown>
                <Grid item xs={12} sm={2}>
                  <ImgWithFallback
                    src={keys_webp}
                    fallback={keys}
                    width="200"
                    alt={t('preapp.alt')}
                    style={{ marginBottom: `-10px` }}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={6} md={3}>
                <h2>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    {t('preapp.title1')}
                  </span>
                  <br />
                  <span className="highlight">{t('preapp.title2')}</span>
                </h2>
                <p>{t('preapp.copy')}</p>
                <Button
                  component="a"
                  href="https://my.thinkhomewise.com"
                  color="secondary"
                  variant="contained"
                  className="hw_btn_secondary"
                  sx={{ marginLeft: `0 !important` }}
                >
                  {t('preapp.cta')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default withTranslation('contact')(Contact)
