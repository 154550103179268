import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history'
import AuthProvider from './store/auth/authProvider'
import { UserProvider } from './store/app/Provider'
import { StateProvider } from './store/app/Store'
import App from './App'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Offline as OfflineIntegration } from '@sentry/integrations'
import SentryRRWeb from '@sentry/rrweb'
import { version } from '../package.json'

import './index.scss'

const history = createBrowserHistory()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_STAGE,
  release: version,
  integrations: [
    new Integrations.BrowserTracing(),
    new OfflineIntegration({
      maxStoredEvents: 100,
    }),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new SentryRRWeb(),
  ],
  normalizeDepth: 10,
  tracesSampleRate: 1,
})

createRoot(document.getElementById('root')).render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <React.StrictMode>
      <AuthProvider>
        <UserProvider>
          <StateProvider>
            <App />
          </StateProvider>
        </UserProvider>
      </AuthProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
)

// Check if the service worker is supported by the browser
if ('serviceWorker' in navigator) {
  // Un-register the service worker
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })

  // Register the service worker
  navigator.serviceWorker
    .register('/real-estate/sw.js', { scope: 'real-estate/' })
    .then((registration) => {
      console.log('Service worker registered.')
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error)
    })
}
