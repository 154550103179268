/**
 * @returns {Object} Coordinates object: {lat, lng}
 * @exception {string} Translation link
 */
const getUserCoordinates = () => {
  return new Promise(function (resolve, reject) {
    const onSuccess = (position) => {
      const { latitude: lat, longitude: lng } = position.coords;
      resolve({ lat, lng });
    };

    const onError = (error) => {
      let translationLink = "navigator.geoLocation.error.";
      switch (error.code) {
        case error.PERMISSION_DENIED:
          translationLink += "permissionDenied";
          break;
        case error.POSITION_UNAVAILABLE:
          translationLink += "positionUnavailable";
          break;
        case error.TIMEOUT:
          translationLink += "timeout";
          break;
        default:
          translationLink += "default";
          break;
      }

      reject(translationLink);
    };
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  });
};

export default getUserCoordinates;
