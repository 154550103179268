import React, { useState } from 'react'
import parse from 'parse-link-header'
import jwt_decode from 'jwt-decode'

import AuthContext from './authContext'
import Config from '../../config'
import customerApi from '../../api/customer'

import { useTranslation } from 'react-i18next'
import { setSessionInactive, setSessionActive } from 'utils/session'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const poolData = {
  UserPoolId: Config.cognito.USER_POOL_ID,
  ClientId: Config.cognito.APP_CLIENT_ID,
  Storage: new AmazonCognitoIdentity.CookieStorage({
    // wildcard domain for cross-subdomain cookies
    domain: Config.cookieDomain,
    secure: process.env.NODE_ENV === 'production',
  }),
}
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

function AuthProvider({ children }) {
  const [schema, setSchema] = useState({})
  const [user, setUser] = useState(null)
  // const [errors, setErrors] = useState({})
  // const [loading, setLoading] = useState(false)
  const [t] = useTranslation()

  /**
   * Returns the session from the cognito user pool.
   * Sets the session state with auth info.
   * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
   * @returns {null} null
   */
  const getSession = async (from) => {
    try {
      let cognitoUser = userPool.getCurrentUser()

      if (cognitoUser === null)
        throw new Error(
          'Unable to retrieve user info (getSession)), cognitoUser is null.'
        )

      await cognitoUser.getSession((err, session) => {
        if (err) throw new Error(err)

        setUser({
          ...jwt_decode(session.idToken.jwtToken),
          activeSession: true,
          jwtToken: session.idToken.jwtToken,
        })

        setSessionActive()

        return true
      })
    } catch (e) {
      console.log('Unable to retrieve user info (getSession).')
      setUser({
        activeSession: false,
      })

      setSessionInactive()

      return false
    }
  }

  /**
   * Refreshes the user session.
   * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
   * @returns {null} null
   */
  const getRefreshedSession = async (from) => {
    try {
      let cognitoUser = userPool.getCurrentUser()

      if (cognitoUser === null) {
        if (from === 'app') return
        else
          throw new Error(
            'Unable to retrieve user info (getRefreshedSession)), cognitoUser is null.'
          )
      }

      await cognitoUser.getSession((err, session) => {
        if (err) throw new Error(err)

        let refresh_token = session.getRefreshToken()

        cognitoUser.refreshSession(refresh_token, (e, refreshedSession) => {
          if (e) throw new Error(e)

          setUser({
            ...jwt_decode(refreshedSession.idToken.jwtToken),
            activeSession: true,
            jwtToken: refreshedSession.idToken.jwtToken,
          })

          setSessionActive()
        })

        return true
      })
    } catch (e) {
      console.log('Unable to refresh session.')
      setUser({
        activeSession: false,
      })

      setSessionInactive()

      return false
    }
  }

  /**
   * Ends the user session with the cognito user pool.
   * @returns {null} null
   */
  const logout = async (callback) => {
    try {
      let cognitoUser = userPool.getCurrentUser()

      if (cognitoUser === null)
        throw new Error(
          'Unable to retrieve user info (endSession)), cognitoUser is null.'
        )

      cognitoUser.signOut()

      setUser({
        activeSession: false,
      })

      try {
        // Clear all data from localStorage & sessionStorage.
        sessionStorage.clear()
        localStorage.clear()
        setSessionInactive()
      } catch (e) {
        console.log('LocalStorage/SessionStorage Error')
      }

      setTimeout(callback, 100)
    } catch (e) {
      console.log('endSession error: ', e)
    }
  }

  const login = async (user, setErrors, setLoading, callback) => {
    try {
      const { email, password } = user
      let authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: email,
          Password: password,
        })

      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          setUser({
            ...jwt_decode(result.idToken.jwtToken),
            activeSession: true,
            jwtToken: result.idToken.jwtToken,
          })

          // console.log('jwt token', result.idToken.jwtToken)

          setSessionActive()

          await callback()
          setLoading(false)

          return true
        },

        onFailure: async (err) => {
          const authError = err.message
          setLoading(false)
          console.log(err, err.message)

          switch (err.code) {
            case 'UserNotConfirmedException':
              setErrors((errors) => ({
                ...errors,
                email: t('errors:' + err.code),
              }))
              break
            case 'UserNotFoundException':
              setErrors((errors) => ({
                ...errors,
                email: t('errors:' + err.code),
              }))
              break
            case 'NotAuthorizedException':
              setErrors((errors) => ({
                ...errors,
                email: t('errors:' + err.code),
                password: '',
              }))
              break
            case 'ResourceNotFoundException':
              setErrors((errors) => ({
                ...errors,
                masterError: t('errors:' + err.code),
                masterInvalid: true,
              }))
              break
            case 'PasswordResetRequiredException':
              // callback()
              // redirect()
              break
            case 'LimitExceededException':
              setErrors((errors) => ({
                ...errors,
                masterError: t('errors:' + err.code),
                masterInvalid: true,
              }))
              break
            default:
              setErrors((errors) => ({
                ...errors,
                masterError: authError,
                masterInvalid: true,
              }))
              break
          }

          setErrors((errors) => ({
            ...errors,
            masterError: authError,
            masterInvalid: true,
          }))
          setLoading(false)

          return
        },

        newPasswordRequired: (authenticationDetails) => {
          delete authenticationDetails.email_verified
          delete authenticationDetails.phone_number_verified

          cognitoUser.completeNewPasswordChallenge(
            password,
            authenticationDetails
          )
        },
      })
    } catch (e) {
      console.log('authenticate error: ', e)
      return
    }
  }

  const signUp = (
    email,
    firstName,
    lastName,
    password,
    setLoading,
    setErrors,
    errors,
    callback
  ) => {
    setLoading(true)
    setErrors({})
    let attributeList = [
      {
        Name: 'locale',
        Value: 'en',
      },
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: 'custom:group',
        Value: 'real_estate',
      },
      {
        Name: 'given_name',
        Value: firstName,
      },
      {
        Name: 'family_name',
        Value: lastName,
      },
      {
        Name: 'custom:attr2',
        Value: 'customer',
      },
    ]

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        console.log(err, err.message)
        setLoading(false)

        if (err.code === 'UsernameExistsException') {
          setErrors((errors) => ({
            ...errors,
            email: t('errors:' + err.code),
          }))
          return
        }

        if (err.code === 'InvalidPasswordException') {
          setErrors((errors) => ({
            ...errors,
            password: t('errors:' + err.code),
          }))
          return
        }

        if (err.code === 'ResourceNotFoundException') {
          errors.masterError = t('errors:' + err.code)
          errors.masterInvalid = true
          setErrors({ ...errors })
          return
        }

        if (err.code === 'InvalidParameterException') {
          errors.masterError = t('errors:' + err.code)
          errors.masterInvalid = true
          setErrors({ ...errors })
          return
        }

        setErrors({ ...errors })
        setLoading(false)
        return
      } else {
        if (!result.userConfirmed) {
          console.log('User not confirmed.')
        } else {
          // await login({ email, password }, callback())
          console.log('User confirmed. Fire authentification.')
          authenticate(email, password, setErrors, null, callback)
        }
      }
    })
  }

  /**
   * Verifies Cognito account status code entered by user.
   * @param {String} email
   * @param {String} userCode
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  const verifyCode = (
    email,
    userCode,
    setErrors,
    setButtonLoading,
    callback
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })

      cognitoUser.confirmRegistration(userCode, true, (err, result) => {
        if (err) {
          const errors = {
            userCodeError: '',
            userCodeInvalid: false,
          }

          if (err.code === 'CodeMismatchException') {
            errors.userCodeError = err.message
            errors.userCodeInvalid = true
            setErrors({ ...errors })
          }
          if (err.code === 'NotAuthorizedException') {
            errors.userCodeError = err.message
            errors.userCodeInvalid = true
            setErrors({ ...errors })
          }
          setButtonLoading(false)
          return
        } else {
          callback()
          return
        }
      })
    } catch (e) {
      console.log('verifyCode error: ', e)
      return
    }
  }

  /**
   * Resets Cognito account password.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @param {Function} callbackOnFail
   */
  const resetPassword = async (
    email,
    setErrors,
    setButtonLoading,
    callback,
    callbackOnFail
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })

      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          callback()
          setButtonLoading(false)
        },
        onFailure: function (err) {
          if (err.code === 'LimitExceededException') {
            setErrors((errors) => ({
              ...errors,
              email: t('errors:' + err.code),
            }))
          } else if (
            err.code === 'CodeMismatchException' ||
            err.code === 'ExpiredCodeException'
          ) {
            setErrors((errors) => ({
              ...errors,
              email: t('errors:' + err.code),
            }))
          } else {
            setErrors((errors) => ({
              ...errors,
              email: t('errors:' + err.code),
            }))
          }
          setButtonLoading(false)
          callbackOnFail()
        },
      })
    } catch (e) {
      console.log('resetPassword error: ', e)
      return
    }
  }

  /**
   * Confirms Cognito account password.
   * @param {String} email
   * @param {String} verificationCode
   * @param {String} newPassword
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  const confirmPassword = async (
    email,
    verificationCode,
    newPassword,
    setErrors,
    setButtonLoading,
    callback
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })
      return new Promise(() => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onFailure(err) {
            console.log(err)
            if (err.code === 'LimitExceededException') {
              setErrors((errors) => ({
                ...errors,
                code: {
                  invalid: true,
                  error: t('errors:code.' + err.code),
                },
              }))
            }
            if (
              err.code === 'InvalidPasswordException' ||
              err.code === 'UserNotFoundException'
            ) {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: t('errors:password.pattern'),
                },
              }))
            }
            if (
              err.code === 'CodeMismatchException' ||
              err.code === 'ExpiredCodeException'
            ) {
              setErrors((errors) => ({
                ...errors,
                code: {
                  invalid: true,
                  error: t('errors:code.' + err.code),
                },
              }))
            }
            if (err.code === 'InvalidParameterException') {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: t('errors:password.pattern'),
                },
                code: {
                  invalid: true,
                  error: t('errors:code.pattern'),
                },
              }))
            }
            // if (err.code === 'InvalidLambdaResponseException') {
            //   console.log(err);
            // }
            setButtonLoading(false)
          },
          onSuccess: async () => {
            authenticate(
              email,
              newPassword,
              setErrors,
              setButtonLoading,
              callback
            )
            // await login({ email, newPassword })
            return
          },
        })
      })
    } catch (e) {
      console.log('confirmPassword error: ', e)
      return
    }
  }

  /**
   * Resends Cognito account verification code.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns
   */
  const resendCode = async (email, setErrors, setButtonLoading, callback) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          const authError = err.message
          setButtonLoading(false)
          if (err.code === 'UserNotFoundException') {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: t('errors:' + err.code),
              },
            }))
          } else {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: t('errors:' + authError),
              },
            }))
          }
          return
        }
        callback()
      })
    } catch (e) {
      console.log('resendCode error: ', e)
      return
    }
  }

  const getSchema = async () => {
    try {
      let isSchemaEmpty = Object.keys(schema).length <= 0

      if (isSchemaEmpty) {
        const res = await customerApi.getSchema()

        if (res.status <= 202) {
          const header = res.headers['link'],
            parsed = parse(header),
            urlsObject = Object.entries(parsed)

          let schema = {}
          urlsObject.forEach(([key, value]) => {
            schema[key] = value.url
          })

          setSchema(schema)
          return schema
        } else if (res.status >= 400) {
          throw new Error(res.statusText)
        }
      } else {
        console.log('Schema already exists.', schema)
        return schema
      }
    } catch (e) {
      console.log('getSchema error: ', e)
      return schema
    }
  }

  /**
   * Authenticate the user with the cognito user pool.
   * @param email - The user's email.
   * @param password - The user's password.
   * @param setErrors - A callback function to set the errors in the component state.
   * @param setButtonLoading - A callback function to set the button loading state in the component state.
   * @param callBack - A callback function to be called after the user is authenticated.
   * @param redirect - A callback function to redirect a user after the authentication.
   * @returns {null} null
   */
  const authenticate = async (
    email,
    password,
    setErrors,
    setLoading,
    callback,
    redirect
  ) => {
    try {
      let authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: email,
          Password: password,
        })

      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
        Storage: new AmazonCognitoIdentity.CookieStorage({
          // wildcard domain for cross-subdomain cookies
          domain: Config.cookieDomain,
          secure: process.env.NODE_ENV === 'production',
        }),
      })

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          setUser({
            ...jwt_decode(result.idToken.jwtToken),
            activeSession: true,
            jwtToken: result.idToken.jwtToken,
          })

          // console.log('jwt token', result.idToken.jwtToken)

          setSessionActive()

          await callback()
          setLoading(false)

          return true
        },

        onFailure: (err) => {
          const authError = err.message
          // setButtonLoading(false)

          console.log('authenticateUser error: ', err)

          switch (err.code) {
            case 'UserNotConfirmedException':
              setErrors((errors) => ({
                ...errors,
                email: {
                  invalid: true,
                  error: t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                },
              }))
              break
            case 'UserNotFoundException':
              setErrors((errors) => ({
                ...errors,
                email: {
                  invalid: true,
                  error: t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                },
              }))
              break
            case 'NotAuthorizedException':
              setErrors((errors) => ({
                ...errors,
                email: {
                  invalid: true,
                  error: t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                },
                password: {
                  invalid: true,
                  error: '',
                },
              }))
              break
            case 'ResourceNotFoundException':
              setErrors((errors) => ({
                ...errors,
                masterError: t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
                masterInvalid: true,
              }))
              break
            case 'PasswordResetRequiredException':
              callback()
              redirect()
              break
            case 'LimitExceededException':
              setErrors((errors) => ({
                ...errors,
                masterError: t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
                masterInvalid: true,
              }))
              break
            default:
              setErrors((errors) => ({
                ...errors,
                masterError: authError,
                masterInvalid: true,
              }))
              break
          }

          setErrors((errors) => ({
            ...errors,
            masterError: authError,
            masterInvalid: true,
          }))

          return
        },

        newPasswordRequired: (authenticationDetails) => {
          delete authenticationDetails.email_verified
          delete authenticationDetails.phone_number_verified

          cognitoUser.completeNewPasswordChallenge(
            password,
            authenticationDetails,
            this
          )
        },
      })
    } catch (e) {
      console.log('authenticate error: ', e)
      return
    }
  }

  return (
    <AuthContext.Provider
      value={{
        getSchema,
        user,
        login,
        logout,
        signUp,
        getSession,
        getRefreshedSession,
        verifyCode,
        resetPassword,
        confirmPassword,
        resendCode,
        authenticate,
        // errors,
        // loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
