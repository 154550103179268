import * as React from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Hidden from '@mui/material/Hidden'

import { store } from '../../../../store/app/Store'
import { homeTypeOptions } from 'utils/constants'

export default function OtherFilters() {
  const globalState = React.useContext(store)
  const { state, dispatch } = globalState

  const [beds, setBeds] = React.useState(
    state.searchParams.minBeds === 0 ? 0 : state.searchParams.minBeds || ''
  )
  const [baths, setBaths] = React.useState(
    state.searchParams.minBaths === 0 ? 0 : state.searchParams.minBaths || ''
  )
  const [squareFeet, setSquareFeet] = React.useState(
    state.searchParams.minSqft === 0 ? 0 : state.searchParams.minSqft || ''
  )

  // const [photos, setPhotos] = React.useState(
  //   state.searchParams.hasImages === true ? '1' : ''
  // )
  // const [openHouse, setOpenHouse] = React.useState(false)
  const [keywords, setKeywords] = React.useState(
    state.searchParams.search || ''
  )
  // const [isTypingKeywords, setIsTypingKeywords] = React.useState(false)

  const handleChangeBeds = (event) => {
    setBeds(event.target.value)
    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        minBeds: event.target.value,
      },
    })
  }

  const handleChangeBaths = (event) => {
    setBaths(event.target.value)
    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        minBaths: event.target.value,
      },
    })
  }

  const handleChangeSquareFeet = (event) => {
    setSquareFeet(event.target.value)
    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        minSqft: Number(event.target.value) <= 0 ? 0 : event.target.value,
      },
    })
  }

  // const handleChangePhotos = (event) => {
  //   setPhotos(event.target.value)
  //   if (event.target.value === '1') {
  //     dispatch({
  //       type: 'searchParams',
  //       newVal: {
  //         ...state.searchParams,
  //         hasImages: true,
  //       },
  //     })
  //   }

  //   // else remove hasImages from searchParams
  //   else {
  //     dispatch({
  //       type: 'searchParams',
  //       newVal: {
  //         ...state.searchParams,
  //         hasImages: null,
  //       },
  //     })
  //   }
  // }

  // const handleChangeOpenHouse = (event) => {
  //   setOpenHouse(event.target.checked)
  // }

  const handleChangeKeywords = (event) => {
    // setIsTypingKeywords(true)
    setKeywords(event.target.value)
    // wait 1 second before dispatching
    setTimeout(() => {
      dispatch({
        type: 'searchParams',
        newVal: {
          ...state.searchParams,
          search: event.target.value,
        },
      })
      // setIsTypingKeywords(false)
    }, 1000)
  }

  // We use propertyType to determine which homeTypeOptions are selected because those values are guranteed to be unique.
  const [homeTypeSelections, setHomeTypeSelections] = React.useState(
    Array.isArray(state.searchParams?.propertyType)
      ? // find the index of all the homeTypeOption whose homeTypeOption.value.propertyType[0] is mentioned in state.searchParams.propertyType
        homeTypeOptions
          .map((option, index) => {
            if (
              state.searchParams.propertyType.includes(
                option.value?.propertyType[0]
              )
            )
              return index
            return null
          })
          .filter((index) => index !== null)
      : [0]
  )

  const handleClickType = (event) => {
    const {
      target: { value },
    } = event

    const selection = typeof value === 'string' ? value.split(',') : value
    let index = Number(selection[selection.length - 1])

    if (isNaN(index)) index = 0

    let freshSelections = selection

    // if any homeTypeSelections include values not in selection, remove them
    freshSelections = freshSelections.filter((index) =>
      selection.includes(index)
    )

    // if index is 0, set homeTypeSelections to [0] else remove 0 from homeTypeSelections
    if (index === 0) freshSelections = [0]
    else freshSelections = freshSelections.filter((type) => type !== 0)

    freshSelections = [...new Set(freshSelections)]

    setHomeTypeSelections(freshSelections)
  }

  React.useEffect(() => {
    // construct searchParams
    let searchParams = {
      ...state.searchParams,
      class: [],
      style: [],
      propertyType: [],
    }

    // for all selected home types, add the respective homeTypeOptions[index].value to searchParams
    homeTypeSelections.forEach((index) => {
      // if index is 0, remove the class, style, and propertyType from searchParams
      if (index === 0) {
        searchParams = {
          ...searchParams,
          class: ['residential', 'condo'],
          style: [],
          propertyType: [],
        }
        return
      }

      const additionalSearchParams = homeTypeOptions[index]?.value

      // if class is an array, add each item to searchParams.class
      if (Array.isArray(additionalSearchParams?.class)) {
        // if the class is already in searchParams.class, skip it
        additionalSearchParams.class.forEach((classItem) => {
          if (!searchParams.class.includes(classItem))
            searchParams.class.push(classItem)
        })
      } else {
        // if the class is already in searchParams.class, skip it
        if (!searchParams.class.includes(additionalSearchParams?.class))
          searchParams.class.push(additionalSearchParams?.class)
      }

      // if style is an array, add each item to searchParams.style
      if (Array.isArray(additionalSearchParams?.style))
        searchParams.style = [
          ...searchParams.style,
          ...additionalSearchParams?.style,
        ]

      // if propertyType is an array, add each item to searchParams.propertyType
      if (Array.isArray(additionalSearchParams?.propertyType))
        searchParams.propertyType = [
          ...searchParams.propertyType,
          ...additionalSearchParams?.propertyType,
        ]
    })

    // make sure searchParams.class, searchParams.style, searchParams.propertyType is unique
    searchParams.class = [...new Set(searchParams.class)]
    searchParams.style = [...new Set(searchParams.style)]
    searchParams.propertyType = [...new Set(searchParams.propertyType)]

    dispatch({
      type: 'searchParams',
      newVal: searchParams,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeTypeSelections, dispatch])

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Hidden mdUp>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="baths-small">Home Type</InputLabel>
              <FormGroup>
                <Select
                  labelId="hometype-small"
                  id="hometype-small"
                  multiple
                  // value is the labels of all the selected homeTypeOptions except for index 0, if no homeTypeOptions are selected, value is ''
                  value={homeTypeSelections}
                  onChange={handleClickType}
                  label="Home Type"
                  renderValue={(selected) =>
                    selected
                      .map((index) => homeTypeOptions[index]?.label)
                      ?.join(', ')
                  }
                  sx={{ backgroundColor: 'white', width: `100%` }}
                >
                  {homeTypeOptions.map((type, index) => (
                    <MenuItem key={index} value={index}>
                      <Checkbox
                        checked={homeTypeSelections?.includes(index) || false}
                      />
                      <ListItemText primary={type.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
            </FormControl>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="beds-small">Beds</InputLabel>
              <Select
                labelId="beds-small"
                id="beds-small"
                value={beds}
                label="Beds"
                sx={{ backgroundColor: 'white', width: `100%` }}
                onChange={handleChangeBeds}
              >
                <MenuItem value={0}>
                  <em>Any</em>
                </MenuItem>
                <MenuItem value={1}>1+</MenuItem>
                <MenuItem value={2}>2+</MenuItem>
                <MenuItem value={3}>3+</MenuItem>
                <MenuItem value={4}>4+</MenuItem>
                <MenuItem value={5}>5+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel id="baths-small">Baths</InputLabel>
            <Select
              labelId="baths-small"
              id="baths-small"
              value={baths}
              label="Baths"
              sx={{ backgroundColor: 'white', width: `100%` }}
              onChange={handleChangeBaths}
            >
              <MenuItem value={0}>
                <em>Any</em>
              </MenuItem>
              <MenuItem value={1}>1+</MenuItem>
              <MenuItem value={2}>2+</MenuItem>
              <MenuItem value={3}>3+</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
              <MenuItem value={5}>5+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel id="sqft-small">Square Feet</InputLabel>
            <Select
              labelId="sqft-small"
              id="sqft-small"
              value={squareFeet}
              label="Square Feet"
              sx={{ backgroundColor: 'white', width: `100%` }}
              onChange={handleChangeSquareFeet}
            >
              <MenuItem value="0">Any</MenuItem>
              <MenuItem value="500">500+ sq ft</MenuItem>
              <MenuItem value="750">750+ sq ft</MenuItem>
              <MenuItem value="1000">1,000+ sq ft</MenuItem>
              <MenuItem value="1250">1,250+ sq ft</MenuItem>
              <MenuItem value="1500">1,500+ sq ft</MenuItem>
              <MenuItem value="1750">1,750+ sq ft</MenuItem>
              <MenuItem value="2000">2,000+ sq ft</MenuItem>
              <MenuItem value="2250">2,250+ sq ft</MenuItem>
              <MenuItem value="2500">2,500+ sq ft</MenuItem>
              <MenuItem value="2750">2,750+ sq ft</MenuItem>
              <MenuItem value="3000">3,000+ sq ft</MenuItem>
              <MenuItem value="3250">3,250+ sq ft</MenuItem>
              <MenuItem value="3500">3,500+ sq ft</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel id="photos-small">Has Photos?</InputLabel>
            <Select
              labelId="photos-small"
              id="photos-small"
              value={photos}
              label="Photos"
              sx={{ backgroundColor: 'white', width: `100%` }}
              onChange={handleChangePhotos}
            >
              <MenuItem value="1">True</MenuItem>
              <MenuItem value="0">False</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        {/* Temporarily disabling this until we figure out the filtering mechanism */}
        {/* <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <FormControlLabel
              value={openHouse}
              control={<Checkbox />}
              label="Open House"
              labelPlacement="end"
              onChange={handleChangeOpenHouse}
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            label="Keywords"
            id="outlined-size-small"
            defaultValue={keywords}
            size="small"
            placeholder="Eg. Pool, Garage, etc."
            InputLabelProps={{ shrink: true }}
            onChange={handleChangeKeywords}
            sx={{ backgroundColor: 'white', width: `100%` }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
