/*global google*/
import config from '../../config.js'
import marker_cluster from '../../assets/map_markers/marker_cluster.svg'
import millify from 'millify'

export const loaderOptions = {
  apiKey: config.google.MAPS,
  version: 'weekly',
  libraries: ['places'], // "places" required for places autocomplete
}

/**
 * Google maps theme
 */
export const mapStylesArray = [
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

/**
 * Number to human readable formats
 * @param {*} num
 * @returns {String} human readable number
 * @see https://stackoverflow.com/a/17633552
 */
export const humanReadableCurrency = (n) => {
  if (!n) return '0'

  return millify(n, {
    precision: 2,
    decimalSeparator: '.',
    units: ['', 'K', 'M', 'B', 'T'],
    space: false,
  })
  // return '$' + `${n.toString()}`
}

/**
 * Cluster marker renderer
 * @param {*} cluster
 * @param {*} styles
 * @returns {Object} cluster marker
 * @see https://developers.google.com/maps/documentation/javascript/marker-clustering#styling_the_clusters
 */
export const clustererRenderer = (marker, stats) => {
  const { count, position } = marker
  // create marker using svg icon
  return new google.maps.Marker({
    position,
    icon: {
      url: marker_cluster,
      labelOrigin: new google.maps.Point(17, 18),
      // scaledSize: new google.maps.Size(75, 75),
    },
    label: {
      text: count.toString(),
      color: '#fff',
      fontSize: '12px',
      // anchorText: [0, 22],
    },
    // adjust zIndex to be above other markers
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    optimized: true,
  })
}

/**
 * DO NOT USE - DEPRECATED;
 * Calculates coordinates of all four corners of the map based on bounds
 * @param {Object} map - a valid google maps object
 * @returns {String} - coordinates of all four corners of the map in the format accepted by repliers.io
 */
export const getParsedMapBounds = (map) => {
  if (!map) return console.error('Map is required!!!')

  const bounds = map.getBounds()

  const southWest = bounds.getSouthWest()
  const northEast = bounds.getNorthEast()

  return `[[[${southWest.lng}, ${southWest.lat}],[${northEast.lng}, ${southWest.lat}],[${northEast.lng}, ${northEast.lat}],[${southWest.lng}, ${northEast.lat}],[${southWest.lng}, ${southWest.lat}]]]`
}

/**
 * Will return request data for API call to fetch map pins
 * @param {Object} map - A leaflet map object
 * @param {Object} requestData - Can be used to pass request data.
 * @returns {Object} requestData
 */
// @genzdev TODO: If search filter is active, bounds can be made optional.
export const getMapPinsRequestData = (map, requestData) => {
  if (!map) return console.error('Map is required!!!')

  const apiRequestData = requestData

  const bounds = map.getBounds()

  const southWest = bounds.getSouthWest()
  const northEast = bounds.getNorthEast()

  /** This format is specific to repliers.io */
  const boundary = `[[[${southWest.lng}, ${southWest.lat}],[${northEast.lng}, ${southWest.lat}],[${northEast.lng}, ${northEast.lat}],[${southWest.lng}, ${northEast.lat}],[${southWest.lng}, ${southWest.lat}]]]`

  if (apiRequestData) {
    return {
      ...apiRequestData,
      map: boundary,
    }
  }

  return {
    map: boundary,
  }
}
