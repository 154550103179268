import * as React from 'react'
import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'

import InterestForm from './InterestForm'

export default function FormDialog(props) {
  const {
    cta,
    color,
    variant,
    className,
    sx,
    endIcon,
    size,
    listing,
    mlsId,
    boardId,
    headshot,
    position,
  } = props
  const [open, setOpen] = React.useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={variant ? variant : 'contained'}
        color={color ? color : 'primary'}
        className={className ? className : 'primaryBtn'}
        sx={sx ? sx : { margin: 0 }}
        endIcon={endIcon ? endIcon : null}
        onClick={handleClickOpen}
        size={size ? size : 'small'}
        id={`interestLink_${position}`}
      >
        <strong>{cta}</strong>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'xs'}
        fullScreen={isMobile}
      >
        <DialogContent>
          {isMobile && (
            <Button
              component="button"
              color="primary"
              variant="contained"
              className="primaryBtn"
              onClick={handleClose}
              sx={{ display: `flex`, marginLeft: `auto`, marginBottom: `1rem` }}
            >
              <CloseIcon />
            </Button>
          )}
          <InterestForm
            listing={listing}
            mlsId={mlsId}
            boardId={boardId}
            headshot={headshot}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
