import React, { useRef, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import Slider from 'react-slick'
import IconButton from '@mui/material/IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import StarRatings from 'react-star-ratings'

import google from 'assets/pages/google-1.png'

const SamplePrevArrow = (props) => {
  const { styles, onClick } = props
  return (
    <IconButton className={styles} onClick={onClick} aria-label="previous">
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `-.25rem`,
        }}
      />
    </IconButton>
  )
}

const SampleNextArrow = (props) => {
  const { styles, onClick } = props
  return (
    <IconButton className={styles} onClick={onClick} aria-label="next">
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `.25rem`,
        }}
      />
    </IconButton>
  )
}

export const ReviewCarouselMini = ({ reviews }) => {
  const [height, setHeight] = useState(0)

  let reviewsSlidingSettings = {
    dots: false,
    dotsClass: 'hwDots',
    // centerMode: false,
    infinite: true,
    fade: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow styles="reviewNext" />,
    prevArrow: <SamplePrevArrow styles="reviewPrev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const firstReview = useRef(null)

  useEffect(() => {
    let reviewHeight = firstReview.current.clientHeight
    setHeight(reviewHeight)
  }, [])

  return (
    <>
      <Hidden smDown>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: `flex`,
          }}
        >
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              width: `100%`,
              height: height,
              padding: `1rem`,
              background: `#fff`,
              borderRadius: `8px`,
              marginTop: `-4px`,
              boxShadow: `0px 1px 0px #EBEBEB, 0px 8px 8px rgba(0, 0, 0, 0.03)`,
            }}
          >
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-start`,
              }}
            >
              <img
                src={google}
                width={160}
                alt=""
                style={{
                  margin: `0 0 10px 0`,
                }}
              />
              <StarRatings
                rating={5}
                starRatedColor="#e5722b"
                starEmptyColor="#F5B58D"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                name="Google Reviews"
              />
              <p
                style={{
                  color: `#054d85`,
                  margin: `10px 0 0`,
                  fontWeight: `500`,
                  fontSize: `16px`,
                }}
              >
                An average of <b>4.9</b> stars
                <br />
                out of 400+ reviews
              </p>
            </div>
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-start`,
                flex: `1`,
                justifyContent: `flex-end`,
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/search?hl=en-CA&gl=ca&q=Homewise,+383+Spadina+Rd+unit+b,+Toronto,+ON+M5P+2W1&ludocid=8718664546004722507&lsig=AB86z5Wt3pQFdmfwA4Do6octDOms&mat=CQZUY1VqAYZkElYBezTaAdAanbd3GAwnxUkuaLh1ZTM7aV8zTbqX46R_FiWTl0uAi2Gaczp_dN3G_wSri-Y_XzcgAP6jg5wpaeZiFWthBuN-B-2CAgZ9XWeV-OCora6eXA#lrd=0x882b33a2363b2179:0x78feeb360c67634b,1"
                style={{ textDecoration: `none` }}
              >
                <Button
                  variant="contained"
                  color={'primary'}
                  style={{
                    marginTop: `2rem`,
                    borderRadius: `10px`,
                    padding: `6px 12px`,
                    textDecoration: `none`,
                  }}
                >
                  Read More Reviews
                </Button>
              </a>
            </div>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={10} sm={9}>
        <Slider {...reviewsSlidingSettings} className="reviewSlider2">
          {reviews.map((item, index) => (
            <Grid
              key={item.id}
              item
              style={{
                display: `flex`,
              }}
              ref={index === 1 ? firstReview : null}
            >
              <div
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  padding: `1rem`,
                  background: `#fff`,
                  borderRadius: `8px`,
                  boxShadow: `0px 1px 0px #EBEBEB, 0px 8px 8px rgba(0, 0, 0, 0.03)`,
                }}
                className={'review-' + index}
              >
                <div
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                  }}
                >
                  <p
                    style={{
                      color: `#054d85`,
                      marginBottom: `0px`,
                      fontWeight: `500`,
                      fontSize: `16px`,
                    }}
                  >
                    {item.name}
                  </p>
                </div>
                <div
                  style={{
                    color: `#4c5257`,
                    lineHeight: `1.2rem`,
                    fontSize: `12px`,
                    paddingBottom: `2rem`,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
                <div
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-end`,
                    flex: `1`,
                    justifyContent: `flex-end`,
                  }}
                >
                  <StarRatings
                    rating={parseFloat(item.rating.split('_').pop())}
                    starRatedColor="#e5722b"
                    starEmptyColor="#F5B58D"
                    numberOfStars={parseFloat(item.rating.split('_').pop())}
                    starDimension="20px"
                    starSpacing="2px"
                    name="Google Reviews"
                  />
                  <p
                    style={{
                      color: `#147bc9`,
                      margin: `0px`,
                      fontSize: `12px`,
                    }}
                  >
                    <em>{item.platform}</em>
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </Slider>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
            }}
          >
            <img
              src={google}
              width={160}
              alt=""
              style={{
                margin: `0 0 10px 0`,
              }}
            />
            <StarRatings
              rating={5}
              starRatedColor="#e5722b"
              starEmptyColor="#F5B58D"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="2px"
              name="Google Reviews"
            />
            <p
              style={{
                color: `#054d85`,
                margin: `10px 0 0`,
                fontWeight: `500`,
                fontSize: `16px`,
                textAlign: `center`,
              }}
            >
              An average of <b>4.9</b> stars
              <br />
              out of 400+ reviews
            </p>
          </div>
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              flex: `1`,
              justifyContent: `flex-end`,
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/search?hl=en-CA&gl=ca&q=Homewise,+383+Spadina+Rd+unit+b,+Toronto,+ON+M5P+2W1&ludocid=8718664546004722507&lsig=AB86z5Wt3pQFdmfwA4Do6octDOms&mat=CQZUY1VqAYZkElYBezTaAdAanbd3GAwnxUkuaLh1ZTM7aV8zTbqX46R_FiWTl0uAi2Gaczp_dN3G_wSri-Y_XzcgAP6jg5wpaeZiFWthBuN-B-2CAgZ9XWeV-OCora6eXA#lrd=0x882b33a2363b2179:0x78feeb360c67634b,1"
              style={{ textDecoration: `none` }}
            >
              <Button
                variant="contained"
                color={'primary'}
                style={{ marginTop: `2rem`, borderRadius: `10px` }}
              >
                Read More Reviews
              </Button>
            </a>
          </div>
        </Grid>
      </Hidden>
    </>
  )
}
