import { useEffect, useContext } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'

import { store } from 'store/app/Store'

const Notifier = () => {
  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const notifications = state.notifications
  const displayed = state.displayedNotifications
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation('notifications')

  const CancelButton = ({ buttonIndex }) => {
    return (
      <IconButton
        onClick={() => closeSnackbar(buttonIndex)}
        color="inherit"
        size="small"
      >
        <CancelIcon />
      </IconButton>
    )
  }

  const ReloadButton = ({ buttonIndex }) => {
    return (
      <Button
        variant={'text'}
        size="small"
        color={'inherit'}
        onClick={() => window.location.reload()}
        sx={{ margin: 0 }} // this avoids the margin-top: 1rem style override
      >
        Reload
      </Button>
    )
  }

  /**
   * Returns different action buttons component based on buttons prop
   * @param key Snackbar id key
   * @param {string} buttons Name of the action component
   */
  const getButtons = (key, buttons) => {
    if (!buttons) return null

    const allButtons = {
      reload: <ReloadButton buttonIndex={key} />,
      dismiss: <CancelButton buttonIndex={key} />,
    }

    return allButtons[buttons]
  }

  // Core logic for displaying snackbars via store dispatch.
  useEffect(() => {
    notifications?.forEach(
      ({ index, messageTranslationLink, options = {}, buttons }) => {
        // do nothing if snackbar is already displayed
        if (displayed.find((item) => item.index === index)) return

        // add action buttons
        options = {
          ...options,
          action: getButtons(index, buttons),
        }

        // display snackbar using notistack
        enqueueSnackbar(t(messageTranslationLink), {
          key: index,
          ...options,
          onExited: (_, sbKey) => {
            console.log('onExited', sbKey)
            // remove this snackbar from state.notifications
            dispatch({
              type: 'NOTIFICATION',
              newVal: notifications.filter((item) => item.index !== sbKey),
            })
          },
          preventDuplicate: true,
        })

        // keep track of snackbars that we've displayed
        dispatch({
          type: 'DISPLAYED_NOTIFICATION',
          newVal: {
            index,
            messageTranslationLink,
            options,
            buttons,
          },
        })
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  // Detect if app goes offline and display a notification only for offline state
  useEffect(() => {
    const handleOffline = () => {
      enqueueSnackbar(t('notifications:offline'), {
        key: 'offline',
        variant: 'default',
        persist: true,
        action: <CancelButton buttonIndex={'offline'} />,
        preventDuplicate: true,
      })
    }

    const handleOnline = () => {
      closeSnackbar('offline')
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Notifier
