import agents from 'assets/pages/agents-zoom.png'
import agents_webp from 'assets/pages/agents-zoom.webp'

import placeholder from 'assets/pages/agents/placeholder.png'
import josh from 'assets/pages/agents/josh_raxlen.png'
import josh_webp from 'assets/pages/agents/josh_raxlen.webp'
import brett from 'assets/pages/agents/Brett_Stein.png'
import jason from 'assets/pages/agents/Jason_Aronsberg.png'
import jaymie from 'assets/pages/agents/Jaymie_Stein.png'
import maddy from 'assets/pages/agents/Maddy_Sherman.png'
// import austin from 'assets/pages/agents/Austin_Pacht.png';
import cyrus from 'assets/pages/agents/cyrus_montazeri.png'
import foster from 'assets/pages/agents/foster_jackson.png'

import buying from 'assets/pages/buying-thumb-v1.jpg'
import selling from 'assets/pages/selling-thumb-v1.jpg'
import mortgages from 'assets/pages/step3.png'
import buying_webp from 'assets/pages/buying-thumb-v1.webp'
import selling_webp from 'assets/pages/selling-thumb-v1.webp'
import mortgages_webp from 'assets/pages/step3.webp'

import buying1 from 'assets/pages/buying/successful-young-couple-becoming-homeowner.jpg'
import buying1_webp from 'assets/pages/buying/successful-young-couple-becoming-homeowner.webp'
import buying2 from 'assets/pages/buying/thought-catalog.jpg'
import buying2_webp from 'assets/pages/buying/thought-catalog.webp'
import buying3 from 'assets/pages/buying/living-room.jpg'
import buying3_webp from 'assets/pages/buying/living-room.webp'
import buying4 from 'assets/pages/buying/business-discussion.jpg'
import buying4_webp from 'assets/pages/buying/business-discussion.webp'

import kitchen from 'assets/pages/selling/kitchen.png'
import kitchen_webp from 'assets/pages/selling/kitchen.webp'
import selling3 from 'assets/pages/selling/couple-looking-house.jpg'
import selling3_webp from 'assets/pages/selling/couple-looking-house.webp'
import selling5 from 'assets/pages/selling/roof-work.jpg'
import selling5_webp from 'assets/pages/selling/roof-work.webp'

import couple from 'assets/pages/about/client-couple.png'
import couple_webp from 'assets/pages/about/client-couple.webp'
import officeChat from 'assets/pages/about/office-chat.jpg'
import officeChat_webp from 'assets/pages/about/office-chat.webp'

export const colors = ['#F88078', '#007BFF', '#CCEFC4']

export const nav = [
  {
    id: 'nav0',
    label: 'Home Search',
    slug: '/map',
  },
  {
    id: 'nav1',
    label: 'Buying',
    slug: '/buying',
  },
  {
    id: 'nav2',
    label: 'Selling',
    slug: '/selling',
  },
  {
    id: 'nav3',
    label: 'About',
    slug: '/about',
  },
  {
    id: 'nav4',
    label: 'Contact',
    slug: '/contact',
  },
]

export const directions = {
  Horizontal_Text_Photo: 'row',
  Horizontal_Photo_Text: 'row-reverse',
  Vertical_Text_Photo: 'column',
  Vertical_Photo_Text: 'column-reverse',
}

export const imageFades = {
  Horizontal_Text_Photo: 'fade-right',
  Horizontal_Photo_Text: 'fade-left',
  Vertical_Text_Photo: 'fade',
  Vertical_Photo_Text: 'fade',
}

export const copyFades = {
  Horizontal_Text_Photo: 'fade-left',
  Horizontal_Photo_Text: 'fade-right',
  Vertical_Text_Photo: 'fade',
  Vertical_Photo_Text: 'fade',
}

export const sections = [
  {
    id: '1s',
    style: 'Horizontal_Text_Photo',
    title: 'Full-service Agents + [Modern\u00a0technology]',
    body: "<p>We're full-service, local agents who get to know you over coffee and on home tours. We use our online tools to make your experience easier, smarter and faster.</p>",
    buttons: [
      {
        primary: true,
        link: '#getStarted',
        id: '1',
        label: 'Book a call',
      },
    ],
    photos: [
      {
        src: agents_webp,
        fallback: agents,
        alt: 'Local agents seen on a zoom call with a client just like you.',
      },
    ],
  },
]

export const reviews = [
  {
    body: '<p>This was the second time we used Homewise to get our mortgage. I was so impressed with the responsiveness and personable service we received both times. Evan was there for us at every stage. Thank you Evan.</p>',
    id: '62acd4e5sc01e',
    name: 'Laura C',
    order: 0,
    platform: 'Google',
    rating: 'Star_5',
  },
  {
    body: '<p>Had a wonderful experience with Vincent. He is very professional and works diligently. He is upfront and honest and I would recommend him to anyone.</p>',
    id: '62acdfa54ffde27594e',
    name: 'Mohammed S',
    order: 0,
    platform: 'Google',
    rating: 'Star_5',
  },
  {
    body: '<p>Awesome company! They beat my current mortgage offer from my bank and the approval/process was the fastest ever.</p>',
    id: '62acdfa54ffde27594fe5ce',
    name: 'Bobby C',
    order: 0,
    platform: 'Google',
    rating: 'Star_5',
  },
  {
    body: '<p>I bank with a large bank and have for 3 weeks now been dealing with one of their mortgage specialist....in less than one week Homewise has gotten me a pre-approval from the same bank!!! Just amazing at this time! So easy and no stress! Saves me from the uncertainty!</p>',
    id: '62acdfa54ffde2fe5c01e',
    name: 'Shanaz M',
    order: 0,
    platform: 'Google',
    rating: 'Star_5',
  },
  {
    body: '<p>Great service, highly recommend for first time home buyers and anyone in the market for a mortgage.</p>',
    id: '62acd54ffde27594fe5c01e',
    name: 'Isabella S',
    order: 0,
    platform: 'Google',
    rating: 'Star_5',
  },
]

export const realtors = [
  {
    id: 'r1',
    style: 'Horizontal_Photo_Text',
    title: 'Joshua Raxlen',
    body: '<p>Broker of Record</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:josh@homewiserealestate.ca',
        id: '1b',
        label: 'josh@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:6479495674',
        id: '2b',
        label: '(647) 949-5674',
      },
    ],
    photos: [
      {
        src: josh_webp,
        fallback: josh,
        alt: 'Joshua Raxlen',
      },
    ],
  },
  {
    id: 'r2',
    style: 'Horizontal_Photo_Text',
    title: 'Brett Stein',
    body: '<p>Director of Real Estate</p>',
    buttons: [
      // {
      //   primary: true,
      //   link: 'http://my.thinkhomewise.com',
      //   id: '1',
      //   label: 'Book a call',
      // },
      {
        email: true,
        link: 'mailto:brett@homewiserealestate.ca',
        id: '1b',
        label: 'brett@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:647-289-3422',
        id: '2b',
        label: '(647) 289-3422',
      },
    ],
    photos: [
      {
        src: brett,
        alt: 'Brett Stein',
      },
    ],
  },
  {
    id: 'r3',
    style: 'Horizontal_Photo_Text',
    title: 'Jaymie Stein',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:jaymie@homewiserealestate.ca',
        id: '1b',
        label: 'jaymie@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:416-949-5296',
        id: '2b',
        label: '(416) 949-5296',
      },
    ],
    photos: [
      {
        src: jaymie,
        alt: 'Jaymie Stein',
      },
    ],
  },
  {
    id: 'r4',
    style: 'Horizontal_Photo_Text',
    title: 'Maddy Sherman',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:maddy@homewiserealestate.ca',
        id: '1b',
        label: 'maddy@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:416-483-4337',
        id: '2b',
        label: '(416) 483-4337',
      },
    ],
    photos: [
      {
        src: maddy,
        alt: 'Maddy Sherman',
      },
    ],
  },
  {
    id: 'r5',
    style: 'Horizontal_Photo_Text',
    title: 'Jason Aronsberg',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:jason@homewiserealestate.ca',
        id: '1b',
        label: 'jason@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:647-988-7759',
        id: '2b',
        label: '(647) 988-7759',
      },
    ],
    photos: [
      {
        src: jason,
        alt: 'Jason Aronsberg',
      },
    ],
  },
  // {
  //   id: "r6",
  //   style: "Horizontal_Photo_Text",
  //   title: "Austin Pacht",
  //   body: "<p>Real Estate Sales Representative</p>",
  //   buttons: [
  //     {
  //       email: true,
  //       link: "mailto:austin@homewiserealestate.ca",
  //       id: "1b",
  //       label: "austin@homewiserealestate.ca",
  //     },
  //     {
  //       email: false,
  //       link: "tel:647-964-5931",
  //       id: "2b",
  //       label: "(647) 964-5931",
  //     },
  //   ],
  //   photos: [
  //     {
  //       src: austin,
  //       alt: "",
  //     },
  //   ],
  // },
  {
    id: 'r7',
    style: 'Horizontal_Photo_Text',
    title: 'Cyrus Montazeri',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:cyrus@homewiserealestate.ca',
        id: '1b',
        label: 'cyrus@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:905-220-6242',
        id: '2b',
        label: '(905) 220-6242',
      },
    ],
    photos: [
      {
        src: cyrus,
        alt: 'Cyrus Montazeri',
      },
    ],
  },
  {
    id: 'r8',
    style: 'Horizontal_Photo_Text',
    title: 'Foster Jackson',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:foster@homewiserealestate.ca',
        id: '1b',
        label: 'foster@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:905-407-5816',
        id: '2b',
        label: '(905) 407-5816',
      },
    ],
    photos: [
      {
        src: foster,
        alt: 'Foster Jackson',
      },
    ],
  },
  {
    id: 'r9',
    style: 'Horizontal_Photo_Text',
    title: 'Thakshaan Jayaranjan',
    body: '<p>Real Estate Sales Representative</p>',
    buttons: [
      {
        email: true,
        link: 'mailto:thakshaan@homewiserealestate.ca',
        id: '1b',
        label: 'thakshaan@homewiserealestate.ca',
      },
      {
        email: false,
        link: 'tel:416-857-4226',
        id: '2b',
        label: '(416) 857-4226',
      },
    ],
    photos: [
      {
        src: placeholder,
        alt: 'Thakshaan Jayaranjan',
      },
    ],
  },
]

export const cards = [
  {
    id: 'a1',
    title: 'Buying A Home',
    body: '<p>Buying a home can be stressful. Book a meeting with us where we explain our proven process to find you a home!</p>',
    url: './buying',
    cta: 'Learn More About Buying >',
    photos: [
      {
        src: buying_webp,
        fallback: buying,
        alt: 'Buying',
      },
    ],
  },
  {
    id: 'd2',
    title: 'Selling A Home',
    body: '<p>A Homewise Real Estate Agent will work with you to get you the most value for your home.</p>',
    url: './selling',
    cta: 'Learn More About Selling >',
    photos: [
      {
        src: selling_webp,
        fallback: selling,
        alt: 'Selling',
      },
    ],
  },
  {
    id: 'f3',
    title: 'Homewise Mortgages',
    body: '<p>Take our 5 minute questionaire  - Help us make the best recommendations to save you money!</p>',
    url: 'https://my.thinkhomewise.com',
    cta: 'Learn More About Mortgages >',
    photos: [
      {
        src: mortgages_webp,
        fallback: mortgages,
        alt: 'Mortgages',
      },
    ],
  },
]

export const buyingSections = [
  {
    id: '1b',
    style: 'Horizontal_Text_Photo',
    title: '1. [Expert] advice',
    subtitle: 'The top Realtors in the country',
    body: '<p>Our Homewise team has years of experience that they will put to work for you. Providing advice for buying and/or selling, to ensure you have the best advice to make important decisions.</p>',
    photos: [
      {
        src: buying1,
        src_webp: buying1_webp,
        alt: 'A young couple becoming homeowners',
      },
    ],
  },
  {
    id: '2b',
    style: 'Horizontal_Photo_Text',
    title: '2. [Communication] that meets your needs',
    subtitle: 'Agents that listen and provide options based on your needs',
    body: '<p>Whether it’s phone calls, emails or text, our team is available to provide you incredible service and be available on your schedule.</p>',
    photos: [
      {
        src: buying2,
        src_webp: buying2_webp,
        alt: 'A couple discussing their home purchase',
      },
    ],
  },
  {
    id: '3b',
    style: 'Horizontal_Text_Photo',
    title: '3. [Amazing] value',
    subtitle: 'Top negotiating skills to save you thousands',
    body: '<p>We not only provide incredible Realtors, we also ensure you get the best service in the industry. Selling a home? Our expert stagers will ensure you get top dollar, while our team and technology help you beat the market.</p>',
    photos: [
      {
        src: buying3,
        src_webp: buying3_webp,
        alt: 'A living room',
      },
    ],
  },
  {
    id: '4b',
    style: 'Horizontal_Photo_Text',
    title: '4. Providing [knowledge] to win',
    subtitle: 'Market, neighborhoods, trends and requirements',
    body: '<p>By pairing our Realtors with our leading technology, we will help you find your perfect home, or sell your home, while our mortgage team helps with affordability and financing. All simply in one place.</p>',
    photos: [
      {
        src: buying4,
        src_webp: buying4_webp,
        alt: 'A business discussion',
      },
    ],
  },
]

export const sellingSections = [
  {
    id: '1s',
    style: 'Horizontal_Text_Photo',
    title: '1. [Amazing] Results',
    subtitle: 'The top Realtors in the country',
    body: "<p>We consistently work to get clients top dollar for their home, while making the process smooth and simple. Whether it's a detached home, condo or cottage, our team has experience driving buyers through the door and surpassing clients expectations on their sale price.</p>",
    photos: [
      {
        src: buying1,
        src_webp: buying1_webp,
        alt: 'A young couple becoming homeowners',
      },
    ],
  },
  {
    id: '2s',
    style: 'Horizontal_Photo_Text',
    title: '2. Marketing that [works]',
    subtitle: 'Staging, photography, virtual tour and social media',
    body: '<p>Our Real Estate team partners with our marketing team to ensure your home not only looks amazing, it also gets as many potential buyers to view it as possible. That is when our marketing strategy goes to work.</p>',
    photos: [
      {
        src: kitchen,
        src_webp: kitchen_webp,
        alt: 'A kitchen',
      },
    ],
  },
  {
    id: '3s',
    style: 'Horizontal_Text_Photo',
    title: '3. [Exposure]',
    subtitle: 'We get your home in front of the right buyers',
    body: '<p>We not only get your listing up on MLS for millions of potential buyers to see, we also share it with our curated database to find high intent buyers.</p>',
    photos: [
      {
        src: selling3,
        src_webp: selling3_webp,
        alt: 'A couple looking at a house',
      },
    ],
  },
  {
    id: '4s',
    style: 'Horizontal_Photo_Text',
    title: '4. [Communication]',
    subtitle: 'Information and updates at every step',
    body: '<p>No sitting by your phone waiting for updates, we provide consistent updates to ensure you are informed throughout the process. So sit back and relax while we get to work for you.</p>',
    photos: [
      {
        src: buying2,
        src_webp: buying2_webp,
        alt: 'A person working on a laptop',
      },
    ],
  },
  {
    id: '5s',
    style: 'Horizontal_Text_Photo',
    title: '5. [Above and beyond]',
    subtitle: 'Financing, contractors, services and more',
    body: '<p>Buying and selling a home is more than just the transaction. At Homewise, we provide Real Estate service, but also mortgage, insurance and more. Need a connection to a contractor, we have many suggestions.</p>',
    photos: [
      {
        src: selling5,
        src_webp: selling5_webp,
        alt: 'A person working on a roof',
      },
    ],
  },
]

export const aboutSections = [
  {
    id: '1a',
    style: 'Horizontal_Text_Photo',
    title: 'Being a Homewise Client',
    subtitle: 'Always being heard and respected',
    body: '<p>We know homeownership is an exciting time, but it can also be stressful. That is why we have a team of Realtors with years of experience to provide you with the partnership you deserve. Whether you are buying or selling, our team will be there for you at each step, providing advice, searching the market, leveraging our industry leading tools and more. In the end, we want to make your exciting journey even better.</p>',
    photos: [
      {
        src: couple,
        src_webp: couple_webp,
        alt: 'A couple looking happy and excited',
      },
    ],
  },
  {
    id: '2a',
    style: 'Horizontal_Photo_Text',
    title: 'Being a Homewise Realtor',
    subtitle: 'Looking to join our Brokerage? ',
    body: '<p>Our team at Homewise is focused on bringing on the best Realtors in the country who focus on one thing, insuring our clients have the best experience possible. Our Realtors get access to incredible training, industry leading technology and a network connection to our mortgage team to create a simple, end-to-end process your clients will love.</p>',
    photos: [
      {
        src: officeChat,
        src_webp: officeChat_webp,
        alt: 'An in-office chat',
      },
    ],
  },
]
