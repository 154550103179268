// store.js
import React, { createContext, useReducer } from 'react'

const initialState = {
  /** Auth related items. */
  loggedIn: false,
  hasHomewise: false,
  userDetailsFirstName: '',
  userDetailsLastName: '',
  userDetailsEmail: '',
  userDetailsPhone: '',
  userDetailsDob: null,
  userDetailsDobMonth: '',
  userDetailsDobDay: '',
  userDetailsDobYear: '',
  userDetailsMaritalStatus: '',
  userDetailsCitizenStatus: '',
  userDetailsPassword: '',
  userDetailsPasswordConfirm: '',
  userSchema: {},
  authRegister: {},
  autoPassword: '',
  authLogin: {},
  authEmail: {},
  authReset: {},
  user: {},

  /** Application - Flow related items. */
  inProgress: false,
  isAddressOpen: false,
  firstTimeHomeBuyer: '',
  propertyInfoOpen: false,
  propertyInfoAddress: '',
  propertyInfoGeoUpdate: false,
  propertyInfoLat: '',
  propertyInfoLng: '',
  propertyInfoAddressOnly: '',
  propertyInfoUnit: '',
  propertyInfoCity: '',
  propertyInfoProvince: '',
  propertyInfoCountry: '',
  propertyInfoPostalCode: '',
  propertyDetailsOwnership: '',
  propertyDetailsPayment: '',
  propertyDetailsOther: false,
  propertyDetailsOtherCount: '',
  propertyDetailsMonth: '',
  propertyDetailsMoveIn: null,
  propertyType: '',
  propertyLocked: false,
  otherProperties: '',
  expectedPriceTotal: '',
  expectedPriceDpCash: '',
  expectedPriceDpPercent: '',
  expectedPriceOtherOffer: false,
  expectedPriceOtherRate: '',
  lenderName: '',
  propertyPurpose: '',
  introduceRealtor: '',
  marketingOptin: false,

  /** Map & search related items. */
  mapPopupOpen: false,
  satelliteView: false,
  mapPopupPosition: {
    lat: 0,
    lng: 0,
  },
  listingCoordinates: {
    lat: null,
    lng: null,
  },
  clusterListingCoordinates: {
    lat: null,
    lng: null,
  },
  mapStatus: {
    loaded: false,
  },
  settings: {
    center: {
      // Toronto
      lat: 43.655733002249015,
      lng: -79.37877025472841,
    },
    area: null,
    city: null,
    zoom: 14,
  },
  streetAddressPin: {
    visible: false,
    location: {
      lat: null,
      lng: null,
    },
  },
  polygon: {
    name: null,
    type: null,
    path: null,
    center: null,
  },
  panTo: {
    lat: null,
    lng: null,
  },
  shouldRefreshMapPins: false,
  searchParams: {
    resultsPerPage: 1,
    fields:
      'map,mlsNumber,listPrice,soldPrice,images[6],sqft,details.propertyType,details.numBedrooms,details.numBedroomsPlus,details.numBathrooms,details.numBathroomsPlus,address,type,details.virtualTourUrl,listDate,office.brokerageName,details.numGarageSpaces,boardId,permissions.displayAddressOnInternet,permissions.displayPublic,lastStatus',
    sortBy: null,
    type: ['sale'],
    status: ['A'],
    lastStatus: ['New', 'Pc'],
    minPrice: 0,
    maxPrice: 6e6,
    minBeds: 0,
    minBaths: null,
    minSqft: null,
    minListDate: null,
    class: ['condo', 'residential'],
    style: null,
    propertyType: null,
    clusterLimit: 100,
    clusterPrecision: 16,
    listings: false,
    aggregates: 'map',
  },
  searchResults: {
    status: 'idle',
    data: [],
    count: null,
    nextPage: null,
  },
  mapPinsListings: {
    status: 'idle',
    data: [],
    count: null,
  },
  pinsStatus: 'idle',
  resultsSection: {
    open: false,
  },

  /** UI behavioural items. */
  modalSeen: false,

  /** Toast-Snackbar notification items. */
  timeoutId: null,
  notifications: [],
  displayedNotifications: [],

  /** Meta-data & partner config items. */
  partnerPath: '',
  group: '',
  alias1: '',
  alias2: '',
  product: '',
  isInternalRedirect: false,
  inFlowReset: false,
  uiTracking: {},
  isPartnerValid: false,
  partner: {},
  referrer: {},
  embedMode: false,
}

const store = createContext(initialState)
const { Provider } = store

const reducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    /** This is used to reset the state when a user logs out. */
    return {
      ...initialState,
    }
  } else if (action.type === 'LOG_IN_RESET') {
    /** This is used to reset the state when a user logs in. */
    return {
      ...initialState,
      userDetailsFirstName: state.userDetailsFirstName,
      userDetailsLastName: state.userDetailsLastName,
      userDetailsDob: state.userDetailsDob,
      userDetailsEmail: state.userDetailsEmail,
      userDetailsPhone: state.userDetailsPhone,
      userDetailsMaritalStatus: state.userDetailsMaritalStatus,
      userDetailsCitizenStatus: state.userDetailsCitizenStatus,
      partnerPath: state.partnerPath,
      group: state.group,
      alias1: state.alias1,
      alias2: state.alias2,
      uiTracking: state.uiTracking,
      isPartnerValid: state.isPartnerValid,
      partner: state.partner,
      referrer: state.referrer,
      embedMode: state.embedMode,
      isInternalRedirect: state.isInternalRedirect,
      app: {
        referrer: state.app.referrer,
      },
    }
  } else if (action.type === 'NOTIFICATION') {
    /** This is used to add a notification to the state. */
    return {
      ...state,
      notifications: [...state.notifications, action.newVal],
    }
  } else if (action.type === 'DISPLAYED_NOTIFICATION') {
    /** This is used to display a notification. */
    return {
      ...state,
      // remove item from notifications
      // notifications: state.notifications.filter((item, index) => index !== action.newVal),
      displayedNotifications: [...state.displayedNotifications, action.newVal],
    }
  } else {
    /** The default action that sends updates to state. */
    return {
      ...state,
      [action.type]: action.newVal,
    }
  }
}

export function useCallReducer() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { state, dispatch }
}

const StateProvider = ({ children }) => {
  return <Provider value={useCallReducer()}>{children}</Provider>
}

export { store, StateProvider }
