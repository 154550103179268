import React from 'react'

import { Grid, Button, Container, Hidden } from '@mui/material'
import Slider from 'react-slick'
import IconButton from '@mui/material/IconButton'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import ImgWithFallback from './ImgWithFallback'

const SamplePrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `2`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        left: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="previous"
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `-.25rem`,
        }}
      />
    </IconButton>
  )
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
    <IconButton
      className={className}
      onClick={onClick}
      style={{
        zIndex: `10`,
        position: `absolute`,
        width: `3rem`,
        height: `3rem`,
        right: `0rem`,
        top: `50%`,
        marginTop: `-1.5rem`,
        // background: `rgba(20, 123, 201, .3)`,
      }}
      aria-label="next"
    >
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{
          color: '#147bc9',
          fontSize: `2rem`,
          marginTop: `-.1rem`,
          marginLeft: `.25rem`,
        }}
      />
    </IconButton>
  )
}

export const RealtorCarousel = ({ realtors, directions }) => {
  let realtorSlidingSettings = {
    dots: false,
    dotsClass: 'hwDots',
    centerMode: false,
    infinite: true,
    fade: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Slider {...realtorSlidingSettings} className="reviewSlider">
      {realtors.map((item) => (
        <Container maxWidth="lg" key={item.id}>
          <Grid
            container
            style={{
              overflow: `hidden`,
              justifyContent: `center`,
              alignItems: `center`,
              paddingBottom: `30px`,
            }}
            direction={directions[item.style]}
            columnSpacing={6}
          >
            <Grid item xs={10} sm={5} className={'realtorCard'}>
              <div>
                <h3
                  style={{
                    marginTop: `0rem`,
                    marginBottom: `0rem`,
                  }}
                >
                  {item.title}
                </h3>
                <div
                  className={'html'}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </div>
              <Hidden smDown>
                <div
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                  }}
                >
                  {item.buttons.map((item) => (
                    <Button
                      startIcon={
                        item.email ? (
                          <EmailIcon sx={{ color: `#054D85` }} />
                        ) : (
                          <LocalPhoneIcon sx={{ color: `#054D85` }} />
                        )
                      }
                      href={item.link}
                      key={item.id}
                      style={{
                        padding: `5px 10px`,
                        margin: `0px`,
                        color: `#007BFF`,
                        textTransform: `none`,
                        fontWeight: `400`,
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </div>
              </Hidden>
            </Grid>
            <Grid item xs={10} sm={4} className={'imageAlign'}>
              {item.photos.map((item, index) => (
                <ImgWithFallback
                  key={index}
                  src={item.src}
                  fallback={item.fallback}
                  alt={item.alt}
                  width="200"
                  className={'rounded'}
                  style={{ margin: `12px`, width: `100%` }}
                />
              ))}
            </Grid>
            <Hidden smUp>
              <Grid item xs={10} sm={5}>
                <div
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                  }}
                >
                  {item.buttons.map((item) => (
                    <Button
                      startIcon={
                        item.email ? (
                          <EmailIcon sx={{ color: `#054D85` }} />
                        ) : (
                          <LocalPhoneIcon sx={{ color: `#054D85` }} />
                        )
                      }
                      href={item.link}
                      key={item.id}
                      style={{
                        padding: `5px 10px`,
                        margin: `0px`,
                        color: `#007BFF`,
                        textTransform: `none`,
                        fontWeight: `400`,
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      ))}
    </Slider>
  )
}
