import React, { useContext } from 'react'
import AuthContext from '../../store/auth/authContext'

import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.neutral.light,
  },
}))

const RequestTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.error.light,
  },
}))

export default function UserDetails() {
  const { user } = useContext(AuthContext)

  const [editName, setEditName] = React.useState(false)
  const [editEmail, setEditEmail] = React.useState(false)
  const [editPhone, setEditPhone] = React.useState(false)
  const [emailNotifications, setEmailNotifications] = React.useState(false)

  const handleCheckboxChange = (event) => {
    setEmailNotifications(event.target.checked)
  }

  if (!user) {
    return <div>Loading user information...</div>
  }

  return (
    <>
      <TableContainer component={'div'}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <StyledTableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Name</strong>
              </TableCell>
              <TableCell align="left">
                {editName ? (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={user.given_name + ` ` + user.family_name}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    // onChange={(e) => {
                    //   setName(e.target.value)
                    // }}
                  />
                ) : (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={user.given_name + ` ` + user.family_name}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    disabled
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {editName ? (
                  <div style={{ display: `flex`, flexDirection: `row` }}>
                    <IconButton
                      aria-label="cancel"
                      title="cancel"
                      type="button"
                      onClick={() => setEditName(false)}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="save"
                      title="save"
                      type="button"
                      onClick={() => setEditName(false)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      width: `80px`,
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      title="edit"
                      type="button"
                      onClick={() => setEditName(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell component="th" scope="row">
                <strong>Email</strong>
              </TableCell>
              <TableCell align="left">
                {editEmail ? (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={user.email}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    // onChange={(e) => {
                    //   setName(e.target.value)
                    // }}
                  />
                ) : (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={user.email}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    disabled
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {editEmail ? (
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      width: `80px`,
                    }}
                  >
                    <IconButton
                      aria-label="cancel"
                      title="cancel"
                      type="button"
                      onClick={() => setEditEmail(false)}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="save"
                      title="save"
                      type="button"
                      onClick={() => setEditEmail(false)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      width: `80px`,
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      title="edit"
                      type="button"
                      onClick={() => setEditEmail(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        <small>Registered on: {user.registered}</small>
      </p>
      <TableContainer component={'div'} sx={{ marginTop: `1.5rem` }}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <RequestTableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ backgroundColor: `#FFF5E5` }}
              >
                <strong>Phone</strong>
              </TableCell>
              <TableCell align="left">
                {editPhone ? (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={'Please provide'}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    // onChange={(e) => {
                    //   setPhone(e.target.value)
                    // }}
                  />
                ) : (
                  <TextField
                    style={{ padding: `0rem` }}
                    fullWidth
                    placeholder={'Please provide'}
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    disabled
                  />
                )}
              </TableCell>
              <TableCell align="right">
                {editPhone ? (
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      width: `80px`,
                    }}
                  >
                    <IconButton
                      aria-label="cancel"
                      title="cancel"
                      type="button"
                      onClick={() => setEditPhone(false)}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="save"
                      title="save"
                      type="button"
                      onClick={() => setEditPhone(false)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      width: `80px`,
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      title="edit"
                      type="button"
                      onClick={() => setEditPhone(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </RequestTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ width: `100%`, padding: `1rem 0` }}>
        <FormControlLabel
          control={<Checkbox checked={emailNotifications} />}
          label={`Email Notifications`}
          checked={emailNotifications}
          onChange={handleCheckboxChange}
        />
      </div>
    </>
  )
}
