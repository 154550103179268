import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../store/auth/authContext'
import { withTranslation } from 'react-i18next'

import axios from 'axios'
import Config from 'config'
import parse from 'parse-link-header'

import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CancelIcon from '@mui/icons-material/Cancel'

import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Box,
} from '@mui/material'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import NumberFormat from 'utils/NumberFormat'

const ResetPasswordDialog = (props) => {
  const { t } = props

  const context = useContext(AuthContext)
  const { confirmPassword } = context
  const [schemaUrl, setSchemaUrl] = useState('')
  const schema = useSchema(schemaUrl)

  const [errors, setErrors] = useState({})
  const [userCode, setUserCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPassword, setShowPassword] = useState('')
  const [showPasswordConfirm, setShowPasswordConfirm] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.CUSTOMER + `/meta?group=homewise`)
      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      // console.log(urlsObject)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'authReset') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !schemaUrl.length

    if (isSchemaEmpty) {
      getSchema()
      isSchemaEmpty = false
    }
  }, [schemaUrl])

  const validatePassword = () => {
    let isError = false
    // empty errors object
    Object.keys(errors).forEach(function (k) {
      delete errors[k]
    })

    if (password.length < 8) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: {
          invalid: true,
          error: t('common:errors.userDetailsPasswordErrorLength'),
        },
      }))
      setButtonLoading(false)
    }

    if (password.length < 1) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: {
          invalid: true,
          error: t('common:errors.userDetailsPasswordError'),
        },
      }))
      setButtonLoading(false)
    }

    if (password !== passwordConfirm) {
      isError = true
      setErrors((errors) => ({
        ...errors,
        password: {
          invalid: true,
          error: t('common:errors.userDetailsPasswordErrorMatch'),
        },
      }))
      setButtonLoading(false)
    }

    return isError
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (buttonLoading) return
    try {
      setButtonLoading(true)
      let data = {
        code: userCode,
        password: password,
        passwordConfirm: passwordConfirm,
      }

      const passwordErr = validatePassword()

      if (!passwordErr) {
        // const { authReset } = await getSchema()

        // const schema = await fetch(authReset).then((res) => res.json())

        // const err = schemaValidate(schema, data)
        const err = validateSchema(schema, data, 'errors')
        if (!err) {
          confirmPassword(
            props.email,
            userCode,
            password,
            setErrors,
            setButtonLoading,
            () => {
              props.handleClose()
            }
          )
        } else setButtonLoading(false)
      } else setButtonLoading(false)
    } catch (err) {
      console.error('password reset submission error', err)
      setButtonLoading(false)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm)
  }

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault()
  }

  return (
    <Dialog
      open={props.open}
      onClose={
        !!props?.allowedClose
          ? () => {
              props?.handleClose(true)
            }
          : null
      }
      aria-labelledby="verify-dialog-title"
      aria-describedby="verify-dialog-description"
    >
      {!!props?.allowedClose && (
        <IconButton
          aria-label="close"
          onClick={() => {
            props?.handleClose(true)
          }}
          style={{
            color: `#000`,
            alignSelf: `flex-end`,
            marginBottom: `-30px`,
          }}
        >
          <CancelIcon />
        </IconButton>
      )}
      <DialogTitle id="verify-dialog-title" style={{ textAlign: `center` }}>
        <span>{t('verify.password')}</span>
      </DialogTitle>
      <DialogContent style={{ padding: `0 0 1.5rem` }}>
        <p
          style={{ textAlign: `center`, margin: `0`, padding: `0px 16px 0px` }}
        >
          {t('verify.passwordCopy')}
        </p>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ m: 3 }}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('verify.label')}
                fullWidth
                margin="normal"
                type="text"
                id="userCode"
                variant="outlined"
                size="small"
                value={userCode}
                InputProps={{
                  inputComponent: NumberFormat,
                  inputProps: { maxLength: 6 },
                }}
                onChange={(e) => {
                  setUserCode(e.target.value)
                  if (errors.code) {
                    delete errors.code
                  }
                }}
                error={errors.code ? errors.code.invalid : false}
                helperText={errors.code ? errors.code.error : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label={t('verify.password')}
                id="password-login"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.password ? true : false}
                helperText={errors.password ? errors.password.error : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label={t('verify.passwordConfirm')}
                id="password-confirm"
                type={showPasswordConfirm ? 'text' : 'password'}
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.passwordConfirm ? true : false}
                helperText={
                  errors.passwordConfirm ? errors.passwordConfirm.error : ''
                }
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Button type="submit" variant="contained">
              {!buttonLoading ? (
                <span>{t('verify.passwordCta')}</span>
              ) : (
                <CircularProgress
                  size={24}
                  sx={{
                    color: `#ffffff`,
                  }}
                />
              )}
            </Button>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default withTranslation('common')(ResetPasswordDialog)
