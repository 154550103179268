import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import { store } from '../../../../store/app/Store'

export default function StatusGroup(props) {
  const globalState = React.useContext(store)
  const { state, dispatch } = globalState

  const [sale, setSale] = React.useState(
    state.searchParams.type.includes('sale') ? true : false
  )
  const [lease, setLease] = React.useState(
    state.searchParams.type.includes('lease') ? true : false
  )
  // const [sold, setSold] = React.useState(props.sold ? props.sold : false)

  const handleChange = (type) => {
    if (type === 'lease') {
      dispatch({
        type: 'searchParams',
        newVal: {
          ...state.searchParams,
          type: ['lease'],
          minPrice: 1e3,
          maxPrice: 5e3, // This needs to always match the last "max" value of const marksLease in src\components\nav\secondary\menu\PriceMobile.js
        },
      })
    } else {
      dispatch({
        type: 'searchParams',
        newVal: {
          ...state.searchParams,
          type: ['sale'],
          minPrice: 0,
          maxPrice: 6e6, // This needs to always match the last "max" value of const salePrices & const marksSale in src\components\nav\secondary\menu\PriceMobile.js
        },
      })
    }
  }

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="Property Status"
      className="saleLeaseGroup"
    >
      <Button
        variant={sale ? 'contained' : 'outlined'}
        onClick={() => {
          setSale(true)
          setLease(false)
          // setSold(false)
          handleChange('sale')
        }}
        sx={{ ...(!sale && { color: `#686868` }) }}
      >
        Sale
      </Button>
      <Button
        variant={lease ? 'contained' : 'outlined'}
        onClick={() => {
          setSale(false)
          setLease(true)
          // setSold(false)
          handleChange('lease')
        }}
        sx={{ ...(!lease && { color: `#686868` }) }}
      >
        Lease
      </Button>
    </ButtonGroup>
  )
}
