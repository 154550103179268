import { useNavigate } from 'react-router-dom'
import Config from '../../config'

const useCustomNavigate = () => {
  const navigate = useNavigate()
  if (process.env.REACT_APP_BASE_PATH === '/real-estate') return navigate
  else {
    async function navigate(to) {
      window.location.assign(`${Config.baseUrl}/real-estate${to}`)
    }
    return navigate
  }
}

export { useCustomNavigate as useNavigate }
