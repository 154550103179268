// A simple results card drawer component that can be used to display a list of results in a drawer on the right half of the screen
// The chevron icon is used to close the drawer, this hangs off the edge and is always visible

import React, { useContext } from 'react'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'

import { store } from '../../store/app/Store'
import ListingsSection from '../results-section/ListingsSection'

const ResultsDrawer = (props) => {
  const theme = useTheme()

  const globalState = useContext(store)
  const { state } = globalState

  return (
    <>
      <div
        style={{
          width: state.resultsSection.open ? '50%' : '0%',
          background: '#E9F5FE',
          transition: 'width 0.3s ease',
          top: theme.spacing(16),
          height: '-webkit-fill-available',
          position: 'absolute',
          right: 0,
          zIndex: 100,
          overflow: 'auto',
        }}
      >
        <Divider />
        {state.resultsSection.open && <ListingsSection />}
      </div>
    </>
  )
}

export default ResultsDrawer
