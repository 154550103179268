import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Hidden from '@mui/material/Hidden'
import ToolTip from '@mui/material/Tooltip'

import MapIcon from '@mui/icons-material/Map'
import AssignmentIcon from '@mui/icons-material/Assignment'

/** Conditionally wraps a tooltip around a button for lgDown */
const ToolTipButton = (props) => {
  const { title, children } = props

  let propsWithoutTitle = { ...props }

  delete propsWithoutTitle.title

  return (
    <>
      <Hidden lgUp>
        <ToolTip title={title} arrow>
          <Button {...propsWithoutTitle}>{children}</Button>
        </ToolTip>
      </Hidden>
      <Hidden lgDown>
        <Button {...props}>{children}</Button>
      </Hidden>
    </>
  )
}

export default function StatusGroup(props) {
  const navigate = useNavigate()
  const [map, setMap] = React.useState(props.map === 'true' ? true : false)
  const [list, setList] = React.useState(props.list === 'true' ? true : false)
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="Property Status"
      className="statusGroup"
    >
      <ToolTipButton
        title="Map"
        variant={map ? 'contained' : 'outlined'}
        onClick={() => {
          setMap(true)
          setList(false)
          navigate('/map')
        }}
        sx={{ ...(!map && { color: `#686868` }) }}
      >
        <Hidden lgDown>Map</Hidden>
        <MapIcon fontSize="small" sx={{ paddingLeft: { lg: '4px', md: 0 } }} />
      </ToolTipButton>
      <ToolTipButton
        title="List"
        variant={list ? 'contained' : 'outlined'}
        onClick={() => {
          setMap(false)
          setList(true)
          navigate('/list')
        }}
        sx={{ ...(!list && { color: `#686868` }) }}
      >
        <Hidden lgDown>List</Hidden>
        <AssignmentIcon
          fontSize="small"
          sx={{ paddingLeft: { lg: '4px', md: 0 } }}
        />
      </ToolTipButton>
    </ButtonGroup>
  )
}
