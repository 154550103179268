import config from '../config'

export const setSessionActive = () => {
  try {
    document.cookie = `activeSession=true; domain=${config.cookieDomain}; path=/`
    return true
  } catch (e) {
    console.error('Error setting session to active', e)
  }
}

export const setSessionInactive = () => {
  try {
    document.cookie = `activeSession=false; domain=${config.cookieDomain}; path=/`
    return true
  } catch (e) {
    console.error('Error setting session to inactive', e)
  }
}

export const isSessionActive = () => {
  try {
    return document.cookie.includes('activeSession=true')
  } catch (e) {
    console.error('Error checking session active', e)
  }
}
