import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { withTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import config from 'config'

import { Button, Container, Grid, Hidden } from '@mui/material'

import Layout from 'layouts/StaticLayout'
import Search from 'components/nav/Search/Search'
import InterestForm from 'components/pages/InterestForm'
import ImageCarousel from 'components/pages/ImageCarousel'
import ImgWithFallback from 'components/pages/ImgWithFallback'
import { ReviewCarouselMini } from 'components/pages/ReviewCarouselMini'
import { RealtorCarousel } from 'components/pages/RealtorCarousel'

import ListingCardList from 'components/common/ListingCardList'
import SearchSuggestions from 'components/common/SearchSuggestions'

import HeroBg1 from 'assets/pages/backgrounds/red-roof.png'
import HeroBg2 from 'assets/pages/backgrounds/lake-view.png'
import HeroBg3 from 'assets/pages/backgrounds/city.png'

import shareImg from 'assets/pages/social_share_low.png'

import AOS from 'aos'
import 'aos/dist/aos.css'

import {
  directions,
  imageFades,
  copyFades,
  sections,
  reviews,
  realtors,
} from 'utils/pageConstants'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { debounce } from '@mui/material/utils'
import { store } from '../store/app/Store'
import UserContext from '../store/app/Context'
import { reverseGeocode } from 'utils/reverseGeocode'
import { isEmpty } from 'lodash'

const Landing = (props) => {
  const { t } = props

  const [heroBg, setHeroBg] = useState(HeroBg1) // Default image

  useEffect(() => {
    const images = [HeroBg1, HeroBg2, HeroBg3]
    const randomImage = images[Math.floor(Math.random() * images.length)]
    setHeroBg(randomImage)
  }, [])

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, []) //eslint-disable-line

  const provider = useContext(UserContext)
  const globalState = useContext(store)
  const { dispatch } = globalState

  let {
    state = globalState?.state?.settings?.area,
    city = globalState?.state?.settings?.city,
  } = useParams()

  const [listings, setListings] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingListings, setLoadingListings] = useState(true)
  const [page, setPage] = useState(1)
  // const [resultRange, setResultRange] = useState([0, 0])
  const [addressHeader, setAddressHeader] = useState(
    state && city
      ? {
          city,
          province: state,
        }
      : null
  )

  const [popular, setPopular] = useState([])
  const [nearby, setNearby] = useState([])
  const [recent, setRecent] = useState([])

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        await request()
      }, 0),
    []
  )

  /** Reverse geocode the center of the map to get the area and city */
  useEffect(() => {
    setLoading(true)
    setLoadingListings(true)
    ;(async () => {
      let newSettings = globalState.state.settings

      const newArea = await reverseGeocode(globalState.state.settings.center) // reverse geocode the center of the map

      if (newArea) newSettings = { ...newSettings, ...newArea }

      // Update the map settings in the global state
      dispatch({
        type: 'settings',
        newVal: newSettings,
      })

      setLoading(false)
      setLoadingListings(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Update the header when the state or city changes */
  useEffect(() => {
    setLoading(true)
    setLoadingListings(true)

    setPage(1)

    let params = { ...globalState.state.searchParams },
      settings = { ...globalState.state.settings }

    if (!isEmpty(state)) {
      setAddressHeader({ province: state })
      params = { ...params, area: state }
      settings = { ...settings, area: state }
      if (!isEmpty(city)) {
        setAddressHeader({ city: city, province: state })
        params = { ...params, city: [city] }
        settings = { ...settings, city: city }
      }
    } else setAddressHeader(null)

    // if the state, city are the same as the searchParams, do nothing
    if (
      state === globalState?.state?.searchParams?.area &&
      city === globalState?.state?.searchParams?.city &&
      state &&
      city
    )
      return

    if (!addressHeader) {
      if (globalState.state.settings?.area) {
        setAddressHeader({ province: globalState.state.settings?.area })
        params = { ...params, area: globalState.state.settings?.area }
        settings = { ...settings, area: globalState.state.settings?.area }

        if (globalState.state.settings?.city) {
          setAddressHeader({
            city: globalState.state.settings?.city,
            province: globalState.state.settings?.area,
          })
          params = { ...params, city: [globalState.state.settings?.city] }
          settings = { ...settings, city: globalState.state.settings?.city }
        }
      }
    }

    dispatch({
      type: 'searchParams',
      newVal: params,
    })

    // update only if the area or city has changed
    if (
      state !== globalState?.state?.settings?.area ||
      city !== globalState?.state?.settings?.city
    ) {
      dispatch({
        type: 'settings',
        newVal: settings,
      })
    }

    setLoading(false)
    setLoadingListings(false)
    // eslint-disable-next-line
  }, [state, city, globalState.state.settings])

  useEffect(() => {
    setLoadingListings(true)
    setLoading(true)
    window.scrollTo(0, 0)

    try {
      fetch(async () => {
        let params = {
          ...globalState.state.searchParams,
          resultsPerPage: 4,
          pageNum: page,
        }

        if (page === 1) {
          // if first page, we want hasImages to be true
          // params['hasImages'] = true

          // if there is no city or area, we want to default to Toronto Ontario
          if (!params['city']) {
            params['city'] = ['Toronto']
          }
        }

        delete params['map']
        delete params['area']
        delete params['listings']
        delete params['aggregates']
        delete params['clusterPrecision']
        delete params['clusterLimit']

        const res = await provider.fetchMapPinsListings(params)

        if (res?.status >= 400) throw new Error(res?.data?.message)
        else {
          dispatch({
            type: 'searchResults',
            newVal: res,
          })

          // setResultRange([page * 8 - 8, page * 8])
          setListings(res?.data)

          setLoadingListings(false)

          // get the mlsNumber from the first listing in the array and make a call to provider.getSuggestions
          const listingId = res?.data?.[0]?.mlsNumber

          if (!isEmpty(listingId)) {
            const suggestionsData = await provider.fetchSuggestions(
              res?.data?.[0]?.mlsNumber,
              res?.data?.[0]?.boardId
            )

            if (suggestionsData?.status >= 400)
              throw new Error(suggestionsData?.data?.message)
            else {
              setPopular(suggestionsData?.data?.suggestions?.popular)
              setNearby(suggestionsData?.data?.suggestions?.nearby)
              setRecent(suggestionsData?.data?.suggestions?.recent)
            }

            setLoading(false)
          }
        }
      })
    } catch (error) {
      console.log('error fetching listings & suggestions', error)
      setLoading(false)
      setLoadingListings(false)
    }
    // eslint-disable-next-line
  }, [globalState.state.searchParams, page])

  return (
    <Layout>
      <div className={'root'}>
        <Helmet>
          <title>{t(`meta.title`)}</title>
          <meta name="description" content={t(`meta.description`)} />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={t(`meta.title`)} />
          <meta itemprop="description" content={t(`meta.description`)} />
          <meta itemprop="image" content={config.baseUrl + shareImg} />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://homewiserealestate.ca" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t(`meta.title`)} />
          <meta property="og:description" content={t(`meta.description`)} />
          <meta property="og:image" content={config.baseUrl + shareImg} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="homewiserealestate.ca" />
          <meta
            property="twitter:url"
            content="https://homewiserealestate.ca"
          />
          <meta name="twitter:title" content={t(`meta.title`)} />
          <meta name="twitter:description" content={t(`meta.description`)} />
          <meta name="twitter:image" content={config.baseUrl + shareImg} />
        </Helmet>
        {/* HERO WITH SVG ARROW */}
        <div style={{ position: `relative` }}>
          <section
            className={'section hero'}
            style={{
              backgroundImage: `url(${heroBg})`,
              backgroundPosition: '0 0',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Container>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={'heroNoCard'}
                  sx={{ textAlign: `left`, paddingBottom: `6rem` }}
                >
                  <h1
                    className={'boostSize'}
                    style={{ color: `#fff`, marginBottom: `1rem` }}
                  >
                    {t('hero.title')}
                  </h1>
                  <Search />
                </Grid>
              </Grid>
            </Container>
          </section>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{
              color: '#ffffff',
              fontSize: `2rem`,
              position: `absolute`,
              bottom: `0`,
              left: `50%`,
              marginLeft: `-.65rem`,
            }}
          />
          <svg
            className={'angleSm'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              fill="#f5f7fa"
              d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
            ></path>
          </svg>
        </div>

        {/* {!loading && !loadingListings && ( */}
        <section>
          <Container style={{ paddingBottom: `2rem` }}>
            <Grid container spacing={0}>
              <ListingCardList
                loadingListings={loadingListings}
                addressHeader={addressHeader}
                listings={listings}
              />
            </Grid>
          </Container>
        </section>
        {/* )} */}

        {sections.map((item) => (
          <span key={item.id}>
            <Hidden mdDown>
              <Container
                maxWidth="lg"
                key={item.id}
                style={{ padding: `2rem 0` }}
              >
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  style={{ overflow: `hidden` }}
                  direction={directions[item.style]}
                >
                  <Grid item xs={10} sm={5} data-aos={imageFades[item.style]}>
                    <h2
                      style={{
                        marginTop: `0rem`,
                        marginBottom: `0rem`,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.title.replace(
                          /\[(.*?)\]/,
                          "<span class='highlight'>$1</span>"
                        ),
                      }}
                    />
                    <div
                      className={'html'}
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />

                    {item.buttons.map((item) => (
                      <Button
                        variant="contained"
                        color={item.primary ? 'primary' : 'secondary'}
                        className={
                          item.primary ? 'hw_btn_primary' : 'hw_btn_secondary'
                        }
                        href={item.link}
                        key={item.id}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    style={{ textAlign: `right` }}
                    data-aos={copyFades[item.style]}
                  >
                    {item.photos.length > 1 ? (
                      <ImageCarousel images={item.photos} />
                    ) : (
                      item.photos.map((item, index) => (
                        <ImgWithFallback
                          key={index + 'i'}
                          src={item.src}
                          fallback={item.fallback}
                          alt={item.alt}
                          className={'fullWidth rounded'}
                        />
                      ))
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
            <Hidden mdUp>
              <Container
                maxWidth="lg"
                key={item.id}
                style={{ padding: `2rem 0` }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ overflow: `hidden` }}
                  direction={directions[item.style]}
                >
                  <Grid item xs={10} sm={5} data-aos={copyFades[item.style]}>
                    {item.photos.length > 1 ? (
                      <ImageCarousel images={item.photos} />
                    ) : (
                      item.photos.map((item, index) => (
                        <ImgWithFallback
                          key={index + 'i'}
                          src={item.src}
                          fallback={item.fallback}
                          alt={item.alt}
                          className={'fullWidth rounded'}
                        />
                      ))
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    data-aos={imageFades[item.style]}
                    style={{ textAlign: `center` }}
                  >
                    <h2
                      style={{
                        marginTop: `1rem`,
                        marginBottom: `0rem`,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.title.replace(
                          /\[(.*?)\]/,
                          "<span class='highlight'>$1</span>"
                        ),
                      }}
                    />
                    <div
                      className={'html'}
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />

                    {item.buttons.map((item) => (
                      <Button
                        variant="contained"
                        color={item.primary ? 'primary' : 'secondary'}
                        className={
                          item.primary ? 'hw_btn_primary' : 'hw_btn_secondary'
                        }
                        href={item.link}
                        key={item.id}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
          </span>
        ))}

        {/* Realtor Carousel */}
        <section
          style={{
            background: `#ffffff`,
            boxShadow: `0 0.5rem 1rem rgb(0 0 0 / 15%)`,
          }}
        >
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} data-aos="fade-up" data-aos-offset={300}>
                <h2 style={{ textAlign: `center` }}>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    AN AMAZING TEAM OF
                  </span>
                  <br />
                  <span className="highlight">Industry Experts</span>
                </h2>
              </Grid>
              <Grid item xs={12}>
                <RealtorCarousel realtors={realtors} directions={directions} />
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className={'section getStarted'}>
          {/* Reviews Carousel */}
          <Container maxWidth="lg" style={{ paddingBottom: `2rem` }}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
              style={{ paddingBottom: `2rem` }}
            >
              <Grid item xs={12} data-aos="fade-up" data-aos-offset={300}>
                <h2 style={{ textAlign: `center` }}>
                  <span className="sm-font" style={{ opacity: 0.6 }}>
                    HOME BUYERS LIKE YOU
                  </span>
                  <br />
                  <span className="highlight">Love Homewise</span>
                </h2>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ position: `relative` }}
            >
              <ReviewCarouselMini reviews={reviews} />
            </Grid>
          </Container>
        </section>

        <section
          className={'section finalCta'}
          style={{
            background: `linear-gradient(180deg, rgba(243, 249, 253, 1) 20%, rgba(5, 77, 133, 1) 100%)`,
          }}
        >
          {/* Lead Capture */}
          <Container id="getStarted">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6}>
                <InterestForm />
              </Grid>
            </Grid>
          </Container>

          {/* Final Search callout */}
          <Container sx={{ padding: `2rem 0rem 4rem` }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={4} sx={{ textAlign: `center` }}>
                <h2 style={{ color: `#ffffff`, marginBottom: `1.5rem` }}>
                  Continue Your Search
                </h2>
                <Search />
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
      <SearchSuggestions
        popular={popular}
        recent={recent}
        nearby={nearby}
        loading={loading}
      />
    </Layout>
  )
}

export default withTranslation('landing')(Landing)
