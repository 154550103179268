import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import AppsIcon from '@mui/icons-material/Apps'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import StatusGroup from './menu/Status'
import DaysActive from './menu/DaysActiveMobile'
// import DaysSold from './menu/DaysSold'
import PriceMobile from './menu/PriceMobile'
import OtherFilters from './menu/OtherFilters'
import { store } from '../../../store/app/Store'
import * as mapConsts from '../../map/const'

const bedsOptions = [
  { label: 'Any', value: 0 },
  { label: '1+', value: 1 },
  { label: '2+', value: 2 },
  { label: '3+', value: 3 },
  { label: '4+', value: 4 },
  { label: '5+', value: 5 },
]

export default function MobileNav(props) {
  const globalState = React.useContext(store)
  const { dispatch } = globalState

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const [bedsSelectedIndex, setBedsSelectedIndex] = React.useState(
    typeof globalState.state.searchParams.minBeds === 'number'
      ? bedsOptions.findIndex(
          (bed) => bed.value === globalState.state.searchParams.minBeds
        )
      : 0
  )

  const [priceAnchor, setPriceAnchor] = React.useState(null)
  const [priceLabel, setPriceLabel] = React.useState('Price')
  const openPrice = Boolean(priceAnchor)

  const handleClosePrice = () => {
    setPriceAnchor(null)
  }

  const handleClickPrice = (event) => {
    setPriceAnchor(event.currentTarget)
  }

  React.useEffect(() => {
    if (
      globalState.state.searchParams.minPrice &&
      globalState.state.searchParams.maxPrice
    ) {
      setPriceLabel(
        `$${mapConsts.humanReadableCurrency(
          globalState.state.searchParams.minPrice
        )} - $${mapConsts.humanReadableCurrency(
          globalState.state.searchParams.maxPrice
        )}`
      )
    } else {
      if (
        globalState.state.searchParams.maxPrice &&
        !globalState.state.searchParams.minPrice
      ) {
        setPriceLabel(
          `Under $${mapConsts.humanReadableCurrency(
            globalState.state.searchParams.maxPrice
          )}`
        )
      } else {
        if (
          globalState.state.searchParams.minPrice &&
          !globalState.state.searchParams.maxPrice
        ) {
          setPriceLabel(
            `Over $${mapConsts.humanReadableCurrency(
              globalState.state.searchParams.minPrice
            )}`
          )
        } else {
          setPriceLabel('Price')
        }
      }
    }
  }, [
    globalState.state.searchParams.minPrice,
    globalState.state.searchParams.maxPrice,
  ])

  // React.useEffect(() => {
  //   setPriceLabel('Price')
  // }, [globalState.state.searchParams.type])

  React.useEffect(() => {
    // Rerender component if priceLabel changes
    if (priceLabel !== 'Price') {
      setPriceAnchor(null)
    }
  }, [priceLabel])

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const handleClickBeds = (event, index) => {
    if (bedsSelectedIndex === index) setBedsSelectedIndex(null)
    else {
      setBedsSelectedIndex(index)
      dispatch({
        type: 'searchParams',
        newVal: {
          ...globalState.state.searchParams,
          minBeds: bedsOptions[index].value,
        },
      })
    }
  }

  const list = (anchor) => (
    <Box className="filterDrawer" role="presentation">
      {/* <h2>Days Sold</h2>
      <DaysSold /> */}
      <h2>Status</h2>
      <StatusGroup />
      <h2>Beds</h2>
      {bedsOptions.map((bed, index) => (
        <Button
          component="button"
          color="primary"
          variant={bedsSelectedIndex === index ? 'contained' : 'outlined'}
          className={bedsSelectedIndex === index ? 'bedsBtn-active' : 'bedsBtn'}
          key={index}
          onClick={(event) => handleClickBeds(event, index)}
        >
          {bed.label}
        </Button>
      ))}
      <h2>Other Filters</h2>
      <OtherFilters />
    </Box>
  )

  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        display: `flex`,
        flexGrow: `1`,
        justifyContent: `space-between`,
        alignContent: `center`,
      }}
    >
      <div
        style={{
          display: `flex`,
          alignContent: `center`,
        }}
      >
        <span className="buttonHolder" style={{ paddingRight: `8px` }}>
          <Button
            component="button"
            color="primary"
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
            className="primaryBtn"
            aria-controls={openPrice ? 'price-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openPrice ? 'true' : undefined}
            onClick={handleClickPrice}
            sx={{ fontSize: `10px`, paddingBottom: `8px` }}
          >
            {/* {priceLabel} */}
            Price
          </Button>
          <Menu
            id="price-menu"
            anchorEl={priceAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openPrice}
            onClose={handleClosePrice}
            MenuListProps={{
              'aria-labelledby': 'price-button',
            }}
            sx={{ marginTop: `15px` }}
          >
            <MenuItem>
              <PriceMobile />
            </MenuItem>
          </Menu>
        </span>
        <DaysActive />
      </div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="primaryBtn"
            aria-label="Open Menu"
            sx={{
              marginLeft: `8px !important`,
              padding: `8px .5rem !important`,
              minWidth: `auto !important`,
            }}
            size="small"
          >
            <AppsIcon fontSize="small" />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={
              largeScreen
                ? {
                    sx: {
                      width: 450,
                      background: '#F3F9FD !important',
                    },
                  }
                : {
                    sx: {
                      width: '100%',
                      background: '#F3F9FD !important',
                    },
                  }
            }
          >
            <AppBar
              sx={{
                padding: '0px',
                background: '#FFFFFF !important',
              }}
              position="fixed"
            >
              <Toolbar className="toolBar">
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    component="button"
                    color="primary"
                    variant="outlined"
                    sx={{ marginTop: '0px' }}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    component="button"
                    color="primary"
                    variant="contained"
                    sx={{ marginTop: '0px' }}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Apply
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            <Toolbar className="toolBar2" />
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  )
}
