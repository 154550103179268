import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Hidden from '@mui/material/Hidden'

import { FileCopy } from '@mui/icons-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import { CopyToClipboard } from 'react-copy-to-clipboard'

export default function ShareMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [copied, setCopied] = React.useState(false)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Hidden mdDown>
        <Button
          component="button"
          color="primary"
          variant="contained"
          endIcon={
            <FontAwesomeIcon
              icon={faShareSquare}
              style={{
                fontSize: '18px',
              }}
            />
          }
          className="primaryBtn"
          sx={{ marginLeft: `20px` }}
          id="share-button-lrg"
          aria-controls={open ? 'share-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Share
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          component="button"
          color="primary"
          variant="contained"
          className="primaryBtn"
          sx={{ marginLeft: `20px` }}
          id="share-button-min"
          aria-controls={open ? 'share-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faShareSquare}
            style={{
              fontSize: '1.25rem',
              marginBottom: `.25rem`,
            }}
          />
        </Button>
      </Hidden>
      <Menu
        id="share-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'share-button-min',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: `15px` }}
      >
        <div
          style={{
            textAlign: 'center',
            margin: `10px`,
            display: `flex`,
            justifyContent: `space-evenly`,
          }}
        >
          <FacebookShareButton
            url={window.location.href}
            quote={'Check out this awesome listing on Homewise!'}
            hashtag="#homewise"
          >
            <FacebookIcon size={36} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={window.location.href}
            title={'Check out this awesome listing on Homewise!'}
            summary={'Check out this awesome listing on Homewise!'}
            source={'Homewise'}
          >
            <LinkedinIcon size={36} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            url={window.location.href}
            title={'Check out this awesome listing on Homewise!'}
            hashtags={['homewise']}
          >
            <TwitterIcon size={36} round={true} />
          </TwitterShareButton>
        </div>
        <div
          style={{
            textAlign: 'center',
            margin: `10px`,
            display: `flex`,
            justifyContent: `space-evenly`,
          }}
        >
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => setCopied(true)}
          >
            <Button
              variant="outlined"
              color={copied ? 'secondary' : 'primary'}
              style={{ textTransform: `uppercase` }}
              startIcon={<FileCopy />}
            >
              {copied ? 'Copied!' : 'Copy this link'}
            </Button>
          </CopyToClipboard>
        </div>
      </Menu>
    </div>
  )
}
