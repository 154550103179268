import { useRouteError } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'

import Header from 'components/nav/Header'
import MapFilters from 'components/nav/secondary/MapFilters'
import i18next from 'i18n'

const map = [
  {
    title: i18next.t('common:footer.map.items.1.title'),
    url: i18next.t('common:footer.map.items.1.url'),
  },
  {
    title: i18next.t('common:footer.map.items.2.title'),
    url: i18next.t('common:footer.map.items.2.url'),
  },
  {
    title: i18next.t('common:footer.map.items.3.title'),
    url: i18next.t('common:footer.map.items.3.url'),
  },
  {
    title: i18next.t('common:footer.map.items.4.title'),
    url: i18next.t('common:footer.map.items.4.url'),
  },
]

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Header />
      <MapFilters list={'true'} map={'false'} />
      <Container component="main" maxWidth="lg">
        <Grid
          container
          style={
            isMobile
              ? {
                  height: `calc(100dvh - 113px)`,
                  margin: `0`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  flexDirection: `column`,
                }
              : {
                  height: `calc(100dvh - 163px)`,
                  margin: `0`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  flexDirection: `column`,
                }
          }
        >
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </Grid>
      </Container>
      <Hidden smDown>
        <Grid container direction="row">
          <Grid item xs={12}>
            <footer className="footer">
              <p>
                © {new Date().getFullYear()} Homewise Real Estate, Brokerage.
                All&nbsp;rights&nbsp;reserved.
              </p>
              <ul>
                {map.map((option, key) => (
                  <li key={key} style={{ marginBottom: `0px` }}>
                    <a
                      href={option.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {option.title}
                    </a>
                  </li>
                ))}
              </ul>
            </footer>
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}
