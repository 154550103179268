import React from 'react'
import ProfileMobileNav from './ProfileMobileNav'

import { AppBar, Toolbar, Button, Hidden, Box } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import MapIcon from '@mui/icons-material/Map'
import NoteAltIcon from '@mui/icons-material/NoteAlt'

const ProfileNav = (props) => {
  const { currentTab, setCurrentTab } = props
  return (
    <AppBar className="secondaryAppBar">
      <Toolbar className="toolBar" style={{ borderTop: `1px solid #E5E5E5` }}>
        <Box sx={{ display: 'flex' }}>
          <Hidden mdDown>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<FavoriteIcon />}
                className={
                  currentTab === 'savedHomes'
                    ? 'primaryBtnActive'
                    : 'primaryBtn'
                }
                onClick={() => {
                  setCurrentTab('savedHomes')
                }}
              >
                Saved Homes
              </Button>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<MapIcon />}
                className={
                  currentTab === 'savedSearches'
                    ? 'primaryBtnActive'
                    : 'primaryBtn'
                }
                sx={{ marginLeft: `20px` }}
                onClick={() => {
                  setCurrentTab('savedSearches')
                }}
              >
                Saved Searches
              </Button>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<NoteAltIcon />}
                className={
                  currentTab === 'accountDetails'
                    ? 'primaryBtnActive'
                    : 'primaryBtn'
                }
                sx={{ marginLeft: `20px` }}
                onClick={() => {
                  setCurrentTab('accountDetails')
                }}
              >
                Account Details
              </Button>
            </Box>
          </Hidden>

          <Hidden mdUp>
            <ProfileMobileNav
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </Hidden>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ProfileNav
