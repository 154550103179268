import { forwardRef } from 'react'
import { TextField } from '@mui/material'

const phoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      label="Phone"
      id="leadCapturePhone"
      InputProps={{
        autoComplete: 'chrome-off',
      }}
      size="small"
      fullWidth
      required
      error={props.errors.phone ? true : false}
      helperText={props.errors.phone ? props.errors.phone : ''}
      InputLabelProps={{ shrink: true }}
    />
  )
}
export default forwardRef(phoneInput)
