import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import common_en from './translations/en/common.json'
import common_fr from './translations/fr/common.json'

import landing_en from './translations/en/landing.json'
import landing_fr from './translations/fr/landing.json'

import buying_en from './translations/en/buying.json'
import buying_fr from './translations/fr/buying.json'

import selling_en from './translations/en/selling.json'
import selling_fr from './translations/fr/selling.json'

import about_en from './translations/en/about.json'
import about_fr from './translations/fr/about.json'

import login_en from './translations/en/login.json'
import login_fr from './translations/fr/login.json'

import errors_en from './translations/en/errors.json'
import errors_fr from './translations/fr/errors.json'

import notifications_en from './translations/en/notifications.json'
import notifications_fr from './translations/fr/notifications.json'

import contact_en from './translations/en/contact.json'
import contact_fr from './translations/fr/contact.json'

const resources = {
  en: {
    common: common_en,
    landing: landing_en,
    buying: buying_en,
    selling: selling_en,
    about: about_en,
    login: login_en,
    errors: errors_en,
    notifications: notifications_en,
    contact: contact_en,
  },
  fr: {
    common: common_fr,
    landing: landing_fr,
    buying: buying_fr,
    selling: selling_fr,
    about: about_fr,
    login: login_fr,
    errors: errors_fr,
    notifications: notifications_fr,
    contact: contact_fr,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    languages: ['en', 'fr'],
    ns: [
      'common',
      'landing',
      'buying',
      'selling',
      'about',
      'login',
      'errors',
      'notifications',
      'contact',
    ],
    defaultNS: 'common',
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
