// A simple Link component that appends ${Config.baseUrl} to the "to" prop of the Link component.

import React from 'react'
import { Link } from 'react-router-dom'
import Config from '../../config'

const CustomLink = ({ to, children, ...rest }) => {
  if (process.env.REACT_APP_BASE_PATH === '/') {
    return (
      <a href={`${Config.baseUrl}/real-estate${to}`} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
}

export { CustomLink as Link }
