import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { store } from '../../../../store/app/Store'
import { soldStatuses, leasedStatuses } from 'utils/constants'
const soldStatusesArray = [...soldStatuses, ...leasedStatuses]

const options = [
  'All',
  '1 day',
  '3 days',
  '7 days',
  '30 days',
  '90 days',
  '180 days',
  '1 Year Ago',
  '2 Years Ago',
  '3 Years Ago',
  '4 Years Ago',
  '5 Years Ago',
  '6 Years Ago',
  '7 Years Ago',
]
const values = [0, 1, 3, 7, 30, 90, 180, 360, 720, 1080, 1440, 1800, 2160, 2520]

export default function DaysSold() {
  const globalState = React.useContext(store)
  const { state, dispatch } = globalState

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const [active, setActive] = React.useState(
    Array.isArray(state?.searchParams?.status)
      ? state?.searchParams?.status?.includes?.('U')
      : false
  )

  const numberOfDays = () => {
    if (state.searchParams.minSoldDate === 0) return 0

    if (!state.searchParams.minSoldDate) return 0

    const date = new Date(state.searchParams.minSoldDate)
    const today = new Date()
    const timeDiff = Math.abs(today.getTime() - date.getTime())
    const numOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

    // for any number of days return the closest value in the values array
    const final = values.reduce((prev, curr) =>
      Math.abs(curr - numOfDays) < Math.abs(prev - numOfDays) ? curr : prev
    )

    // if final is out of bound, return ''
    if (final > values[values.length - 1]) return ''

    return final
  }

  const [daysSold, setDaysSold] = React.useState(numberOfDays())
  const [selectedIndex, setSelectedIndex] = React.useState(
    values.indexOf(daysSold)
  )

  const handleChangeDaysSold = (value) => {
    setDaysSold(values[value])

    // calculate the date from today minus the number of days
    const date = new Date()
    date.setDate(date.getDate() - values[value])

    dispatch({
      type: 'searchParams',
      newVal: {
        ...state.searchParams,
        // conver to YYYY-MM-DD
        minSoldDate:
          Number(values[value]) <= 0 ? 0 : date.toISOString().split('T')[0],
      },
    })
  }

  const handleClick = () => {
    let params = {
      ...state.searchParams,
    }

    if (active) {
      params.status = params.status.filter((status) => status !== 'U')

      if (params.status.length === 0) params.status = null

      delete params.minSoldDate

      if (Array.isArray(params?.lastStatus))
        params.lastStatus = params?.lastStatus.filter(
          (status) => !soldStatusesArray.includes(status)
        )
      else delete params.lastStatus
    }

    if (!active) {
      if (Array.isArray(params.status)) params.status.push('U')
      else params.status = ['U']
      params.minSoldDate = null

      if (Array.isArray(params?.lastStatus))
        params?.lastStatus.push(...soldStatusesArray)
      else params.lastStatus = soldStatusesArray
    }

    dispatch({
      type: 'searchParams',
      newVal: params,
    })
    setActive(!active)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    handleChangeDaysSold(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup
        ref={anchorRef}
        variant="outlined"
        aria-label="Days Sold"
        className="daysActiveGroup"
      >
        <Button
          onClick={handleClick}
          variant={active ? `contained` : `outlined`}
          sx={{
            fontWeight: `700`,
            background: active ? `#AA482F` : `#fff`,
            '&:hover': {
              background: active ? `#AA482F` : `#fff`,
            },
            color: active ? `#fff` : `#686868`,
          }}
        >
          {state?.searchParams?.type?.includes('sale') ? 'Sold' : 'Leased'}
        </Button>
        {/* {active && ( */}
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select days active filter"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ color: `#686868` }}
        >
          {options[selectedIndex]} <KeyboardArrowDownIcon />
        </Button>
        {/* )} */}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1300,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            sx={{ marginTop: `13px` }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
