import React, { useContext, useEffect, useState } from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useInterval } from 'utils/hooks/useInterval'
import Slide from '@mui/material/Slide'
import { SnackbarProvider } from 'notistack'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import TagManager from 'react-gtm-module'

import Config from './config'
import AuthContext from './store/auth/authContext'
import { store } from './store/app/Store'
import { isSessionActive } from 'utils/session'

import Landing from './pages/Landing'
import About from './pages/About'
import Buying from './pages/Buying'
import Selling from './pages/Selling'
import Contact from './pages/Contact'

import Explore from './pages/Explore'
import ListView from './pages/ListView'
import DetailView from './pages/DetailView'
import Search from './pages/Search'

import Notifier from 'components/common/Notifier.js'
import Login from './pages/auth/Login'
import NewAccount from './pages/auth/NewAccount'
import Profile from './pages/auth/Profile'
import ResetPassword from './pages/auth/ResetPassword'
import ErrorPage from 'pages/Error'
import config from './config'

const tagManagerArgs = {
  gtmId: Config.google.GTM_ID,
  auth: Config.google.GTM_AUTH,
  preview: Config.google.GTM_PREV,
}

TagManager.initialize(tagManagerArgs)

// https://coolors.co/326822-ccefc4-007bff-147bc9-054d85
// https://coolors.co/406236-ccefc4-3b98fb-3c8cc9-205680

const theme = createTheme({
  root: { '& svg:first-child': { transform: 'scale(1)' } },
  palette: {
    primary: {
      light: '#007BFF',
      main: '#147BC9',
      dark: '#054D85',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#007BFF',
      main: '#147BC9',
      dark: '#054D85',
      contrastText: '#3179C6',
    },
    background: {
      light: '#fefefe',
      main: '#f5f7fa',
      dark: '#cecece',
      contrastText: '#3f3f3f',
    },
    error: {
      light: '#FFEBEE',
      main: '#F44336',
      dark: '#D32F2F',
      contrastText: '#fff',
    },
    neutral: {
      light: '#ECF4FB',
      main: '#E5F0F8',
      dark: '#DCE9F4',
      contrastText: '#3f3f3f',
    },
    forSale: {
      light: '#EAF5E4',
      main: '#EAF5E4',
      dark: '#EAF5E4',
      contrastText: '#445639',
    },
    forLease: {
      light: '#007BFF',
      main: '#147BC9',
      dark: '#054D85',
      contrastText: '#ffffff',
    },
    sold: {
      light: '#C47F6D',
      main: '#AA482F',
      dark: '#AA482F',
      contrastText: '#ffffff',
    },
    chip: {
      light: '#007BFF',
      main: '#054D85',
      dark: '#054D85',
      contrastText: '#ffffff',
    },
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: [
      'Inter',
      'Merriweather Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: `#4C5257`,
        },
        rounded: {
          borderRadius: `8px`,
        },
        elevation1: {
          boxShadow: `
          0px 2px 15px -1px rgba(0,0,0,0.1), 
          0px 1px 1px 0px rgba(0,0,0,0.1), 
          0px 1px 3px 0px rgba(0,0,0,0.1)
        `,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: `8px`,
          fontWeight: `700`,
          padding: `8px 16px 6px`,
          marginTop: `1rem`,
          textTransform: `uppercase`,
          letterSpacing: `0.1em`,
        },
        label: {
          textTransform: `none`,
          fontWeight: `700`,
        },
      },
    },
  },
})

const App = () => {
  const { user, getRefreshedSession, getSession } = useContext(AuthContext)
  const globalState = useContext(store)
  const { dispatch } = globalState
  const [activeSession, setActiveSession] = useState(
    user?.activeSession || isSessionActive()
  )

  // Refresh session every 45mins
  useInterval(() => {
    getRefreshedSession()
  }, 1200000)

  // Get session on app load
  useEffect(() => {
    if (activeSession) getRefreshedSession()
    else getSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Version check and notification using manifest.json file.
   * 1 hour long-polling.
   * @returns {void}
   */
  useInterval(async () => {
    try {
      const response = await fetch(
          `${config.baseUrl}/real-estate/manifest.json`
        ),
        data = await response.json()

      const version = data.version || '0.0.0',
        currentVersion = config.version

      if (currentVersion === version) return

      dispatch({
        type: 'NOTIFICATION',
        newVal: {
          index: 'version',
          messageTranslationLink: 'notifications:version',
          options: {
            variant: 'default',
            persist: true,
          },
          buttons: ['reload'],
        },
      })
    } catch (error) {
      console.error('Error checking version:', error)
    }
    // 10 mins in ms = 600000
  }, 600000)

  /* Wrapper component for auth routes to redirect to home if a session is active */
  const AuthRoute = ({ isPrivate = false, element, ...rest }) => {
    useEffect(() => {
      setActiveSession(user?.activeSession)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    if (isPrivate && !activeSession)
      return <Navigate to="/auth/login" replace />
    if (!isPrivate && activeSession) return <Navigate to="/" replace />
    return element
  }

  /* Router configuration */
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element:
          process.env.REACT_APP_BASE_PATH === '/real-estate' ? (
            <Explore />
          ) : (
            <Landing />
          ),
        errorElement: <ErrorPage />,
      },
      { path: '/about', element: <About />, errorElement: <ErrorPage /> },
      { path: '/buying', element: <Buying />, errorElement: <ErrorPage /> },
      { path: '/selling', element: <Selling />, errorElement: <ErrorPage /> },
      { path: '/contact', element: <Contact />, errorElement: <ErrorPage /> },
      { path: '/map', element: <Explore /> },
      {
        path: '/map/:coordinates',
        element: <Explore />,
      },
      {
        path: '/map/:coordinates/:mlsId/:boardId',
        element: <Explore />,
      },
      { path: '/search', element: <Search /> },
      { path: '/list', element: <ListView /> },
      {
        path: '/list/:state',
        element: <ListView />,
      },
      {
        path: '/list/:state/:city',
        element: <ListView />,
      },
      {
        path: '/detail/:state?/:city?/:neighborhood?/:address?/:mlsId/:boardId',
        element: <DetailView />,
      },
      {
        path: '/detail/:state?/:city?/:neighborhood?/:redundantProp?/:address?/:mlsId/:boardId',
        element: <DetailView />,
      },
      {
        path: '/auth/new-account',
        element: <AuthRoute element={<NewAccount />} />,
      },
      { path: '/auth/login', element: <AuthRoute element={<Login />} /> },
      {
        path: '/auth/verify',
        element: <AuthRoute element={<ResetPassword />} />,
      },
      {
        path: '/auth/profile',
        element: <AuthRoute element={<Profile />} isPrivate={true} />,
      },
    ],
    { basename: process.env.REACT_APP_BASE_PATH }
  )

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={2}
          style={{ pointerEvents: 'all' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={Slide}
        >
          <Notifier />
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App
