import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthContext from '../../store/auth/authContext'

import { AppBar, Toolbar, Button, Hidden, Box } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SearchIcon from '@mui/icons-material/Search'

import { nav } from '../../utils/constants'
import MobileNav from './MobileNav'
import Search from './Search/Search'

import logo from '../../assets/white-logo.png'
import smLogo from '../../assets/homewise-house.png'
import { isSessionActive } from 'utils/session'

export default function Header(props) {
  const { t } = useTranslation('common')
  const { user, logout } = useContext(AuthContext)
  const activeSession = user?.activeSession || isSessionActive()

  let targetTab

  const openNewTab = (url) => {
    if (!targetTab || targetTab.closed) {
      targetTab = window.open('', '_blank')
    }
    targetTab.focus()
    targetTab.location.href = url
  }

  return (
    <>
      <AppBar
        sx={{
          padding: '0px',
          boxShadow: 'inset 0px -10px 10px 0px rgba(0,0,0,0.05)',
          background: '#147BC9 !important',
          zIndex: '200',
          // borderBottom: '1px solid #054D85',
        }}
        position="fixed"
      >
        <Toolbar className="toolBar mapMobile">
          <Hidden mdDown>
            <Box
              sx={{
                display: `flex`,
                alignItems: `center`,
                flexGrow: `1`,
                paddingRight: `2rem`,
              }}
            >
              <Link to="/" style={{ height: `50px`, marginRight: `12px` }}>
                <img src={logo} alt="Homewise" height="50" width="112" />
              </Link>
              <Search />
            </Box>
          </Hidden>

          <Hidden mdUp>
            <Link to="/" style={{ height: `30px`, width: `64px` }}>
              <img
                src={smLogo}
                alt="Homewise"
                height="30"
                style={{
                  display: `block`,
                  margin: `0 auto`,
                  paddingLeft: `7px`,
                }}
              />
            </Link>
            {/* <Search /> */}
            <Link to={`/search`}>
              <Button
                component="button"
                color="primary"
                variant="contained"
                className="primaryBtn"
                startIcon={<SearchIcon />}
                sx={{
                  textTransform: 'none',
                  fontSize: '.8rem',
                  fontWeight: '400 !important',
                  padding: '0.5rem 1rem',
                }}
              >
                {t('search.label')}
              </Button>
            </Link>
          </Hidden>
          <Box sx={{ display: 'flex' }}>
            <Hidden mdDown>
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                {nav.map((page) => (
                  <Button
                    key={page.id}
                    className="navBtn"
                    onClick={() => openNewTab(page.slug)}
                  >
                    {page.label}
                  </Button>
                ))}
                <Link
                  to={
                    activeSession
                      ? '#'
                      : `/auth/login?redirect=${window.location.pathname}`
                  }
                >
                  <Button
                    className="navBtn"
                    style={{ marginRight: `2rem` }}
                    onClick={() => {
                      if (activeSession)
                        logout(() => {
                          window.location.reload()
                        })
                    }}
                  >
                    {activeSession ? 'Logout' : 'Login'}
                  </Button>
                </Link>
                {/* {user ? (
                  <Link to="/auth/profile">
                    <Button className="navBtn">{'Profile'}</Button>
                  </Link>
                ) : (
                  <Link to="/auth/login">
                    <Button className="navBtn">{'Login'}</Button>
                  </Link>
                )} */}
              </Box>
              <Button
                component="a"
                href={`https://my.thinkhomewise.com/m/real_estate`}
                color="secondary"
                variant="contained"
                endIcon={<KeyboardArrowRightIcon />}
                className="secondaryBtn"
                target="_blank"
              >
                {'Mortgage Options'}
              </Button>
            </Hidden>

            <Hidden mdUp>
              <MobileNav />
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar className="toolBar2" />
      <Toolbar className="toolBar2" style={{ minHeight: `54px` }} />
    </>
  )
}
