/**
 * StorageList is used to keep track of markers that were clicked on during a session.
 * It helps determine the icon color of the marker.
 * It is used in src\components\map\Leaflet.js.
 * @class StorageList
 * @property {Array} storageList - an array of marker ids
 * @method add - adds a value to the storageList
 * @method includes - checks if the storageList includes a value
 * @exports StorageList
 */
class StorageList {
  constructor() {
    this.storageList = this.#readFromStorage()
  }

  /**
   * Reads the storageList from sessionStorage.
   * @returns {Array} - an array of marker ids
   */
  #readFromStorage() {
    const serializedList = sessionStorage.getItem('storageList')
    return serializedList ? JSON.parse(serializedList) : []
  }

  /**
   * Writes the storageList to sessionStorage.
   */
  #writeToStorage() {
    const serializedList = JSON.stringify(this.storageList)
    sessionStorage.setItem('storageList', serializedList)
  }

  /**
   * Adds a value to the storageList.
   * @param {String} value - a marker id
   * @returns {Array} - an array of marker ids
   */
  add(value) {
    this.storageList.push(value)
    this.#writeToStorage()
  }

  /**
   * Checks if the storageList includes a value.
   * @param {String} value - a marker id
   * @returns {Boolean} - if the storageList includes the value
   */
  includes(value) {
    return this.storageList.includes(value)
  }
}

const storageList = new StorageList()

export default storageList
