import * as mapConsts from '../components/map/const'
import { Loader } from '@googlemaps/js-api-loader'

/**
 * A function to reverse geocode a latLng to get the area, city, state/province.
 * @param {Object} location - { lat: Number, lng: Number }
 * @returns {Object} - { area: String, city: String }
 */
export async function reverseGeocode(location) {
  try {
    const loader = new Loader(mapConsts.loaderOptions)

    await loader.load()

    // get the address from the latLng
    const geoCoder = new window.google.maps.Geocoder()

    if (!geoCoder) throw new Error('Geocoder not found')

    const point = new window.google.maps.LatLng(location.lat, location.lng)

    let newSettings = {}

    await geoCoder.geocode({ location: point }, (results, status) => {
      if (status === 'OK') {
        const addressComponents = results[0].address_components

        const area = addressComponents.find((c) =>
          c.types.includes('administrative_area_level_1')
        )
        const city = addressComponents.find((c) => c.types.includes('locality'))

        if (area?.long_name) newSettings.area = area?.long_name
        if (city?.long_name) newSettings.city = city?.long_name
      } else throw new Error('Geocoder failed with status: ' + status)
    })

    // unload the google maps api
    await loader.deleteScript()

    return newSettings
  } catch (e) {
    console.error('error reverse geocoding: ', e)
    return null
  }
}
