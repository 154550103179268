import React, { useEffect } from 'react'
// import LocationSearch from '../LocationSearch'
// import Search from '../Search'

import { AppBar, Toolbar, Button, Hidden, Box } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import MapMobileFilters from './MapMobileFilters'
import StatusGroup from './menu/Status'
import ViewGroup from './menu/View'
import PriceMobile from './menu/PriceMobile'
import OtherFilters from './menu/OtherFilters'
import DaysActive from './menu/DaysActive'
import DaysSold from './menu/DaysSold'

import { store } from '../../../store/app/Store'
import * as mapConsts from '../../map/const'
import { homeTypeOptions } from 'utils/constants'

const MapFilters = (props) => {
  const globalState = React.useContext(store)
  const { state, dispatch } = globalState

  const [priceAnchor, setPriceAnchor] = React.useState(null)
  const [priceLabel, setPriceLabel] = React.useState('Price')
  const [hometypeAnchor, setHometypeAnchor] = React.useState(null)
  const [moreAnchor, setMoreAnchor] = React.useState(null)

  const openPrice = Boolean(priceAnchor)
  const openType = Boolean(hometypeAnchor)
  const openMore = Boolean(moreAnchor)

  // We use propertyType to determine which homeTypeOptions are selected because those values are guranteed to be unique.
  const [homeTypeSelections, setHomeTypeSelections] = React.useState(
    Array.isArray(state.searchParams?.propertyType)
      ? // find the index of all the homeTypeOption whose homeTypeOption.value.propertyType[0] is mentioned in state.searchParams.propertyType
        homeTypeOptions
          .map((option, index) => {
            if (
              state.searchParams.propertyType.includes(
                option.value?.propertyType[0]
              )
            )
              return index
            return null
          })
          .filter((index) => index !== null)
      : [0]
  )

  const handleClickPrice = (event) => {
    setPriceAnchor(event.currentTarget)
  }
  const handleClosePrice = () => {
    setPriceAnchor(null)
  }

  const handleClickType = (event, index) => {
    let freshSelections = [...homeTypeSelections]

    if (!hometypeAnchor) setHometypeAnchor(event.currentTarget)

    // if already selected, remove from array
    if (homeTypeSelections.includes(index)) {
      const newSelections = homeTypeSelections.filter((type) => type !== index)
      freshSelections = newSelections
    }
    // if not already selected, add to array
    else freshSelections.push(index)

    // if index is 0, set homeTypeSelections to [0] else remove 0 from homeTypeSelections
    if (index === 0) freshSelections = [0]
    else freshSelections = freshSelections.filter((type) => type !== 0)

    setHomeTypeSelections(freshSelections)
  }

  useEffect(() => {
    // construct searchParams
    let searchParams = {
      ...state.searchParams,
      class: [],
      style: [],
      propertyType: [],
    }

    // for all selected home types, add the respective homeTypeOptions[index].value to searchParams
    homeTypeSelections.forEach((index) => {
      // if index is 0, remove the class, style, and propertyType from searchParams
      if (index === 0) {
        searchParams = {
          ...searchParams,
          class: ['residential', 'condo'],
          style: [],
          propertyType: [],
        }
        return
      }

      const additionalSearchParams = homeTypeOptions[index]?.value

      // if class is an array, add each item to searchParams.class
      if (Array.isArray(additionalSearchParams?.class)) {
        // if the class is already in searchParams.class, skip it
        additionalSearchParams.class.forEach((classItem) => {
          if (!searchParams.class.includes(classItem))
            searchParams.class.push(classItem)
        })
      } else {
        // if the class is already in searchParams.class, skip it
        if (!searchParams.class.includes(additionalSearchParams?.class))
          searchParams.class.push(additionalSearchParams?.class)
      }

      // if style is an array, add each item to searchParams.style
      if (Array.isArray(additionalSearchParams?.style))
        searchParams.style = [
          ...searchParams.style,
          ...additionalSearchParams?.style,
        ]

      // if propertyType is an array, add each item to searchParams.propertyType
      if (Array.isArray(additionalSearchParams?.propertyType))
        searchParams.propertyType = [
          ...searchParams.propertyType,
          ...additionalSearchParams?.propertyType,
        ]
    })

    // make sure searchParams.class, searchParams.style, searchParams.propertyType is unique
    searchParams.class = [...new Set(searchParams.class)]
    searchParams.style = [...new Set(searchParams.style)]
    searchParams.propertyType = [...new Set(searchParams.propertyType)]

    dispatch({
      type: 'searchParams',
      newVal: searchParams,
    })
    // setHometypeAnchor(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeTypeSelections, dispatch])

  useEffect(() => {
    if (state.searchParams.minPrice && state.searchParams.maxPrice) {
      setPriceLabel(
        `$${mapConsts.humanReadableCurrency(
          state.searchParams.minPrice
        )} - $${mapConsts.humanReadableCurrency(state.searchParams.maxPrice)}`
      )
    } else {
      if (state.searchParams.maxPrice && !state.searchParams.minPrice) {
        setPriceLabel(
          `Under $${mapConsts.humanReadableCurrency(
            state.searchParams.maxPrice
          )}`
        )
      } else {
        if (state.searchParams.minPrice && !state.searchParams.maxPrice) {
          setPriceLabel(
            `Over $${mapConsts.humanReadableCurrency(
              state.searchParams.minPrice
            )}`
          )
        } else {
          setPriceLabel('Price')
        }
      }
    }
  }, [state.searchParams.minPrice, state.searchParams.maxPrice])

  useEffect(() => {
    setPriceLabel('Price')
  }, [state.searchParams.type])

  useEffect(() => {
    // Rerender component if priceLabel changes
    if (priceLabel !== 'Price') {
      setPriceAnchor(null)
    }
  }, [priceLabel])

  const handleClickMore = (event) => {
    setMoreAnchor(event.currentTarget)
  }
  const handleCloseMore = () => {
    setMoreAnchor(null)
  }

  return (
    <AppBar className="secondaryAppBar">
      <Toolbar
        className="toolBar"
        style={{
          borderTop: `1px solid #E5E5E5`,
          justifyContent: `center`,
          minHeight: `54px`,
        }}
      >
        <Hidden mdDown>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: `center`,
            }}
          >
            {/* Sale, Sold, Lease Selection */}
            <StatusGroup />

            {/* Price Filter */}
            <span className="buttonHolder" style={{ paddingRight: `16px` }}>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                className="primaryBtn"
                aria-controls={openPrice ? 'price-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPrice ? 'true' : undefined}
                onClick={handleClickPrice}
              >
                {priceLabel}
              </Button>
              <Menu
                id="price-menu"
                anchorEl={priceAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={openPrice}
                onClose={handleClosePrice}
                MenuListProps={{
                  'aria-labelledby': 'price-button',
                }}
                sx={{ marginTop: `15px` }}
              >
                <MenuItem>
                  <PriceMobile />
                </MenuItem>
              </Menu>
            </span>

            {/* Days Active Filter */}
            <span className="buttonHolder" style={{ paddingRight: `16px` }}>
              <DaysActive />
            </span>

            {/* Days Sold Filter */}
            <span className="buttonHolder" style={{ paddingRight: `16px` }}>
              <DaysSold />
            </span>

            {/* Home Type Filter */}
            <span className="buttonHolder" style={{ paddingRight: `16px` }}>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                className="primaryBtn"
                aria-controls={openType ? 'type-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openType ? 'true' : undefined}
                onClick={(event) =>
                  setHometypeAnchor(hometypeAnchor ? null : event.currentTarget)
                }
              >
                Home Type
              </Button>
              <Menu
                id="hometype-menu"
                anchorEl={hometypeAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={openType}
                onClose={() => setHometypeAnchor(null)}
                MenuListProps={{
                  'aria-labelledby': 'hometype-button',
                }}
                sx={{ marginTop: `15px` }}
              >
                <FormGroup>
                  {homeTypeOptions.map((type, index) => (
                    <MenuItem key={index}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={
                              homeTypeSelections?.includes(index) || false
                            }
                          />
                        }
                        label={type.label}
                        onChange={(event) => handleClickType(event, index)}
                      />
                    </MenuItem>
                  ))}
                </FormGroup>
              </Menu>
            </span>

            {/* More Filters */}
            <span className="buttonHolder" style={{ paddingRight: `16px` }}>
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardArrowDownIcon />}
                className="primaryBtn"
                aria-controls={openMore ? 'more-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMore ? 'true' : undefined}
                onClick={handleClickMore}
              >
                More
              </Button>
              <Menu
                id="more-menu"
                anchorEl={moreAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={openMore}
                onClose={handleCloseMore}
                MenuListProps={{
                  'aria-labelledby': 'more-button',
                }}
                PaperProps={{
                  style: {
                    // maxHeight: 48 * 4.5,
                    width: '20ch',
                    padding: `10px`,
                    marginTop: `15px`,
                  },
                }}
              >
                <OtherFilters />
                <Button
                  component="button"
                  color="primary"
                  variant="contained"
                  onClick={handleCloseMore}
                >
                  Apply
                </Button>
              </Menu>
            </span>

            {/* Map/Listings Toggle*/}
            <ViewGroup map={props.map} list={props.list} />
          </Box>
        </Hidden>

        <Hidden mdUp>
          <Box
            sx={{
              display: 'flex',
              flexGrow: `1`,
            }}
          >
            <MapMobileFilters map={props.map} list={props.list} />
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default MapFilters
