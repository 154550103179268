import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar, Toolbar, Button, Hidden, Box } from '@mui/material'
// import FavoriteIcon from '@mui/icons-material/Favorite'
import MapIcon from '@mui/icons-material/Map'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import ShareMenu from '../../common/ShareButtons'

const DetailNav = (props) => {
  const { map, mlsId, boardId } = props
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const seeOnMap = () => {
    // if map & mlsId are present, get map.lattitude & map.longitude
    if (map && mlsId) {
      const { latitude, longitude } = map

      // navigate to map with lat & long
      navigate(`/map/${latitude};${longitude}/${mlsId}/${boardId}`)
    }
  }

  return (
    <AppBar className="secondaryAppBar">
      <Toolbar
        className="toolBar"
        style={{
          borderTop: `1px solid #E5E5E5`,
          minHeight: `54px`,
        }}
      >
        <Hidden mdDown>
          <Box>
            <Button
              component="button"
              color="primary"
              variant="contained"
              startIcon={<ArrowBackIcon />}
              className="primaryBtn"
              onClick={goBack}
            >
              Back
            </Button>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {/* <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<FavoriteIcon />}
                className="primaryBtn"
              >
                Save
              </Button> */}
              <ShareMenu />
              <Button
                component="button"
                color="primary"
                variant="contained"
                endIcon={<MapIcon />}
                className="primaryBtn"
                sx={{ marginLeft: `20px` }}
                onClick={seeOnMap}
              >
                View On Map
              </Button>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {/* <Button
                component="button"
                color="primary"
                variant="contained"
                className="primaryBtn"
              >
                <FavoriteIcon />
              </Button> */}
              <ShareMenu />

              <Button
                component="button"
                color="primary"
                variant="contained"
                className="primaryBtn"
                sx={{ marginLeft: `20px` }}
                onClick={seeOnMap}
              >
                <MapIcon />
              </Button>
            </Box>
          </Box>
          <Box>
            <Button
              component="button"
              color="primary"
              variant="contained"
              className="primaryBtn"
              onClick={goBack}
            >
              <ArrowBackIcon />
            </Button>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default DetailNav
