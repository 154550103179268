import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../store/auth/authContext'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'

import i18next from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardCheck,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

import { nav } from '../../utils/constants'
import { isSessionActive } from 'utils/session'

const map = [
  {
    title: i18next.t('common:footer.map.items.3.title'),
    url: i18next.t('common:footer.map.items.3.url'),
  },
  {
    title: i18next.t('common:footer.map.items.4.title'),
    url: i18next.t('common:footer.map.items.4.url'),
  },
]

export default function MobileNav() {
  const { user, logout } = useContext(AuthContext)
  const activeSession = user?.activeSession || isSessionActive()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor, i) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {nav.map((page, index) =>
          page.id === 'nav5' ? (
            <ListItem key={index} disablePadding>
              <Link to={page.slug}>
                <ListItemButton>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      style={{
                        color: '#147bc9',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={page.label} />
                </ListItemButton>
              </Link>
            </ListItem>
          ) : (
            <ListItem key={index} disablePadding>
              <ListItemButton component="a" href={page.slug}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                      color: '#147bc9',
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={page.label} />
              </ListItemButton>
            </ListItem>
          )
        )}
        <ListItem key={'auth'} disablePadding>
          <Link
            to={
              activeSession
                ? '#'
                : `/auth/login?redirect=${window.location.pathname}`
            }
            style={{ width: '100%', color: `rgb(76, 82, 87)` }}
          >
            <ListItemButton
              onClick={() => {
                if (activeSession) logout()
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  style={{
                    color: '#147bc9',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={activeSession ? 'Logout' : 'Login'} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        {['Mortgages'].map((text, index) => (
          <ListItem key={text + index} disablePadding>
            <ListItemButton
              component="a"
              href="https://my.thinkhomewise.com/m/real_estate"
              target="_blank"
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  style={{
                    color: '#147bc9',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div style={{ textAlign: `center` }}>
        <p
          style={{
            color: `#147bc9`,
            fontSize: `.75rem`,
          }}
        >
          © {new Date().getFullYear()} Homewise Real Estate, Brokerage.
          All&nbsp;rights&nbsp;reserved.
        </p>
        <p>
          {map.map((option, key) => (
            <a
              href={option.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                margin: `0 .25rem`,
                color: `#147bc9`,
                fontSize: `.75rem`,
              }}
              key={key}
            >
              {option.title}
            </a>
          ))}
        </p>
      </div>
    </Box>
  )

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {['right'].map((anchor, index) => (
        <React.Fragment key={index}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            sx={{
              marginTop: `0`,
              paddingLeft: `10px`,
              paddingRight: `10px`,
              color: `#fff`,
            }}
            key={index + 'button'}
            aria-label="Open Menu"
          >
            <MenuIcon key={index + 'icon'} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            key={index + 'drawer'}
          >
            {list(anchor, index)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
