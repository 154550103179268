import React, { useContext, useEffect, useState, useMemo } from 'react'
import { NumericFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { Link } from 'components/common/CustomLink'
import TimeAgo from 'react-timeago'

import styled from '@emotion/styled'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/system'
// import Skeleton from '@mui/material/Skeleton'
import Hidden from '@mui/material/Hidden'

// import IconButton from '@mui/material/IconButton'
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
// import FavoriteIcon from '@mui/icons-material/Favorite'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import BedIcon from '@mui/icons-material/Bed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { debounce } from '@mui/material/utils'

import placeholder from '../../assets/placeholder.png'
import pinBottom from '../../assets/pin-bottom-2.png'
import ThumbnailGallery from './ThumbnailGallery'

import { formatDetailPagePath, formatListingName } from '../../utils/formats'
import { soldStatuses, leasedStatuses } from '../../utils/constants'
import UserContext from '../../store/app/Context'
import AuthContext from '../../store/auth/authContext'

import { formatCurrency } from 'utils/formats'
import { isSessionActive } from 'utils/session'

const imageFormatOptions = new URLSearchParams({
  w: 800, // adjust as needed
  f: 'webp', // fastest format for web
})

/**
 * Map Overlay style/positioning calculations
 * @returns {Object} - style object for the overlay
 */
const getOverlayStyle = () => {
  const style = {
    position: `absolute`,
  }

  const pinBottomStyle = {
    position: `absolute`,
    top: `95%`,
    left: `50%`,
  }

  return { style, pinBottomStyle }
}

const StyledCardActionArea = styled(CardActionArea)(
  ({ theme }) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    };
`
)

const MapOverlay = (props) => {
  const navigate = useNavigate()
  const { fetchListingViaProxy } = useContext(UserContext)
  const { user } = useContext(AuthContext)
  const [activeSession, setActiveSession] = useState(
    isSessionActive() || user?.activeSession
  )
  const [listing, setListing] = useState(props?.listing)
  const setShowOverlay = props?.setShowOverlay
  const [isPublic, setIsPublic] = useState(true)
  const [loading, setLoading] = useState(true) // eslint-disable-line

  const fetch = useMemo(
    () =>
      debounce(async (request) => {
        await request()
      }, 75),
    []
  )

  useEffect(() => {
    fetch(async () => {
      setLoading(true)
      if (!props?.listing?.address) {
        const { data = null, status } = await fetchListingViaProxy(
          props?.listing?.mlsNumber,
          props?.listing?.boardId
        )
        if (status === 200) {
          setListing(data)
          setIsPublic(
            data?.permissions?.displayAddressOnInternet === 'Y' &&
              data?.permissions?.displayPublic === 'Y'
          )
        }
        setLoading(false)
      } else {
        setListing(props?.listing)
        setIsPublic(
          props?.listing?.permissions?.displayAddressOnInternet === 'Y' &&
            props?.listing?.permissions?.displayPublic === 'Y'
        )
        setTimeout(() => {
          setLoading(false)
        }, 150)
      }
    })

    // The following ensures that the map.on('move') happens (hides the overlay when maps is moved) as defined in the callback from props
    setTimeout(() => {
      props?.callback?.()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.listing])

  // ensure a re-render on listing change
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 150)
  }, [listing])

  useEffect(() => {
    if (user?.activeSession !== undefined) setActiveSession(user?.activeSession)
  }, [user?.activeSession])

  const handleClose = () => {
    setShowOverlay(false)
    navigate('/map', { replace: true })
  }

  const CloseButton = () => {
    return (
      <IconButton
        variant="outlined"
        size="small"
        sx={{
          borderRadius: '10px',
          position: 'absolute',
          zIndex: '1001',
          width: 'auto',
          alignSelf: 'center',
          justifySelf: 'center',
          top: '5px',
          right: '5px',
        }}
        onClick={handleClose}
      >
        <CancelIcon />
      </IconButton>
    )
  }

  return !isPublic && !activeSession ? (
    <Card
      className="mapOverlay"
      sx={{
        ':hover': {
          boxShadow: 20,
        },
        zIndex: 1200,
        display: 'grid',
      }}
    >
      <CloseButton />
      <img src={pinBottom} alt="pin" style={getOverlayStyle().pinBottomStyle} />
      {!isPublic && !activeSession && (
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: '10px',
            position: 'absolute',
            zIndex: '1001',
            width: 'auto',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <Link
            to={`/auth/login?redirect=${formatDetailPagePath(
              listing?.address,
              listing?.mlsNumber,
              listing?.boardId
            )}`}
            style={{ color: `white` }}
          >
            LOGIN TO VIEW
          </Link>
        </Button>
      )}
      <Box
        sx={{
          display: `flex`,
          flexGrow: 1,
          paddingBottom: `10px`,
          filter: !isPublic && !activeSession ? 'blur(5px)' : 'none',
        }}
      >
        <CardActionArea
          onClick={() => {
            navigate(
              `/auth/login?redirect=${formatDetailPagePath(
                listing?.address,
                listing?.mlsNumber,
                listing?.boardId
              )}`
            )
          }}
        >
          <CardMedia
            component="img"
            className="media"
            image={
              listing?.images?.length >= 1
                ? `https://cdn.repliers.io/${listing.images[1]}?${imageFormatOptions}`
                : placeholder
            }
            alt={listing?.address?.streetName?.substring(0, 25) || ''}
          />
        </CardActionArea>
        <StyledCardActionArea>
          <CardContent className="content">
            <div style={{ position: `relative` }}>
              <h2 style={{ marginTop: `10px` }}>
                <Link
                  to={`/auth/new-account?redirect=${formatDetailPagePath(
                    listing?.address,
                    listing?.mlsNumber,
                    listing?.boardId
                  )}`}
                  className="link"
                >
                  Join
                </Link>
                &nbsp;or&nbsp;
                <Link
                  to={`/auth/login?redirect=${formatDetailPagePath(
                    listing?.address,
                    listing?.mlsNumber,
                    listing?.boardId
                  )}`}
                  className="link"
                >
                  Login
                </Link>
                &nbsp;to view.
              </h2>
              <h4 className="truncate">
                <span className="highlight">
                  {formatListingName(listing?.address)?.substring(0, 25)}
                </span>
              </h4>
              <h5>
                {listing?.address?.city?.substring(0, 100)},{' '}
                {listing?.address?.area?.substring(0, 100)}{' '}
                {listing?.address?.neighborhood &&
                  '- ' + listing?.address?.neighborhood?.substring(0, 100)}
              </h5>
              {listing?.details?.numBedrooms && (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={'- Beds'}
                  icon={<BedIcon />}
                  sx={{ border: `0px` }}
                />
              )}
              {listing?.details?.numBathrooms && (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={'- Baths'}
                  icon={<BathtubIcon />}
                  sx={{ border: `0px` }}
                />
              )}
              <Hidden mdDown>
                {listing?.details?.numGarageSpaces && (
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={'- Garage'}
                    icon={<DirectionsCarIcon />}
                    sx={{ border: `0px` }}
                  />
                )}
              </Hidden>
            </div>
            <Stack
              direction="row"
              sx={{ width: `100%`, paddingBottom: `10px` }}
              justifyContent="space-between"
            >
              {listing.type === 'Sale' &&
                (soldStatuses.includes(listing.lastStatus) ? (
                  <Chip label="Sold" color="sold" size="small" />
                ) : (
                  <Chip label="For Sale" color="forSale" size="small" />
                ))}
              {listing.type === 'Lease' &&
                (leasedStatuses.includes(listing.lastStatus) ? (
                  <Chip label="Leased" color="sold" size="small" />
                ) : (
                  <Chip label="For Lease" color="forLease" size="small" />
                ))}
              {listing.listDate && (
                <Chip
                  label={<TimeAgo date={listing.listDate} />}
                  color="neutral"
                  size="small"
                />
              )}
              <Hidden mdDown>
                {listing?.details?.virtualTourUrl && (
                  <Chip label="Virtual Tour" color="primary" size="small" />
                )}
              </Hidden>
            </Stack>
          </CardContent>
        </StyledCardActionArea>
      </Box>
    </Card>
  ) : (
    // full data
    <Card
      className="mapOverlay"
      sx={{
        ':hover': {
          boxShadow: 20,
        },
        zIndex: 1200,
        display: 'block',
        // TODO: following attempt to fix the overlay when side-results section is open
        // minWidth: `calc(100% - 80px)`,
        // borderRadius: `12px 12px 0 0 !important`,
        // top: 'auto',
        // bottom: `0px`,
        // left: `0px`,
      }}
    >
      <CloseButton />
      <img src={pinBottom} alt="pin" style={getOverlayStyle().pinBottomStyle} />
      <Box
        sx={{
          display: `flex`,
          flexGrow: 1,
          paddingBottom: `10px`,
        }}
      >
        <ThumbnailGallery data={listing} isOverlay={true} />
        <StyledCardActionArea
          onClick={() => {
            navigate(
              formatDetailPagePath(
                listing?.address,
                listing?.mlsNumber,
                listing?.boardId
              )
            )
          }}
        >
          <CardContent className="content">
            <div style={{ position: `relative` }}>
              {listing.lastStatus === 'Sld' ? (
                <>
                  <h2 style={{ fontFamily: `MerriWeather`, marginTop: `10px` }}>
                    <span
                      style={{
                        color: `#333`,
                        fontFamily: `Inter`,
                        fontSize: `1.25rem`,
                      }}
                    >
                      Sold:
                    </span>{' '}
                    <span style={{ color: `#AA482F` }}>
                      {formatCurrency(parseFloat(listing.soldPrice))}
                    </span>
                  </h2>
                  <h3
                    style={{
                      color: `#666`,
                      fontFamily: `Inter`,
                      fontSize: `1rem`,
                      marginTop: `0px`,
                    }}
                  >
                    Listed:{' '}
                    <span style={{ textDecoration: `line-through` }}>
                      {formatCurrency(parseFloat(listing.listPrice))}
                    </span>
                  </h3>
                </>
              ) : (
                listing.listPrice && (
                  <h2 style={{ marginTop: `10px` }}>
                    <NumericFormat
                      value={listing.listPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      allowNegative={false}
                    />
                  </h2>
                )
              )}
              <h4 className="truncate">
                <span className="highlight">
                  {formatListingName(listing?.address)?.substring(0, 25)}
                </span>
              </h4>
              <h5>
                {listing?.address?.city?.substring(0, 100)},{' '}
                {listing?.address?.area?.substring(0, 100)}{' '}
                {listing?.address?.neighborhood &&
                  '- ' + listing?.address?.neighborhood?.substring(0, 100)}
              </h5>
              {/* <IconButton
                  aria-label="Save"
                  color="primary"
                  sx={{ position: `absolute`, top: 0, right: 0 }}
                >
                  {listing.saved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton> */}
              {listing?.details?.numBedrooms && (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={listing?.details?.numBedrooms + ' Beds'}
                  icon={<BedIcon />}
                  sx={{ border: `0px` }}
                />
              )}
              {listing?.details?.numBathrooms && (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={listing.details.numBathrooms + ' Baths'}
                  icon={<BathtubIcon />}
                  sx={{ border: `0px` }}
                />
              )}
              <Hidden mdDown>
                {listing?.details?.numGarageSpaces && (
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={
                      parseFloat(listing.details.numGarageSpaces) + ' Garage'
                    }
                    icon={<DirectionsCarIcon />}
                    sx={{ border: `0px` }}
                  />
                )}
              </Hidden>
            </div>
            <Stack
              direction="row"
              sx={{ width: `100%`, paddingBottom: `10px` }}
              justifyContent="space-between"
            >
              {listing.type === 'Sale' &&
                (soldStatuses.includes(listing.lastStatus) ? (
                  <Chip
                    label={
                      listing?.lastStatus === 'Sc' ? 'Sold Conditional' : 'Sold'
                    }
                    color="sold"
                    size="small"
                  />
                ) : (
                  <Chip label="For Sale" color="forSale" size="small" />
                ))}
              {listing.type === 'Lease' &&
                (leasedStatuses.includes(listing.lastStatus) ? (
                  <Chip label="Leased" color="sold" size="small" />
                ) : (
                  <Chip label="For Lease" color="forLease" size="small" />
                ))}
              {listing.listDate && (
                <Chip
                  label={<TimeAgo date={listing.listDate} />}
                  color="neutral"
                  size="small"
                />
              )}
              <Hidden mdDown>
                {listing?.details?.virtualTourUrl && (
                  <Chip label="Virtual Tour" color="primary" size="small" />
                )}
              </Hidden>
            </Stack>
          </CardContent>
        </StyledCardActionArea>
      </Box>
    </Card>
  )
}

export default MapOverlay
