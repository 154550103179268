import React, { Component } from 'react'
import parse from 'parse-link-header'
import i18next from '../../i18n'

import Config from '../../config'
import UserContext from './Context'
import customerApi from '../../api/customer'
import realtyApi from '../../api/realty'
import { parseListingsApiData } from '../../utils/parseListingsApiData'
import { getLocationsFromIDB } from 'utils/getLocations'
import { setSessionActive, setSessionInactive } from 'utils/session'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const poolData = {
  UserPoolId: Config.cognito.USER_POOL_ID,
  ClientId: Config.cognito.APP_CLIENT_ID,
  Storage: new AmazonCognitoIdentity.CookieStorage({
    // wildcard domain for cross-subdomain cookies
    domain: Config.cookieDomain,
    secure: process.env.NODE_ENV === 'production',
  }),
}
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

class Provider extends Component {
  abortController = new AbortController()

  state = {
    appId: '',
    userId: '',
    auth: {},
    appLoading: true,
    schema: {},
    isError: false,
    errMessage: '',

    /**
     * Returns schema links from the customer API.
     * Sets the schema state.
     * @returns {null} null
     */
    getSchema: async () => {
      try {
        let isSchemaEmpty = Object.keys(this.state.schema).length <= 0

        if (isSchemaEmpty) {
          const res = await customerApi.getSchema()

          if (res.status <= 202) {
            const header = res.headers['link'],
              parsed = parse(header),
              urlsObject = Object.entries(parsed)

            urlsObject.forEach(([key, value]) => {
              this.setState({
                ...this.state,
                schema: {
                  ...this.state.schema,
                  [value.rel]: value.url,
                },
              })
            })

            this.setState({ appLoading: false })
          } else if (res.status >= 400) throw new Error(res.statusText)
        } else this.setState({ appLoading: false })
      } catch (e) {
        console.log('getSchema error: ', e)

        this.setState({
          appLoading: false,
          isError: true,
          errMessage: 'There has been an error at (getSchema).',
        })
      }
    },

    /**
     * Returns the session from the cognito user pool.
     * Sets the session state with auth info.
     * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
     * @returns {null} null
     */
    getSession: async (from) => {
      try {
        let cognitoUser = userPool.getCurrentUser()

        if (cognitoUser === null)
          throw new Error(
            'Unable to retrieve user info (getSession)), cognitoUser is null.'
          )

        await cognitoUser.getSession((err, session) => {
          if (err) throw new Error(err)

          this.setState({
            ...this.state,
            auth: {
              activeSession: true,
              jwtToken: session.idToken.jwtToken,
            },
          })

          setSessionActive()

          switch (from) {
            case 'login':
              window.location.reload()
              break
            case 'register':
              window.location.reload()
              break
            case 'appMount':
              this.state.getSchema()
              break
            default:
              break
          }

          return true
        })
      } catch (e) {
        // console.log('getSession Error: ', e);

        this.setState({
          ...this.state,
          activeSession: false,
          appLoading: false,
        })

        setSessionInactive()

        return false
      }
    },

    /**
     * Refreshes the user session.
     * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
     * @returns {null} null
     */
    getRefreshedSession: async (from) => {
      try {
        let cognitoUser = userPool.getCurrentUser()

        if (cognitoUser === null) {
          if (from === 'app') return
          else
            throw new Error(
              'Unable to retrieve user info (getRefreshedSession)), cognitoUser is null.'
            )
        }

        await cognitoUser.getSession((err, session) => {
          if (err) throw new Error(err)

          let refresh_token = session.getRefreshToken()

          cognitoUser.refreshSession(refresh_token, (e, refreshedSession) => {
            if (e) throw new Error(e)

            this.setState({
              ...this.state,
              auth: {
                activeSession: true,
                jwtToken: refreshedSession.idToken.jwtToken,
              },
            })

            setSessionActive()
          })

          return true
        })
      } catch (e) {
        console.log('getRefreshedSession Error: ', e)

        this.setState({
          ...this.state,
          activeSession: false,
          appLoading: false,
        })

        setSessionInactive()

        return false
      }
    },

    /**
     * Ends the user session with the cognito user pool.
     * @returns {null} null
     */
    endSession: (callback) => {
      try {
        let cognitoUser = userPool.getCurrentUser()

        if (cognitoUser === null)
          throw new Error(
            'Unable to retrieve user info (endSession)), cognitoUser is null.'
          )

        cognitoUser.signOut()

        this.setState({
          ...this.state,
          auth: {},
        })

        try {
          // Clear all data from localStorage & sessionStorage.
          sessionStorage.clear()
          localStorage.clear()
          setSessionInactive()
        } catch (e) {
          console.log('LocalStorage/SessionStorage Error')
        }

        setTimeout(callback, 100)
      } catch (e) {
        console.log('endSession error: ', e)
      }
    },

    /**
     * Authenticate the user with the cognito user pool.
     * @param email - The user's email.
     * @param password - The user's password.
     * @param setErrors - A callback function to set the errors in the component state.
     * @param setButtonLoading - A callback function to set the button loading state in the component state.
     * @param callBack - A callback function to be called after the user is authenticated.
     * @param redirect - A callback function to redirect a user after the authentication.
     * @returns {null} null
     */
    authenticate: (
      email,
      password,
      setErrors,
      setButtonLoading,
      callback,
      redirect
    ) => {
      try {
        let authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password,
          })

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: email,
          Pool: userPool,
          Storage: new AmazonCognitoIdentity.CookieStorage({
            // wildcard domain for cross-subdomain cookies
            domain: Config.cookieDomain,
            secure: process.env.NODE_ENV === 'production',
          }),
        })

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            this.setState({
              ...this.state,
              auth: {
                activeSession: true,
                jwtToken: result.idToken.jwtToken,
              },
            })

            setSessionActive()

            if (callback) {
              if (callback === 'register') this.state.getSession('register')
              else callback('login')
            }

            return true
          },

          onFailure: (err) => {
            const authError = err.message
            setButtonLoading(false)

            switch (err.code) {
              case 'UserNotConfirmedException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                }))
                break
              case 'UserNotFoundException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                }))
                break
              case 'NotAuthorizedException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                  password: {
                    invalid: true,
                    error: '',
                  },
                }))
                break
              case 'ResourceNotFoundException':
                setErrors((errors) => ({
                  ...errors,
                  masterError: i18next.t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                  masterInvalid: true,
                }))
                break
              case 'PasswordResetRequiredException':
                callback()
                redirect()
                break
              case 'LimitExceededException':
                setErrors((errors) => ({
                  ...errors,
                  masterError: i18next.t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                  masterInvalid: true,
                }))
                break
              default:
                setErrors((errors) => ({
                  ...errors,
                  masterError: authError,
                  masterInvalid: true,
                }))
                break
            }

            setErrors((errors) => ({
              ...errors,
              masterError: authError,
              masterInvalid: true,
            }))

            return
          },

          newPasswordRequired: (authenticationDetails) => {
            delete authenticationDetails.email_verified
            delete authenticationDetails.phone_number_verified

            cognitoUser.completeNewPasswordChallenge(
              password,
              authenticationDetails,
              this
            )
          },
        })
      } catch (e) {
        console.log('authenticate error: ', e)
        return
      }
    },

    /**
     * Fetches the data for a particular user from the customer API.
     * @returns {Object} The data for the user.
     */
    getUser: async () => {
      try {
        if (!this.state.auth.jwtToken) this.state.getSession()

        const res = await customerApi.getUser(this.state.auth.jwtToken)

        if (res.status <= 202) return res.data
        else if (res.status >= 400) throw new Error(res.statusText)
      } catch (e) {
        console.log('getUser error: ', e)
        return
      }
    },

    /**
     * Creates a new user in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    createUser: async (data) => {
      try {
        const res = await customerApi.createUser(data)

        if (res.status <= 202) {
          this.setState({
            ...this.state,
            userId: res.data.id,
          })

          try {
            localStorage.removeItem('userId')
            localStorage.setItem('userId', `${res.data.id}`)
          } catch (e) {
            console.log('LocalStorage Error')
          }

          return res.status
        } else if (res.status >= 400) throw new Error(res.statusText)
      } catch (e) {
        console.log('createUser error: ', e)
        return 400
      }
    },

    /**
     * Updates a user in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    updateUser: async (data) => {
      try {
        if (!this.state.auth.jwtToken) this.state.getSession()

        const res = await customerApi.updateUser(data, this.state.auth.jwtToken)

        if (res.status <= 202) {
          return res.status
        } else if (res.status >= 400) throw new Error(res.statusText)
      } catch (e) {
        console.log('updateUser error: ', e)
        return 400
      }
    },

    /**
     * Validates a email address.
     * @param {String} data - The email to validate.
     * @param {String} type - The type of email address to validate, possible values, 'primary', 'other'.
     * @returns {Object} data - The validation result.
     */
    checkEmail: async (data, type) => {
      try {
        const res = await customerApi.checkEmail(data, type)

        if (res.status <= 202) return res.data
        else if (res.status === 404) return res.data
        // This is a valid response for a non-existing email.
        else if (res.status >= 400) throw new Error(res.statusText)
      } catch (e) {
        console.log('checkEmail error: ', e)
        return
      }
    },

    /**
     * Provider method for fetching map pins data
     * @param {{ data:listingData, params }} reqData
     * `{ data: listingData, params }`
     * @param {Boolean} cancellable - Whether or not to cancel the previous request
     * @return {{ data: [listingData], count: Number }}
     * `{ data: [listingData], count: Number }`
     */
    fetchMapPinsListings: async (reqData, cancellable = true) => {
      try {
        // Cancel previous request if a new one is made using abortController
        if (cancellable) this.abortController.abort()

        // Create a new abortController
        this.abortController = new AbortController()

        const res = await realtyApi.search(
          reqData,
          {},
          {
            signal: this.abortController.signal,
          }
        )

        const parsedRes = parseListingsApiData(res)

        return parsedRes
      } catch (error) {
        // showToast('api.mapPins.error', 'error')
        console.error('fetchMapPinsListings error: ', error)
      }
    },

    /**
     * Provider method for fetching a single listing
     * @param {String} mlsId
     * @param {String} boardId
     * @param {Object} params
     * @return {Object} listingData
     */
    fetchListing: async (mlsId, boardId, params) => {
      try {
        const res = await realtyApi.getListing(mlsId, boardId, params)
        return res
      } catch (error) {
        // showToast('api.listing.error', 'error')
        console.error('fetchListing error: ', error)
      }
    },

    /**
     * Provider method for fetching a single listing via the proxy
     * @param {String} mlsId
     * @param {String} boardId
     * @param {Object} params
     * @return {Object} listingData
     */
    fetchListingViaProxy: async (mlsId, boardId, params) => {
      try {
        const res = await realtyApi.getListingViaProxy(mlsId, boardId, params)
        return res
      } catch (error) {
        // showToast('api.listing.error', 'error')
        console.error('fetchListingViaProxy error: ', error)
      }
    },

    /**
     * Provider method for fetching nearby, popular city & recent listings suggestions based on an MLS ID
     * @param {String} mlsId
     * @param {String} boardId
     * @return {Object} suggestions
     */
    fetchSuggestions: async (mlsId, boardId) => {
      try {
        const res = await realtyApi.getSuggestions(mlsId, boardId)
        return res
      } catch (error) {
        console.error('fetchSearchSuggestions error: ', error)
      }
    },

    /**
     * Provider method for autocomplete search
     * @param {String} query
     * @return {Object} suggestions
     */
    autocompleteSearch: async (query) => {
      try {
        const params = {
          search: query,
          searchFields:
            'address.streetName,address.streetNumber,address.neighborhood,address.city,mlsNumber',
          fields:
            'address,mlsNumber,map,type,boardId,permissions.displayAddressOnInternet,permissions.displayPublic,lastStatus,images[1],listPrice,listDate,details.numBedrooms,,details.numGarageSpaces,details.numBathrooms',
          resultsPerPage: 5,
          aggregates: 'address.neighborhood,address.city',
        }

        const res = await realtyApi.autocomplete(params)

        if (res.status === 200) {
          return res.data
        } else {
          throw new Error('Error fetching autocomplete data')
        }
      } catch (error) {
        console.error('autocompleteSearch error: ', error)
        return null
      }
    },

    /**
     * Provider method for fetching locations based on a search query
     * @param {String} query
     * @return {Object} locations
     */
    fetchLocations: async (query) => {
      try {
        // Try getLocationsFromIDB first, if it fails, fetch from API
        const locations = await getLocationsFromIDB(query)

        if (
          locations &&
          (locations?.search?.cities?.length > 0 ||
            locations?.search?.neighborhoods?.length > 0)
        )
          return locations

        const res = await realtyApi.getLocations({ search: query })

        if (res.status === 200) return res.data
        else throw new Error('Error fetching locations data from API')
      } catch (error) {
        console.error('fetchLocations error: ', error)
        return null
      }
    },

    /**
     * Provider method to fetch polygon data for a given location
     * @param {String} location
     * @param {String} type
     * @return {Object} polygonData
     */
    fetchPolygonData: async (location, type) => {
      try {
        const params = {}

        if (type === 'city') params.city = location
        else if (type === 'neighborhood') params.neighborhood = location

        const res = await realtyApi.getLocations(params)

        if (res.status === 200) {
          // in the res.boards array, use the object with boardId 2(TRREB), if not exit (CREA doesn't have polygon data)
          const board = res?.data?.boards?.find((board) => board.boardId === 2)

          if (!board) throw new Error('No polygon data found for: ', location)
          else {
            // From all the classes in the board, use the one that has 1 or more areas
            const classWithAreas = board.classes.find(
              (classItem) => classItem.areas.length > 0
            )

            if (!classWithAreas)
              throw new Error('No polygon data found for: ', location)

            // For each class from all the areas in the class, use the one whose area.name matches the location
            const area = classWithAreas.areas[0]

            if (!area) throw new Error('No polygon data found for: ', location)

            // based on the type, if it's a city, then use the areas.cities array, if not, use the areas.neighborhoods array
            if (area.cities.length === 0)
              throw new Error('No polygon data found for: ', location)
            else {
              if (type === 'city') {
                let city

                // for each city, if the city.name matches the location, then use that city
                for (let i = 0; i < area.cities.length; i++) {
                  if (area.cities[i].name === location) {
                    city = area.cities[i]
                    break
                  }
                }

                if (!city)
                  throw new Error('No polygon data found for: ', location)

                // if the city has coordinates[0] & the length is greater than 2, then use the coordinates
                if (city.coordinates && city.coordinates[0].length > 2)
                  return city.coordinates
              } else if (type === 'neighborhood') {
                let neighborhood

                // for each neighborhood in the first city, if the neighborhood.name matches the location, then use that neighborhood
                for (let i = 0; i < area.cities[0].neighborhoods.length; i++) {
                  if (area.cities[0].neighborhoods[i].name === location) {
                    neighborhood = area.cities[0].neighborhoods[i]
                    break
                  }
                }

                if (!neighborhood)
                  throw new Error('No polygon data found for: ', location)

                // if the neighborhood has coordinates[0] & the length is greater than 2, then use the coordinates
                if (
                  neighborhood.coordinates &&
                  neighborhood.coordinates[0].length > 2
                )
                  return neighborhood.coordinates
              }
            }
          }
        } else {
          throw new Error('No data found in the boards array')
        }
      } catch (error) {
        console.error('fetchPolygonData error: ', error)
        return null
      }
    },

    /**
     * Provider method for fetching a single listing's history
     * @param {String} token
     * @param {Object} params
     */
    fetchListingHistory: async (token, params) => {
      try {
        const res = await realtyApi.getListingHistory(token, params)
        return res
      } catch (error) {
        // showToast('api.listing.error', 'error')
        console.error('fetchListingHistory error: ', error)
      }
    },
  }

  /**
   * Verifies Cognito account status code entered by user.
   * @param {String} email
   * @param {String} userCode
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  verifyCode = (email, userCode, setErrors, setButtonLoading, callback) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })

      cognitoUser.confirmRegistration(userCode, true, (err, result) => {
        if (err) {
          const errors = {
            userCodeError: '',
            userCodeInvalid: false,
          }

          if (err.code === 'CodeMismatchException') {
            errors.userCodeError = err.message
            errors.userCodeInvalid = true
            setErrors({ ...errors })
          }
          if (err.code === 'NotAuthorizedException') {
            errors.userCodeError = err.message
            errors.userCodeInvalid = true
            setErrors({ ...errors })
          }
          setButtonLoading(false)
          return
        } else {
          callback()
          return
        }
      })
    } catch (e) {
      console.log('verifyCode error: ', e)
      return
    }
  }

  /**
   * Resets Cognito account password.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @param {Function} callbackOnFail
   */
  resetPassword = async (
    email,
    setErrors,
    setButtonLoading,
    callback,
    callbackOnFail
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })

      cognitoUser.forgotPassword({
        onSuccess: function (result) {},
        onFailure: function (err) {
          if (err.code === 'LimitExceededException') {
            setErrors((errors) => ({
              ...errors,
              masterError: i18next.t('authErrors|' + err.code, {
                nsSeparator: '|',
              }),
              masterInvalid: true,
            }))
          } else {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
              },
            }))
          }
          setButtonLoading(false)
          callbackOnFail()
        },
        inputVerificationCode() {
          // var verificationCode = prompt("Please input verification code ", "");
          // var newPassword = prompt("Enter new password ", "");
          // cognitoUser.confirmPassword(verificationCode, newPassword, this);
          callback()
        },
      })
    } catch (e) {
      console.log('resetPassword error: ', e)
      return
    }
  }

  /**
   * Confirms Cognito account password.
   * @param {String} email
   * @param {String} verificationCode
   * @param {String} newPassword
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  confirmPassword = async (
    email,
    verificationCode,
    newPassword,
    setErrors,
    setButtonLoading,
    callback
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })
      return new Promise(() => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onFailure(err) {
            if (
              err.code === 'InvalidPasswordException' ||
              err.code === 'UserNotFoundException'
            ) {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.password.pattern'),
                },
              }))
            }
            if (
              err.code === 'CodeMismatchException' ||
              err.code === 'ExpiredCodeException'
            ) {
              setErrors((errors) => ({
                ...errors,
                code: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.code.' + err.code),
                },
              }))
            }
            if (err.code === 'InvalidParameterException') {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.password.pattern'),
                },
                code: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.code.pattern'),
                },
              }))
            }
            // if (err.code === 'InvalidLambdaResponseException') {
            //   console.log(err);
            // }
            setButtonLoading(false)
          },
          onSuccess: () => {
            this.state.authenticate(
              email,
              newPassword,
              setErrors,
              setButtonLoading,
              callback
            )
            return
          },
        })
      })
    } catch (e) {
      console.log('confirmPassword error: ', e)
      return
    }
  }

  /**
   * Resends Cognito account verification code.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns
   */
  resendCode = async (email, setErrors, setButtonLoading, callback) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
      })
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          const authError = err.message
          setButtonLoading(false)
          if (err.code === 'UserNotFoundException') {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
              },
            }))
          } else {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + authError, {
                  nsSeparator: '|',
                }),
              },
            }))
          }
          return
        }
        callback()
      })
    } catch (e) {
      console.log('resendCode error: ', e)
      return
    }
  }

  render() {
    const { children } = this.props
    const {
      auth,
      schema,
      appLoading,
      isError,
      errMessage,
      getSchema,
      getSession,
      getRefreshedSession,
      getUser,
      endSession,
      authenticate,
      createUser,
      updateUser,
      checkEmail,
      fetchMapPinsListings,
      fetchListing,
      fetchListingViaProxy,
      fetchSuggestions,
      autocompleteSearch,
      fetchLocations,
      fetchPolygonData,
      fetchListingHistory,
    } = this.state
    const { verifyCode, resetPassword, confirmPassword, resendCode } = this

    return (
      <UserContext.Provider
        value={{
          auth,
          schema,
          appLoading,
          isError,
          errMessage,
          getSession,
          getRefreshedSession,
          getUser,
          endSession,
          authenticate,
          verifyCode,
          getSchema,
          resetPassword,
          confirmPassword,
          resendCode,
          createUser,
          updateUser,
          checkEmail,
          fetchMapPinsListings,
          fetchListing,
          fetchListingViaProxy,
          fetchSuggestions,
          autocompleteSearch,
          fetchLocations,
          fetchPolygonData,
          fetchListingHistory,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export { Provider as UserProvider }
