import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import FavoriteIcon from '@mui/icons-material/Favorite'
import MapIcon from '@mui/icons-material/Map'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import AppsIcon from '@mui/icons-material/Apps'

export default function MobileNav(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const { currentTab, setCurrentTab } = props

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setCurrentTab('savedHomes')
            }}
          >
            <ListItemIcon>
              <FavoriteIcon
                color={currentTab === 'savedHomes' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={'Saved Homes'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setCurrentTab('savedSearches')
            }}
          >
            <ListItemIcon>
              <MapIcon
                color={currentTab === 'savedSearches' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={'Saved Searches'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setCurrentTab('accountDetails')
            }}
          >
            <ListItemIcon>
              <NoteAltIcon
                color={currentTab === 'accountDetails' ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={'Account Details'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="primaryBtn"
            aria-label="Open Menu"
            endIcon={<AppsIcon />}
          >
            Menu
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
