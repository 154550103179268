import React from 'react'
import { Link } from 'react-router-dom'

import i18next from '../../i18n'
import { withTranslation } from 'react-i18next'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import logo from '../../assets/white-logo.png'

const start = [
  {
    title: i18next.t('common:footer.start.items.1.title'),
    url: i18next.t('common:footer.start.items.1.url'),
  },
  {
    title: i18next.t('common:footer.start.items.2.title'),
    url: i18next.t('common:footer.start.items.2.url'),
  },
]

const about = [
  {
    title: i18next.t('common:footer.about.items.1.title'),
    url: i18next.t('common:footer.about.items.1.url'),
  },
  {
    title: i18next.t('common:footer.about.items.2.title'),
    url: i18next.t('common:footer.about.items.2.url'),
  },
  {
    title: i18next.t('common:footer.about.items.3.title'),
    url: i18next.t('common:footer.about.items.3.url'),
  },
]

const contact = [
  {
    title: i18next.t('common:footer.contact.items.1.title'),
    url: i18next.t('common:footer.contact.items.1.url'),
  },
  {
    title: i18next.t('common:footer.contact.items.2.title'),
    url: i18next.t('common:footer.contact.items.2.url'),
  },
  {
    title: i18next.t('common:footer.contact.items.3.title'),
    url: i18next.t('common:footer.contact.items.3.url'),
  },
]

const connect = [
  {
    title: i18next.t('common:footer.connect.items.1.title'),
    url: i18next.t('common:footer.connect.items.1.url'),
  },
  {
    title: i18next.t('common:footer.connect.items.2.title'),
    url: i18next.t('common:footer.connect.items.2.url'),
  },
  {
    title: i18next.t('common:footer.connect.items.3.title'),
    url: i18next.t('common:footer.connect.items.3.url'),
  },
]

const Footer = (props) => {
  // const isDev = process.env.REACT_APP_STAGE === 'dev' ? true : false;
  const { t } = props

  return (
    <footer>
      <AppBar position="static" className="ftr">
        <Toolbar>
          <Container
            component="main"
            maxWidth="lg"
            style={{ paddingBottom: `5rem` }}
          >
            <Grid
              container
              spacing={3}
              style={{ justifyContent: `space-between` }}
            >
              <Grid item xs={12} sm={12} md={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="ftrList">
                      <p>{t('footer.start.title')}</p>
                      <ul>
                        {start.map((option, key) => (
                          <li key={key} style={{ marginBottom: `0px` }}>
                            <a
                              href={option.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {option.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="ftrList">
                      <p>{t('footer.about.title')}</p>
                      <ul>
                        {about.map((option, key) => (
                          <li key={key} style={{ marginBottom: `0px` }}>
                            <a
                              href={option.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {option.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="ftrList">
                      <p>{t('footer.contact.title')}</p>
                      <ul>
                        {contact.map((option, key) => (
                          <li key={key} style={{ marginBottom: `0px` }}>
                            <a
                              href={option.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {option.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="ftrList">
                      <p>{t('footer.connect.title')}</p>
                      <ul>
                        {connect.map((option, key) => (
                          <li key={key} style={{ marginBottom: `0px` }}>
                            <a
                              href={option.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {option.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} style={{ textAlign: `center` }}>
                <Grid item xs={12}>
                  <Link to="/" style={{ height: `75px` }}>
                    <img
                      src={logo}
                      style={{ marginBottom: `2rem` }}
                      alt="Homewise"
                      height="75"
                      width="167"
                    />
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: `#ccc` }} />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    <small>
                      <strong>Don't overpay for your&nbsp;mortgage!</strong>
                    </small>
                  </p>
                  <p>
                    <small>
                      Our team can help you save thousands by shopping around to
                      30+ banks and lenders to secure the
                      best&nbsp;rates&nbsp;and&nbsp;features.
                    </small>
                  </p>
                  <Button
                    variant="contained"
                    href="https://my.thinkhomewise.com/m/real_estate"
                    target="_blank"
                  >
                    Get Pre-Approved Today!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>

        <Toolbar style={{ paddingTop: `0`, paddingBottom: `2rem` }}>
          <Container component="main" maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider style={{ background: `#ccc` }} />
              </Grid>
              <Grid item xs={12}>
                <p>
                  <small>
                    © {new Date().getFullYear()} Homewise Real Estate,
                    Brokerage. All&nbsp;rights&nbsp;reserved.
                  </small>
                </p>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </footer>
  )
}

export default withTranslation('common')(Footer)
