import React, { useContext, useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'

import axios from 'axios'
import Config from 'config'
import parse from 'parse-link-header'

import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'

import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import logo from '../../assets/HWRE-logo-colour.png'
import AuthContext from '../../store/auth/authContext'

import Wrapper from './Wrapper'
import ResetPasswordDialog from 'components/common/ResetPasswordDialog'

export default function ResetPassword() {
  const context = useContext(AuthContext)
  const { resetPassword, getSession, user } = context
  const [schemaUrl, setSchemaUrl] = useState('')
  const schema = useSchema(schemaUrl)

  // const [searchParams] = useSearchParams()
  const [errors, setErrors] = useState({})
  const [buttonLoading, setButtonLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [verifyDialog, setVerifyDialog] = useState(false)

  // const redirect = searchParams?.get('redirect')
  const [t] = useTranslation()

  const resetPasswordCallback = () => {
    setVerifyDialog(true)
  }

  const is_email = (email) => {
    let minRegex = new RegExp(
      /^([^<>()[\],;:\s@"]+)@([^<>()[\],;:\s@"]+\.)+[a-zA-Z]{2,4}$/
    )
    return minRegex.test(email)
  }

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.CUSTOMER + `/meta?group=homewise`)
      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      // console.log(urlsObject)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'authEmail') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      setButtonLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !schemaUrl.length

    if (isSchemaEmpty) {
      getSchema()
      isSchemaEmpty = false
    }
  }, [schemaUrl])

  const handleSubmit = async (event) => {
    event.preventDefault()

    setButtonLoading(true)
    setErrors({})
    try {
      // Temporary is_email check
      const seemsEmaily = is_email(email)

      if (seemsEmaily) {
        const payload = {
          email: email,
        }

        const err = validateSchema(schema, payload, 'errors')
        if (!err) {
          resetPassword(
            email,
            setErrors,
            setButtonLoading,
            resetPasswordCallback,
            () => {
              console.error('password reset fail')
            }
          )
        } else {
          setButtonLoading(false)
          console.log('error', err)
        }
      } else {
        console.log('email is not valid')
        setButtonLoading(false)
        setErrors({
          email: t('errors:email.format'),
        })
      }
    } catch (err) {
      console.log('resetPassword', err)
      setButtonLoading(false)
    }
  }

  const handleClose = () => {
    getSession()
    setButtonLoading(false)
    setVerifyDialog(false)
  }

  if (!user?.activeSession) {
    return (
      <Wrapper>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width="220" alt="Homewise Real Estate" />
          <h1
            style={{
              margin: `2rem auto`,
              textAlign: `center`,
              fontSize: `1.8rem`,
            }}
          >
            Verify your <span className="highlight">Email</span>
          </h1>
          <p style={{ textAlign: `center` }}>
            Enter the email address associated with your Homewise account.
          </p>
          {errors.masterInvalid && (
            <p style={{ color: `red` }}>
              <small>{errors.masterError}</small>
            </p>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: `100%` }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  size="small"
                  sx={{ backgroundColor: 'white' }}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    if (errors.email) {
                      delete errors.email
                    }
                  }}
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ display: `block`, margin: `2rem auto` }}
            >
              {!buttonLoading ? (
                <span>Verify</span>
              ) : (
                <CircularProgress
                  size={24}
                  sx={{
                    color: `#ffffff`,
                  }}
                />
              )}
            </Button>
            <Grid container>
              <Grid item xs style={{ textAlign: `center` }}>
                <Link
                  to={`/auth/login${window?.location?.search}`}
                  variant="body2"
                  className="link"
                >
                  Login
                </Link>
                <br />
                <br />
                <Link
                  to={`/auth/new-account${window?.location?.search}`}
                  variant="body2"
                  className="link"
                >
                  {'Create an account'}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ResetPasswordDialog
          open={verifyDialog}
          email={email}
          handleClose={handleClose}
        />
      </Wrapper>
    )
  } else {
    return <Navigate to="/" />
  }
}
