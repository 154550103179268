import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import parse from 'parse-link-header'
import { SnackbarProvider } from 'notistack'

import Notifier from 'components/common/Notifier'

import {
  Grid,
  Container,
  Button,
  TextField,
  CircularProgress,
  Slide,
} from '@mui/material'
import axios from 'axios'

import Config from 'config'

import NumberFormatDollars from 'utils/priceNumberFormat'
import NumberFormatPhone from 'utils/phoneNumberFormat'
import { useSchema } from 'utils/hooks/useSchema'
import { validateSchema } from 'utils/validateSchema'

const LeadCapture = (props) => {
  const { t, title, subtitle } = props

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [thankyou, setThankyou] = useState(false)
  const [schemaUrl, setSchemaUrl] = useState('')

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [city, setCity] = useState('')
  const [price, setPrice] = useState('')
  const [refCode, setRefCode] = useState('')

  const schema = useSchema(schemaUrl)

  const getSchema = async () => {
    try {
      const res = await axios.get(Config.api.REALTY + `/meta?group=homewise`)

      const header = res?.headers?.link
      const parsed = parse(header)
      const urlsObject = Object.entries(parsed)
      urlsObject.forEach(([key, value]) => {
        if (value.rel === 'user') {
          setSchemaUrl(value.url)
        }
      })
    } catch (error) {
      // showToast('api.getSchema.error', 'warning')
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let isSchemaEmpty = !Object.keys(schemaUrl).length

    if (isSchemaEmpty) {
      getSchema()
    }
  }, [schemaUrl])

  const is_email = (email) => {
    let minRegex = new RegExp(
      /^([^<>()[\],;:\s@"]+)@([^<>()[\],;:\s@"]+\.)+[a-zA-Z]{2,4}$/
    )
    return minRegex.test(email)
  }

  const pushLead = async (event) => {
    event.preventDefault()

    setLoading(true)
    setErrors({})

    // Temporary is_email check
    const seemsEmaily = is_email(email)

    if (seemsEmaily) {
      let data = {
        user: {
          name: {
            first: firstname.trim(),
            last: lastname.trim(),
          },
          email: email,
          phone: phoneNumber,
        },
        property: {
          city: city,
          price: parseFloat(price),
        },
      }

      if (refCode) {
        data.code = refCode
      }

      // console.log(schema, data);
      let isSchemaEmpty = !Object.keys(schemaUrl).length

      const err = isSchemaEmpty
        ? true
        : validateSchema(t, schema, data, 'errors')
      // const err = false;

      if (!err) {
        try {
          const headers = {
            'Content-Type': 'application/json',
          }

          const body = JSON.stringify(data)
          const res = await axios.post(Config.api.CONTACT, body, { headers })

          if (res.status === 200) {
            setLoading(false)
            setThankyou(true)
          } else {
            setLoading(false)
            // showToast('api.createContact.error', 'error')
          }
        } catch (error) {
          setLoading(false)
          // showToast('api.createContact.error', 'error')
          console.log(error)
        }
      } else {
        setLoading(false)
        // console.table(errors);

        isSchemaEmpty
          ? console.log('api.getSchema.error', 'warning') //showToast('api.getSchema.error', 'warning')
          : setErrors(err)
      }
    } else {
      setLoading(false)
      setErrors({
        email: t('errors:email.format'),
      })
    }
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      style={{ pointerEvents: 'all' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
    >
      <Notifier />
      {thankyou ? (
        <>
          <h2>{t('capture.success.title')}</h2>
          <p>{t('capture.success.copy')}</p>
        </>
      ) : (
        <>
          <h2
            dangerouslySetInnerHTML={{
              __html: title
                ? title
                : t('capture.title', {
                    start: "<span class='highlight'>",
                    end: '</span>',
                    interpolation: { escapeValue: false },
                  }),
            }}
          />
          <p>{subtitle ? subtitle : t('capture.copy')}</p>
        </>
      )}
      <Container disableGutters maxWidth={false}>
        {thankyou ? (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: `center` }}>
              <h1>👍</h1>
              <p>
                <strong>{t('capture.success.msg')}</strong>
              </p>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={pushLead}>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              sx={{ padding: `1rem 0 3rem` }}
            >
              <Grid item xs={12} md={8}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.firstName')}
                      fullWidth
                      margin="normal"
                      id="leadCaptureFirstName"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={firstname}
                      onChange={(e) => {
                        setFirstname(e.target.value)
                        if (errors.first) {
                          delete errors.first
                        }
                      }}
                      required
                      error={errors.first ? true : false}
                      helperText={errors.first ? errors.first : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.lastName')}
                      fullWidth
                      margin="normal"
                      id="leadCaptureLastName"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={lastname}
                      onChange={(e) => {
                        setLastname(e.target.value)
                        if (errors.last) {
                          delete errors.last
                        }
                      }}
                      required
                      error={errors.last ? true : false}
                      helperText={errors.last ? errors.last : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.email')}
                      fullWidth
                      margin="normal"
                      id="leadCaptureEmail"
                      variant="outlined"
                      InputProps={{
                        autoComplete: 'chrome-off',
                      }}
                      size="small"
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                        if (errors.email) {
                          delete errors.email
                        }
                      }}
                      required
                      error={errors.email ? true : false}
                      helperText={errors.email ? errors.email : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.phone')}
                      fullWidth
                      margin="normal"
                      id="leadCapturePhone"
                      name="leadCapturePhone"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatPhone,
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        if (errors.phone) {
                          delete errors.phone
                        }
                      }}
                      required
                      error={errors.phone ? true : false}
                      helperText={errors.phone ? errors.phone : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.city')}
                      fullWidth
                      margin="normal"
                      id="leadCaptureCity"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                        if (errors.city) {
                          delete errors.city
                        }
                      }}
                      required
                      error={errors.city ? true : false}
                      helperText={errors.city ? errors.city : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.price')}
                      fullWidth
                      margin="normal"
                      id="leadCapturePrice"
                      name="leadCapturePrice"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatDollars,
                        inputProps: { maxLength: 9 },
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={price}
                      placeholder={`$0`}
                      onChange={(e) => {
                        setPrice(e.target.value)
                        if (errors.price) {
                          delete errors.price
                        }
                      }}
                      required
                      error={errors.price ? true : false}
                      helperText={errors.price ? errors.price : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('userDetails.refCode')}
                      fullWidth
                      margin="normal"
                      id="leadCaptureRefCode"
                      name="leadCaptureRefCode"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        autoComplete: 'chrome-off',
                      }}
                      sx={{ marginTop: '0px', marginBottom: '0px' }}
                      value={refCode}
                      onChange={(e) => {
                        setRefCode(e.target.value)
                        if (errors.code) {
                          delete errors.code
                        }
                      }}
                      error={errors.code ? true : false}
                      helperText={errors.code ? errors.code : ''}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: `center` }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className="hw_btn_primary"
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: `#fff !important` }}
                    />
                  ) : (
                    t('capture.cta')
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </SnackbarProvider>
  )
}

export default withTranslation('landing')(LeadCapture)
