export function monthlyMortgage(total_mortgage, interestRate, period) {
  interestRate = interestRate <= 0 ? 0.000001 : interestRate
  var rate = interestRate / 12 / 100
  var periodMonthly = period * 12
  var powered = Math.pow(1 + rate, periodMonthly)
  var numerator = rate * powered
  var denominator = powered - 1
  var fraction = numerator / denominator
  var monthlyMortgage = total_mortgage * fraction
  if (monthlyMortgage < 0) {
    return 0
  } else {
    return monthlyMortgage
  }
}

export const calculateDownPayment = (principal, downPaymentPercentage) => {
  const downPaymentDecimal = downPaymentPercentage / 100
  const downPaymentAmount = principal * downPaymentDecimal
  return downPaymentAmount
}

export const presentValue = (rate, nper, pmt, fv) => {
  // Cleaning Input Vars
  rate = parseFloat(rate)
  nper = parseFloat(nper)
  pmt = parseFloat(pmt)
  fv = parseFloat(fv)

  rate = rate / 100 / 12
  nper = nper * 12
  pmt = -pmt
  if (rate === 0) {
    // Interest rate is 0
    var pv_value = -(fv + pmt * nper)
  } else {
    var x = Math.pow(1 + rate, -nper)
    var y = Math.pow(1 + rate, nper)
    pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate
  }
  pv_value = parseFloat(pv_value).toFixed(0)
  return parseFloat(pv_value)
}

export const getAffordability = (
  totalIncome,
  dp,
  period,
  interestRate,
  cc,
  car,
  loan,
  tax,
  fees,
  heat
) => {
  // Cleaning Input Vars
  totalIncome = parseFloat(totalIncome)
  dp = parseFloat(dp)
  period = parseFloat(period)
  interestRate = parseFloat(interestRate)
  cc = parseFloat(cc)
  car = parseFloat(car)
  loan = parseFloat(loan)
  tax = parseFloat(tax)
  fees = parseFloat(fees)
  heat = parseFloat(heat)

  tax = tax / 12

  let GDS = 0.385,
    TDS = 0.435,
    rateOffset = 2,
    isStaticStressTest = false

  let monthly_income = totalIncome / 12,
    totalLiving = tax + fees + heat,
    totalDebts = cc + car + loan,
    // stressedRate = interestRate + 2 >= 5.25 ? interestRate + 2 : 5.25,
    // stressedRate = interestRate,
    stressedRate = isStaticStressTest ? interestRate : interestRate + rateOffset

  // Step 1 - Get the GDS & TDS based on monthly income
  let maxMonthlyGDS = GDS * monthly_income - totalLiving,
    maxMonthlyTDS = TDS * monthly_income - (totalLiving + totalDebts)

  // Step 2 - Get the loan value
  let max_payment = Math.min(maxMonthlyGDS, maxMonthlyTDS),
    max_loan = presentValue(stressedRate, period, max_payment, 0)

  //Step 3 - Max Home Price & Downpayment Percentage
  let maxHomePrice = max_loan + dp <= 0 ? 0 : max_loan + dp

  let maxMonthly = monthlyMortgage(max_loan, interestRate, period)

  return [maxHomePrice, max_loan, maxMonthly]
}
