/** Uses new store. */

import React, { useContext } from 'react'
import AuthContext from '../../store/auth/authContext'
import { useNavigate } from 'react-router-dom'

import { Grid, Container, Paper, Button } from '@mui/material'

import Header from '../../components/nav/Header'
import Footer from '../../components/nav/Footer'
import ProfileNav from '../../components/nav/secondary/ProfileNav'
import UserDetails from '../../components/common/UserDetails'
import SavedSearches from '../../components/common/SavedSearches'
import SavedHomes from '../../components/common/SavedHomes'

const Profile = () => {
  const { user, logout } = useContext(AuthContext)
  const navigate = useNavigate()

  const [currentTab, setCurrentTab] = React.useState('accountDetails')

  return (
    <>
      <Header />
      <ProfileNav currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <section style={{ padding: `2rem 0` }}>
        {user?.activeSession && (
          <Container maxWidth="sm">
            <Grid item xs={12}>
              {currentTab === 'accountDetails' && (
                <Paper
                  className="classic-hw-card"
                  style={{ display: `flex`, flexDirection: `column` }}
                >
                  <h1>User Account details</h1>
                  <p>
                    Check in on <strong>your details</strong>
                  </p>
                  <p>Update your Homewise account details.</p>
                  <UserDetails />
                  <Button
                    component="button"
                    color="primary"
                    variant="text"
                    onClick={() => {
                      logout(() => {
                        navigate('/auth/login')
                      })
                    }}
                    style={{ alignSelf: `flex-end` }}
                  >
                    Log Out
                  </Button>
                </Paper>
              )}
              {currentTab === 'savedSearches' && (
                <Paper
                  className="classic-hw-card"
                  style={{ display: `flex`, flexDirection: `column` }}
                >
                  <h1>Saved Searches</h1>
                  <SavedSearches />
                </Paper>
              )}
              {currentTab === 'savedHomes' && (
                <Paper
                  className="classic-hw-card"
                  style={{ display: `flex`, flexDirection: `column` }}
                >
                  <h1>Saved Homes</h1>
                  <SavedHomes />
                </Paper>
              )}
            </Grid>
          </Container>
        )}
      </section>
      <Footer />
    </>
  )
}

export default Profile
