import React from 'react'
import { NumericFormat } from 'react-number-format'
import { kebabCase, startCase, lowerCase } from 'lodash'

/**
 * Provides a way to format a number to a currency string.
 * @param {Ref} inputRef - The ref to the input element.
 * @returns {string} The formatted currency string.
 */
export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      allowNegative={false}
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      prefix="$"
    />
  )
})

export const formatCurrency = (number = 0) => {
  // convert to number
  number = Number(number)

  if (isNaN(number)) return '$0'

  // add a dollar sign and commas
  return `$${number
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

export const capitalizeFirstLetter = (string) => {
  let lowered = string?.toLowerCase()
  return lowered?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter?.toUpperCase()
  )
}

export const formatDetailPagePath = (address, mlsId, boardId) => {
  // join the streetName, zip after checking for empty or null values and convert to lowercase then kebab case
  const addr = kebabCase(
    [
      address?.unitNumber,
      address?.streetNumber,
      address?.streetName,
      address?.streetSuffix,
      address?.streetDirection,
    ]
      .filter(Boolean)
      .join(' ')
      .toLowerCase()
  )

  // join, addr and zip after checking for empty or null values and convert to lowercase
  const addressWithZip = [addr, address?.zip]
    .filter(Boolean)
    .join('-')
    .toLowerCase()

  // create path /detail/:state/:city/:neighborhood/:address/:mlsId/:boardId
  const path = [
    '/detail',
    address?.state,
    address?.city,
    address?.neighborhood,
    addressWithZip,
    mlsId,
    boardId,
  ]
    .filter(Boolean)
    .join('/')

  return path
}

export const formatListingName = (address) => {
  let name = ''

  // if address.streetNumber, address.streetName, address.streetSuffix, address.streetDirection are not null or empty use that for the name
  if (address?.streetNumber && address?.streetName) {
    name = `${address?.unitNumber ? `${address?.unitNumber} - ` : ''} ${
      address?.streetNumber
    } ${startCase(lowerCase(address?.streetName))} ${
      address?.streetSuffix || ''
    } ${address?.streetDirection || ''}`
    return name
  }

  if (address?.streetName) {
    // split the street name into an array, then remove #, -, from the values
    const streetName = address?.streetName?.split(' ')?.map((item) => {
      return item?.replace(/[#-]/g, '')
    })

    // if the first 2 items are numbers, then the first item is the unit number
    if (!isNaN(Number(streetName[0])) && !isNaN(Number(streetName[1]))) {
      name = `${streetName[0]} - ${streetName[1]} ${startCase(
        lowerCase(streetName?.slice(2)?.join(' '))
      )}`
    } else {
      name = startCase(lowerCase(streetName?.join(' ')))
    }

    return name
  }
}
