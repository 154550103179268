import React from 'react'
import { useNavigate } from 'react-router-dom'

import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function BackButton() {
  const navigate = useNavigate()
  return (
    <IconButton
      color="primary"
      onClick={() => navigate(-1)}
      style={{ position: `absolute`, top: `20px`, right: `20px` }}
    >
      <ArrowBackIcon />
    </IconButton>
  )
}
