import React from 'react'
import { NumericFormat } from 'react-number-format'

export default React.forwardRef(function PhoneNumberFormat(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
      format="(###) ###-####"
      mask="_"
      allowEmptyFormatting
    />
  )
})
