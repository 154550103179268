import axios from 'axios'

// Request interceptor
axios.interceptors.request.use(
  async (config) => {
    if (!config?.noAuth) {
      return {
        ...config,
        headers: {
          ...config.headers,
          // eslint-disable-next-line
          // ["Authorization"]: await `Bearer jwtToken`,
        },
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axios
