import * as React from 'react'
import { styled } from '@mui/material/styles'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  )
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

export default function InteractiveList() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Demo>
          <List dense sx={{ alignItems: `center` }}>
            {generate(
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      console.log('Deleted')
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <Button
                  onClick={() => {
                    console.log('Selected')
                  }}
                  sx={{ marginTop: `0 !important` }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <SearchIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Single-line item"
                    secondary={'Secondary text'}
                  />
                </Button>
              </ListItem>
            )}
          </List>
        </Demo>
      </Grid>
    </Grid>
  )
}
